import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ApiService } from 'app/api.service';
import { ExcelDownloadService } from 'app/excel-download.service';
import { MetaService } from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-YYYY',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-payroll-report',
  templateUrl: './payroll-report.component.html',
  styleUrls: ['./payroll-report.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PayrollReportComponent {

  branchIdNameList = []
  selectedBranchIds = []
  roleIdNameList = []
  selectedroleIds = []
  userIdNameList = []
  selectedUserIds = []

  payrollDate = null;

  constructor(public apiService: ApiService, private toastr: ToastrService, private excelDownloadService: ExcelDownloadService, public _formBuilder: FormBuilder, public meta: MetaService, private datePipe: DatePipe) {

  }

  ngOnInit() {
    this.getRoles();
    this.getBranches();
    this.getUsers();
  }

  getRoles() {
    let data = {
      "filter_by_role_active_status": true
    }
    this.apiService.getRolesByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.roleIdNameList = resp.data.roles.map(role => {
          return {
            "id": role.id, "name": role.name
          }
        });
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  getBranches() {
    let data = {
      "filter_by_branch_active_status": true
    }
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branchIdNameList = resp.data.branches.map(branch => {
          return {
            "id": branch.id, "name": branch.name
          }
        })
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  getUsers() {
    this.resetCheckedUsers()
    let data = {
      "filter_by_branch_ids": this.selectedBranchIds,
      "filter_by_role_ids": this.selectedroleIds
    }
    this.apiService.getUserByFilterReport(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.userIdNameList = resp.data.users.map(user => {
          return {
            "id": user.id, "full_name": user.full_name
          }
        })
      }
    })
  }
  
  // SelecteAll checkbox for Users
  allBranchesChecked = false
  someBranchesChecked() {
    if (this.selectedBranchIds.length == this.branchIdNameList.length) {
      this.allBranchesChecked = true
    } else {
      this.allBranchesChecked = false
    }
    return this.selectedBranchIds.length > 0 && !this.allBranchesChecked;
  }
  setAllBranchesChecked(change) {
    if (change.checked) {
      this.allBranchesChecked = true;
      this.selectedBranchIds = this.branchIdNameList.map(e => e.id);
    } else {
      this.allBranchesChecked = false;
      this.selectedBranchIds = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  // SelecteAll checkbox for Roles
  allRolesChecked = false
  someRolesChecked() {
    if (this.selectedroleIds.length == this.roleIdNameList.length) {
      this.allRolesChecked = true
    } else {
      this.allRolesChecked = false
    }
    return this.selectedroleIds.length > 0 && !this.allRolesChecked;
  }
  setAllRolesChecked(change) {
    if (change.checked) {
      this.allRolesChecked = true;
      this.selectedroleIds = this.roleIdNameList.map(e => e.id);
    } else {
      this.allRolesChecked = false;
      this.selectedroleIds = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  // SelecteAll checkbox for Users
  allUsersChecked = false
  someUsersChecked() {
    if (this.selectedUserIds.length == this.userIdNameList.length) {
      this.allUsersChecked = true
    } else {
      this.allUsersChecked = false
    }
    return this.selectedUserIds.length > 0 && !this.allUsersChecked;
  }
  setAllUsersChecked(change) {
    if (change.checked) {
      this.allUsersChecked = true;
      this.selectedUserIds = this.userIdNameList.map(e => e.id);
    } else {
      this.allUsersChecked = false;
      this.selectedUserIds = [];
    }
  }

  getPayrollReport() {
    let payrollDate = new Date(this.payrollDate)
    let payrollMonth = payrollDate.getMonth() + 1
    let payrollYear = payrollDate.getFullYear()
    let requestBody = {
      payroll_month: payrollMonth,
      payroll_year: payrollYear,
      filter_by_branch_ids: this.selectedBranchIds,
      filter_by_role_ids: this.selectedroleIds,
      filter_by_user_ids: this.selectedUserIds
    }

    this.apiService.getPayrollReport(requestBody,{}).subscribe((response) => {
      if (response.status == "success") {
        let payrollData = response.data.pay_details
        let fileName = "PayrollReport_" + payrollMonth + "/" + payrollYear + '.' + 'xlsx'
        let headerNames = ["User", 'User Active Status', 'Gender', 'Branch', 'Role', "Duty Time Pay", "Over Time Pay", "Paid Leaves Pay", "Paid DayOffs Pay", "Branch Holidays Pay", "Sessions Pay", "Sessions Count", "Total Pay"]
        this.excelDownloadService.downloadExcelFile(payrollData, headerNames, fileName);
        this.meta.dialogClosed = false;
        this.resetAll()
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  // Resetting details
  resetCheckedUsers() {
    this.allUsersChecked = false;
    this.selectedUserIds = []
  }

  resetAll() {
    this.payrollDate = null;
    this.selectedBranchIds=[];
    this.selectedroleIds=[];
    this.selectedUserIds=[];
  }

}
