import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { ToastrService } from 'ngx-toastr';
import { UpdateLeaveStatusComponent } from '../update-leave-status/update-leave-status.component';
import moment from 'moment';
import { LeaveActions, PlanTypeActions, featureConstants } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';

@Component({
  selector: 'app-user-leave-list',
  templateUrl: './user-leave-list.component.html',
  styleUrls: ['./user-leave-list.component.scss']
})
export class UserLeaveListComponent {

  scrHeight: number;
  scrWidth: number;
  newDate: any;
  minDate: any;
  fromDate = null;
  toDate = null;
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;

  searchTerm = "";
  LeaveStatus = [
    // { value: 'All', viewValue: 'All' },
    { value: 'PENDING', viewValue: 'PENDING' },
    { value: 'ACCEPTED', viewValue: 'ACCEPTED' },
    { value: 'REJECTED', viewValue: 'REJECTED' },
    { value: 'CANCEL', viewValue: 'CANCEL' }
  ]
  IDPlanSubType: LeaveActions = {
    filter_by_from_date: "",
    filter_by_to_date: "",
    filter_by_leave_status:""
  }

  userId = null;
  FirstName = null;
  userRoleType = "CUSTOMER"
  // need to keep it in constants

  searchInputControl: UntypedFormControl = new UntypedFormControl();

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToApproveLeaves = this.userAllowedFeatures.indexOf(featureConstants.APPROVE_LEAVES) > -1
  isUserAllowedToEditLeaves = this.userAllowedFeatures.indexOf(featureConstants.EDIT_LEAVES) > -1
  isUserAllowedToViewLeaves = this.userAllowedFeatures.indexOf(featureConstants.VIEW_LEAVES) > -1
  displayableColumns() {
    let displayableColumnsList = ["Title", "FromandToDate", "NumOfDays", "PaidLeaves", "Status", "LastRespondedBy", "LastRespondedAt"];
    if(this.isUserAllowedToApproveLeaves) {
      displayableColumnsList.push("Actions")
    }
    return displayableColumnsList
  }

  displayedColumns = this.displayableColumns();
  dataSource = new MatTableDataSource<Element>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public apiService: ApiService, public dialog: MatDialog, public toastr: ToastrService, public router: Router) {
    this.getScreenSize();

    // todo: we are keeping it here for now later we will get it from local storage
    // if (this.router.getCurrentNavigation()) {
    //   const state = this.router.getCurrentNavigation().extras.state;
    //   this.userId = state['userId'].id || this.userId;
    //   this.FirstName = state['userId'].full_name || this.FirstName;
    // }
    this.userId = sessionStorage.getItem('LeavesForUserId') || this.userId;
    this.FirstName = sessionStorage.getItem('UserName') || this.FirstName;
  }

  back() {
    this.router.navigateByUrl('Admin/leaves');
  }

  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // This is get ad on every time screen is resized
  }

  ngOnInit() {
    this.filterPlanType()
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllLeaves() {

    let filterData = {
      user_id: this.userId,
    }

    this.apiService.getUserListById(filterData.user_id).subscribe((response) => {
      if (response.status == "success") {
        this.dataSource.data = response.data.leaves;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    );
  }

  filterPlanType() {
    let data = {}
    data = {
       offset:this.bOffset,
       limit:this.bLimit,
      filter_by_from_date: this.IDPlanSubType.filter_by_from_date ? moment(new Date(this.IDPlanSubType.filter_by_from_date)).format("YYYY-MM-DD").toString() : null,
      filter_by_to_date: this.IDPlanSubType.filter_by_to_date ? moment(new Date(this.IDPlanSubType.filter_by_to_date)).format("YYYY-MM-DD").toString() : null, 
      filter_by_leave_status: this.IDPlanSubType.filter_by_leave_status ? this.IDPlanSubType.filter_by_leave_status: null
    }

    this.apiService.getUserLeaveByFilter(data,this.userId).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data =  resp.data.leaves;
        this.totalCount=resp.data.total_user_leaves_count;
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewWidth:any
  viewUser(leaveData, shouldShowApprovalScreen) {
    if(this.scrWidth < 1450){
      this.viewWidth='80%'
    }else{
      this.viewWidth='50%'
    }
    let dialogRef = this.dialog.open(UpdateLeaveStatusComponent, {
      data: {
        "leaveData": leaveData,
        "shouldShowApprovalScreen": shouldShowApprovalScreen
      },
      // height: auto,
      width: this.viewWidth,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllLeaves()
    });
  }

  getLeaveStatusById(row) {
    this.apiService.getLeaveById(row.id).subscribe((resp) => {
      if (resp.status == "success") {
        this.viewUser(resp.data.leave, false)
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  approveLeaveStatusById(row) {
    this.apiService.getLeaveById(row.id).subscribe((resp) => {
      if (resp.status == "success") {
        this.viewUser(resp.data.leave, true)
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  editLeaveById(row) {
    sessionStorage.setItem('editLeaveId', row.id)
    this.router.navigateByUrl('Admin/edit-leave');
  }

  reset() {
   this.IDPlanSubType.filter_by_from_date = null,
   this.IDPlanSubType.filter_by_to_date = null,
   this.IDPlanSubType.filter_by_leave_status = "";
   this.getAllLeaves()
  }
  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.filterPlanType(); 
  }

  onFilterPlanType(){
    this.bOffset=0;
    this.pageIndex=0;
    this.filterPlanType()
  }

}

