import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { IDeviceActions, MetaService, featureConstants } from 'app/meta.service';
import { VerifyDeactivateComponent } from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';



export interface Element {
  is_removed: boolean;
  id: String;
  BranchName: string;
  Location: string;
  Number: string;
  viewUser: number;
  IsRemoved: boolean;
  CreatedBy: string;
}


@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['name', 'description', 'branch', 'attendance_device_id', 'attendance_device_type', 'createdBy', 'createdDate', 'Actions'];
  dataSource = new MatTableDataSource<Element>();
  branches_list = [];
  roleType = [
    // { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }

  ]
  IDevice: IDeviceActions = {
    deviceActive: "",
    branchId: "",
  }
  filterValue=null;
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1
  isUserAllowedToDeviceCreate = this.userAllowedFeatures.indexOf(featureConstants.CREATE_ATTENDANCE_DEVICES) > -1
  isUserAllowedToDeviceEdit = this.userAllowedFeatures.indexOf(featureConstants.EDIT_ATTENDANCE_DEVICES) > -1
  isUserAllowedToDeviceDeactivate = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_ATTENDANCE_DEVICES) > -1
  isUserAllowedToDeviceReactivate = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_ATTENDANCE_DEVICES) > -1
  

  constructor(private router: Router, public dialog: MatDialog,public meta: MetaService, public apiService: ApiService, private toastr: ToastrService) {

  }
  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.filterValue = paginationData.filterValue;
      this.IDevice.branchId=paginationData.filterByBranchId;
      this.IDevice.deviceActive=paginationData.filterByAttendance;
    }
    if (this.isUserAllowedToViewBranches) {
      this.getBranches();
    }
    this.filterDevice()
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    // }
    this.onFilterDeviceForSearch()
  }
  getDevices(){
    this.apiService.getDevicesList().subscribe((resp)=>{
      if (resp.status == "success") {
        this.dataSource.data = resp.data.attendance_devices;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  getBranches() {
    let data = { "filter_by_branch_active_status": true }
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branches_list = resp.data.branches
        // this.toastr.success("Branches Loaded successfully");
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  filterDevice() {
    
    
    let data = {
      filter_by_branch_id: this.IDevice.branchId,
      filter_by_attendance_device_active_status: this.IDevice.deviceActive,
      offset:this.bOffset,
      limit:this.bLimit,
      search_term:this.filterValue||null
     }

    this.apiService.getDeviceByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data =  resp.data.attendance_devices;
        this.totalCount=resp.data.total_attendance_devices_count

      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }
  addDevice() {
    sessionStorage.removeItem('editDeviceId')
    this.router.navigateByUrl('Admin/create-device');
  }
  editDevice(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue:this.filterValue,
      filterByBranchId:this.IDevice.branchId,
      filterByAttendance:this.IDevice.deviceActive
    };
    sessionStorage.setItem('paginationDataForDevice', JSON.stringify(paginationData)); 
    sessionStorage.setItem('editDeviceId', row.id)
   
    // this.router.navigate(['Admin/create-device', { device_id:row.id }]);
    this.router.navigateByUrl('Admin/edit-device');
   
  }


  verifyDeactivate(device_id:string){
    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
        "message": "The access through this will be denied for anyone who scans the QR from this device(Enry & Exit)"
      }, // Pass data to the dialog
      width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {

      if (this.meta.dialogClosed) {
        this.deactivateDevice(device_id=device_id)
      }
    });
  }


  deactivateDevice(device_id: String) {
    this.apiService.deactivateDevice(device_id = device_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          device => {
            if (device.id == device_id)
              device.is_removed = true
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  reactivateDevice(device_id: String) {
    this.apiService.reactivateDevice(device_id = device_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          device => {
            if (device.id == device_id)
              device.is_removed = false
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }
  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.filterDevice(); 
  }

  onFilterDeviceForSearch(){
    this.pageIndex=0;
    this.bOffset=0;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue:this.filterValue,
      filterByBranchId:this.IDevice.branchId,
      filterByAttendance:this.IDevice.deviceActive
    };
    sessionStorage.setItem('paginationDataForDevice', JSON.stringify(paginationData)); 
    
    this.filterDevice();
  }

  onFilterDevice(){
    this.pageIndex=0;
    this.bOffset=0;
    this.filterValue=null;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue:this.filterValue,
      filterByBranchId:this.IDevice.branchId,
      filterByAttendance:this.IDevice.deviceActive
    };
    sessionStorage.setItem('paginationDataForDevice', JSON.stringify(paginationData)); 

    this.filterDevice();
  }

}
