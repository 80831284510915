<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row ">
        <div class="row mt-8 justify-content-between">
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
              <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">
                PLANS
              </h1>
            </div>
      
            <div class="col-xs-12 col-sm-6 col-md-4  mb-2 d-flex justify-content-end" *ngIf="isUserAllowedToCreatePlans">
                <button class="ml-4 addBtn" mat-flat-button [color]="'primary'" (click)="createUser()"
                [ngClass]="{'btn-clr': true}">
                <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 txt" style="color: white">Create Plan</span>
            </button>
          </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-2" *ngIf="isUserAllowedToViewPlans">
                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 100%;">
                    <mat-select [placeholder]="'Select Plans'" [(ngModel)]="IDPlanSubType.planTypeId"
                    (selectionChange)="filterPlanType()">
                        <mat-option value="">Select Plan Type</mat-option>
                        <mat-option *ngFor="let role of planTypesList" [value]="role.id">{{role.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div class="col-sm-12 col-md-4 col-lg-2" *ngIf="isUserAllowedToViewPlans">
                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 100%;">
                    <mat-select [placeholder]="'Select Plans'" [(ngModel)]="IDPlanSubType.planSubTypeId"
                    (selectionChange)="filterPlanSubType()">
                        <mat-option value="">Select Plan Sub Type</mat-option>
                        <mat-option *ngFor="let subType of planSubTypesList" [value]="subType.id">{{subType.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-2">
                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 100%;">
                    <mat-select [placeholder]="'All'" [(ngModel)]="IDPlanSubType.plansActive" (selectionChange)="onGetPlansByFilter()">
                        <mat-option value="">All</mat-option>
                        <mat-option *ngFor="let role of roleType" [value]="role.value">{{role.viewValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;" [subscriptSizing]="'dynamic'">
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'"
                        [placeholder]="'Search Plan Name'">
                </mat-form-field>
            </div>
        </div>
    </div>


    <div class="mat-elevation-z8 mt-2 mt-sm-4 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

            <!-- Name Column -->
            <ng-container matColumnDef="planName">
                <th mat-header-cell *matHeaderCellDef>Plan Name</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.name}}
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="planHierarchy">
                <th mat-header-cell *matHeaderCellDef >Plan Hierarchy</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.plan_hierarchy}}
                </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="planType">
                <th mat-header-cell *matHeaderCellDef  sortActionDescription="Sort by Name"> Plan Type
                </th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                    {{row.plan_type.name}}
                </td>
            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="planSubType">
                <th mat-header-cell *matHeaderCellDef > Plan Sub Type</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                    {{row.plan_sub_type?.name}}</td>
            </ng-container>

            <ng-container matColumnDef="planPricePerMonth">
                <th mat-header-cell *matHeaderCellDef > Plan Price/Month </th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row ">
                    {{row.price_per_month}}</td>
            </ng-container>
            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                    <button mat-button [matMenuTriggerFor]="menu"><mat-icon style="color:#0038FF;font-size: 30px;"
                            svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="getPlansById(row)">View</button>
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToEditPlans"
                            (click)="editPlans(row)">Edit</button>
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToDeactivatePlans"
                            (click)="verifyDeactivate(row.id)">Deactivate</button>
                        <button mat-menu-item *ngIf="row.is_removed && isUserAllowedToReactivatePlans"
                            (click)="reactivatePlans(row.id)">Reactivate</button>
                    </mat-menu>

                </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <div>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">
                        <h3 style="display: flex;justify-content: center; margin:10%;">
                            No Records Found
                        </h3>
                    </td>
                </tr>
            </div>
        </table>
    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>