import { Component, PLATFORM_INITIALIZER, ViewChild } from '@angular/core';
import { MatPaginator,PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { featureConstants } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-user-purchase-history',
  templateUrl: './user-purchase-history.component.html',
  styleUrls: ['./user-purchase-history.component.scss']
})
export class UserPurchaseHistoryComponent {

  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  scrHeight: number;
  scrWidth: number;

  userId = null;
  userName = "User Purchase History";
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  searchIndicator=1;
  filterValue=null;
  currentRoutePath=null;
  // isUserPurchaseHistory: boolean = false;
  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewSchedules = this.userAllowedFeatures.indexOf(featureConstants.VIEW_SCHEDULES) > -1

  // displayedColumns = ["userName","email","mobileNumber","planName", "planType", "startDate", "endDate", "totalPrice","paymentGateway", "paymentStatus", "paymentSuccessAt", "planStatus", "invoiceNo", "Actions"]; 
  displayedColumns: string[]; 

  dataSource = new MatTableDataSource<any>();

  constructor(public apiService: ApiService, public toastr: ToastrService, public router: Router,private route: ActivatedRoute) {
    this.getScreenSize();


    this.route.url.subscribe(url => {
     


      
      if (url && url.length > 0 && url[0].path=="user-purchase-history-all") {
        sessionStorage.removeItem('userPurchaseHistoryForUserId');
    sessionStorage.removeItem('userPurchaseHistoryForUserName');
        
      }
});







    this.userId = sessionStorage.getItem('userPurchaseHistoryForUserId') || this.userId;
    this.userName = sessionStorage.getItem('userPurchaseHistoryForUserName') || this.userName;
    if (this.userId === null) {
      this.searchIndicator=1;
      this.displayedColumns = ["userName", "email", "mobileNumber", "planName", "startDate", "totalPrice", "paymentGateway", "paymentStatus", "paymentSuccessAt", "planStatus", "invoiceNo", "Actions"];
    } else {
      this.searchIndicator=null;
      this.displayedColumns = ["planName", "startDate", "totalPrice", "paymentGateway", "paymentStatus", "paymentSuccessAt", "planStatus", "invoiceNo", "Actions"];
    }
  }
    
  


  back() {
    if(sessionStorage.getItem("userPurchaseHistoryForUserId"))
    {
    sessionStorage.removeItem('userPurchaseHistoryForUserId');
    sessionStorage.removeItem('userPurchaseHistoryForUserName');
    // this.userId=null;
    // this.userName="User Purchase History"
    this.router.navigate(['Admin/users'])
  }
  
}

  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // This is get ad on every time screen is resized
  }

  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    
    
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.filterValue=paginationData.filterValue;
    }

    this.getUserPurchaseHistory()
  }



  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
   
    // }
  this.onGetUserPurchaseHistory()
  }

  getUserPurchaseHistory() {
    let data: any = {
      offset: this.bOffset,
      limit: this.bLimit,
      filter_by_user_id: this.userId,
      search_term: this.filterValue || null
    };
    
    this.apiService.getUserPurchaseHistory(data).subscribe((response) => {
      if (response.status == "success") {
        this.dataSource.data = response.data["user_orders"];
        this.totalCount=response.data.total_count;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    );
  }

  getUserPlanSchedules(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue
    };
    sessionStorage.setItem('paginationDataForUserPurchaseHistory', JSON.stringify(paginationData));
    sessionStorage.setItem('getBackToFromSchedules', 'user-purchase-history')
    sessionStorage.setItem('userSchedulesUserId', row.user_id);
    sessionStorage.setItem('userSchedulesUserOrderPlanId', row.user_order_plan_id);
    sessionStorage.setItem('userSchedulesPlanTypeAlias', row.plan.plan_type.category);
    this.router.navigate(['Admin/user-schedules'])
  }

  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.getUserPurchaseHistory(); 
  }

  onGetUserPurchaseHistory(){
    this.pageIndex = 0;
    this.bOffset = 0;

    this.getUserPurchaseHistory()
  }


}
