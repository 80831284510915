<!-- <h1  mat-dialog-title>View User</h1> -->
<div class="bgCard">

<div class="dilogStl">
    <h1  class="viewHeading" mat-dialog-title>VIEW BRANCH</h1>
       <button align="end" mat-icon-button mat-dialog-close >
        <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>       
      </button>
</div>

<mat-dialog-content class="mat-typography" >
  <div class="card dataCard" *ngFor="let item of branchDetails"  >
    <div class="mt-2 ml-3" >
        <h1 class="headings">
            Basic Details
        </h1>
        <div class="row m-3" >
            <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                <div class="listFont fw-bold">
                    Branch Name:
                </div>
                <div class="fw-bold">
                    {{item.name}}
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                <div class="listFont fw-bold">
                   Mobile Number:
                </div>
                <div class="fw-bold">
                    {{item.mobile_number}}
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                <div class="listFont fw-bold">
                    Email:
                </div>
                <div class="fw-bold">
                    {{item.email}}
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                <div class="listFont fw-bold">
                    Created By:
                </div>
                <div class="fw-bold">
                    {{item.createdBy}}<span *ngIf="item.createdByRole">({{item.createdByRole}})</span>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                <div class="listFont fw-bold">
                    Created Date:
                </div>
                <div class="fw-bold">
                    {{item.createdAt | dateFormat }}
                </div>
            </div>
          </div>
        </div>
    </div>
 
  <div class="card dataCard mt-4" *ngFor="let item of branchDetails"  >
    <div class="mt-2 ml-3 ">
        <h4 class="headings">
            Branch Address
        </h4>
        <div class="row  m-3">
            <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                <div class="listFont fw-bold">
                    Address Line#1
                </div>
                <div class="fw-bold">
                    {{item.address.line_1}}
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                <div class="listFont fw-bold">
                    Address Line#2
                </div>
                <div class="fw-bold">
                    {{item.address.line_2}}
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                <div class="listFont fw-bold">
                    PIN Code:
                </div>
                <div class="fw-bold">
                   {{item.address.pincode}}
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                <div class="listFont fw-bold">
                    State:
                </div>
                <div class="fw-bold">
                    {{item.address.city.state.name}}
                </div>
            </div>
            <div class="col-md-6">
                <div class="listFont fw-bold">
                   City:
                </div>
                <div class="fw-bold">
                    {{item.address.city.name}}
                </div>
            </div>
        </div>
      
    </div>  
  </div>
  <div class="card dataCard mt-4" style="height: auto!important;" >
    <div class="mt-2 ml-3 ">
        <h4 class="headings">
            Branch Timings
        </h4>
        <div class="col-12 d-flex flex-column align-items-center" style="max-height:400px; overflow-y:scroll;">
            <table class="table " style="width: 70%;">
                <thead class="thead-dark" style="background: #000;color: #fff; position: sticky; top: 0; z-index: 1;">
                  <tr>
                    <th scope="col">Day</th>
                    <th scope="col">Day Type</th>
                    <th scope="col">Start Time</th>
                    <th scope="col">End Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of timeSlots">
                    <td>{{item.day_of_week}}</td>
                    <td *ngIf="item.day_type == 'WORK_DAY'" class="">Work Day</td>
                    <td *ngIf="item.day_type == 'DAY_OFF'" class="">Day Off</td>
                    <td>{{item.start_time}}</td>
                    <td>{{item.end_time}}</td>
                  </tr>
                </tbody>
              </table>
        </div>
    
    </div>
  </div>
  
  <app-file-upload-manager #fileUploadComponent 
    [shouldShowFileUploader]="false" 
    [existingFileData]="existingFileDataForFileManager" 
    [actualexistingFilesCount]="actualexistingFilesCountForFileManager">
    </app-file-upload-manager>

</mat-dialog-content>
</div>

<!-- <mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
