<div class="">

    <div class="dilogStl">
        <h1 style="font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;" mat-dialog-title>Create Level</h1>
           <button align="end" mat-icon-button mat-dialog-close >
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>       
          </button>
    </div>
    <div class="row justify-content-center " style="margin: 5%;">
        <div class="col-md-12">
            <div class="fw-bold">
               Plan Level Name 
                <span class="text-danger">*</span>
            </div>
            <div class="">
                <mat-form-field class="w-full">
                    <input  id="plan" class="bg-white" [(ngModel)]="levelName" matInput placeholder="Enter Plan"
                    >
                
                    <!-- <mat-error *ngIf="basicDetailsForm.get('fullName').hasError('required')">
                        Full Name  is required
                    </mat-error> -->
                    <!-- <mat-error *ngIf="signInForm.get('fullName').hasError('required')">
                        Please enter a valid email address
                    </mat-error> -->
                </mat-form-field>
            </div>
        </div>
    </div>
    <div>
        
    </div>  <div class=" mt-8 d-flex justify-content-center ">
        <button class="ml-4 addBtn" mat-flat-button[color]="'primary'" *ngIf="!isUpdate" (click)="createLevel()">
       
          <span class="ml-2 mr-1 txt" style="color: black">Create</span>
        </button>
        <button class="ml-4 addBtn" mat-flat-button [color]="'primary'" *ngIf="isUpdate" (click)="updateLevel()">
       
            <span class="ml-2 mr-1 txt" style="color: black">Update</span>
          </button>
        <!-- (click)="createRole()" -->
      
      </div>
</div>