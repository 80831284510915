<div class="container-fluid">
    <div>
        <div class="d-flex m-3">
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{title}}</h2>
        </div>
        <div class="card" [formGroup]="timeslotDiscountsForm" style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="mt-2 ml-3 mb-4">
                <h4 style="color: #00FF00;">
                    Details
                </h4>
                <div class="row  mr-3 ml-3 mt-3">
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Select Time Slot
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <div class="">
    
                                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="plan" style="width: 90%;">
                                    <mat-select [placeholder]="'Select time slot'" formControlName="timeSlot" >
                                        <mat-option *ngFor="let timeslot of timeSlots"
                                            [value]="timeslot.alias">{{timeslot.name}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="timeslotDiscountsForm.get('timeSlot').hasError('required')">
                                        Time Slot is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Select Start Date
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-75">
                                <input id="StartDate" matInput readonly [matDatepicker]="picker" [(ngModel)]="startDate" [min]="newDate"
                                    [placeholder]="'Start Date'" [ngModelOptions]="{standalone: true}">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Select End Date
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-75">
                                <input matInput readonly [matDatepicker]="picker1" [min]="startDate || newDate"
                                    [placeholder]="'End Date'" [(ngModel)]="endDate" [ngModelOptions]="{standalone: true}">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                                <mat-error *ngIf="(startDate && endDate && startDate > endDate)"> End Date should be greater than Start Date</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Discount Percentage
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="discount_percentage" style="background: #FFFFFF;" matInput placeholder="Enter Discount Percentage"
                                formControlName="discountPercentage">
                                <mat-error *ngIf="timeslotDiscountsForm.get('discountPercentage').hasError('required')"> Discount Percentage is required</mat-error>
                                <mat-error *ngIf="timeslotDiscountsForm.get('discountPercentage').hasError('min')"> Enter valid Discount Percentage</mat-error>
                                <mat-error *ngIf="timeslotDiscountsForm.get('discountPercentage').hasError('max')"> Enter valid Discount Percentage</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="btnposition">
        <div class="bottomBtns mb-3 row">
            <button class="ml-4 mt-4 addBtn" mat-flat-button (click)="resetForm()" *ngIf="!isUpdate">
                <span class="ml-2 mr-1 txt">Reset</span>
            </button>
            <button class="ml-4 mt-4 addBtn" mat-flat-button (click)="back()" *ngIf="isUpdate">
                <span class="ml-2 mr-1 txt">Cancel</span>
            </button>
            <button class="ml-4 mr-6 mt-4 addBtn" mat-flat-button [color]="'primary'" (click)="createTimeslotDiscount()" *ngIf="!isUpdate"
            [disabled] = "(timeslotDiscountsForm.invalid || !((!startDate && !endDate) || (!startDate && endDate) || (startDate && !endDate) || (startDate && endDate && startDate <= endDate)))" 
            [ngClass] = "{'btn-clr': (timeslotDiscountsForm.valid && ((!startDate && !endDate) || (!startDate && endDate) || (startDate && !endDate) || (startDate && endDate && startDate <= endDate)))}">
                <span class="ml-2 mr-1 txt" style="color: white">Create </span>
            </button>
            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="updateTimeslotDiscount()" *ngIf="isUpdate"
            [disabled] = "(timeslotDiscountsForm.invalid || !((!startDate && !endDate) || (!startDate && endDate) || (startDate && !endDate) || (startDate && endDate && startDate <= endDate)))" 
            [ngClass] = "{'btn-clr': (timeslotDiscountsForm.valid && ((!startDate && !endDate) || (!startDate && endDate) || (startDate && !endDate) || (startDate && endDate && startDate <= endDate)))}">
            <span class="ml-2 mr-1 txt" style="color: white">Update</span>
            </button>
        </div>
    </div>
</div>
