import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'app/api.service';
export interface role {
  name: string;
  type:string;
  createdAt:string;
  createdBy:string;
  createdByRole: string;
  shouldAddAddress: boolean;
  shouldAddAvailabilityTimings: boolean;
  shouldAddBranchAtUserCreation: boolean;
  canAddMultipleBranches: boolean;
  // shouldAddLevel:boolean;
}
@Component({
  selector: 'app-view-role',
  templateUrl: './view-role.component.html',
  styleUrls: ['./view-role.component.scss']
})
export class ViewRoleComponent {
  selectedfeaturesList=[];
  heading=[];
  headingArr=[];
  dataArr=[];
  Irole:role={
    name:"",
    type:"",
    createdAt:"",
    createdBy:"",
    createdByRole: "",
    shouldAddAddress: false,
    shouldAddAvailabilityTimings: false,
    shouldAddBranchAtUserCreation: false,
    canAddMultipleBranches: false,
    // shouldAddLevel:false,
  }
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public apiservice: ApiService) { }
  ngOnInit() {
    this.GetRoleDetails()
  }
  GetRoleDetails() {
    this.selectedfeaturesList=this.data.data.role.features;
    this.Irole.name = this.data.data.role.name;
    this.Irole.type = this.data.data.role.role_type;
    this.Irole.createdAt=this.data.data.role.created_at;
    this.Irole.createdBy=this.data.data.role.created_by?.full_name;
    this.Irole.createdByRole = this.data.data.role.created_by?.role.name;
    this.Irole.shouldAddAddress = this.data.data.role.should_add_address;
    this.Irole.shouldAddAvailabilityTimings = this.data.data.role.should_add_availability_timings;
    this.Irole.shouldAddBranchAtUserCreation = this.data.data.role.should_add_branch_at_user_creation;
    this.Irole.canAddMultipleBranches = this.data.data.role.can_add_multiple_branches;
    // this.Irole.shouldAddLevel =this.data.data.role.should_add_level;
    this.heading = Object.entries(this.selectedfeaturesList);
    this.heading.forEach(element => {
      this.headingArr.push(element[0])
      this.dataArr.push(element[1])
    });

    // let role_id=this.data.id
    // this.apiservice.viewRoleById(role_id).subscribe((resp) => {
    //   this.selectedfeaturesList = resp.data.role.features;
    //   this.Irole.name= resp.data.role.name;
    //   this.Irole.type = resp.data.role.role_type;
    //   this.Irole.createdAt=resp.data.role.created_at;
    //   this.Irole.createdBy=resp.data.role.created_by;
    //   this.heading = Object.entries(this.selectedfeaturesList);
    //   this.heading.forEach(element => {
    //     this.headingArr.push(element[0]) 
    //     this.dataArr.push(element[1])
        
    //   });
    // })
  }
  rows = [
    {
      name: "View Plans",
      web: false,
      mobile: true
    },
    {
      name: "Create Plans",
      web: false,
      mobile: true
    },
    {
      name: "Edit Plans",
      web: true,
      mobile: false
    },
    {
      name: "Deactivate Plans",
      web: true,
      mobile: false
    }


  ]
  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  showOptions(event, i) {
    // console.log(event.checked, i); //true or false
  }
}
