<div class="container-fluid">
    <div>
        <div class="d-flex m-3">
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{ title }}</h2>
        </div>
        <div class="card m-2" [formGroup]="basicDetailsForm" style="border: none">
            <div class="card-header" style="border: none; ">
                <h4>
                    Basic Details
                    <span class="text-danger">*</span>
                </h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Branch Name
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input formControlName="branchName" id="fullName" class="bg-white" matInput
                                    placeholder="Enter Branch Name" 
                                    (keypress)="handleStartingSpaceTextInput($event, basicDetailsForm.get('branchName').value)"/>
                                <mat-error *ngIf="basicDetailsForm.get('branchName').hasError('required')">
                                    Name is required
                                </mat-error>
                                <mat-error *ngIf="basicDetailsForm.get('branchName').hasError('pattern')">
                                    Name has invalid characters
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Contact Number
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="mobileNumber" formControlName="mobileNumber" class="bg-white"
                                    (keypress)="allowNumkeyPress($event)" matInput maxlength="10"
                                    placeholder="Enter Contact Number" />
                                <mat-error *ngIf="
                                        basicDetailsForm
                                            .get('mobileNumber')
                                            .hasError('required')
                                    ">
                                    Contact Number is required
                                </mat-error>
                                <mat-error *ngIf="
                                        basicDetailsForm
                                            .get('mobileNumber')
                                            .hasError('minlength')
                                    ">
                                    Contact Number must be at least 10 numbers
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">Branch Email</div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="email" formControlName="email" class="bg-white" matInput
                                    placeholder="Enter Email ID" (keypress)="handleStartingSpaceTextInput($event, basicDetailsForm.get('email').value)"/>
                                <mat-error *ngIf="
                                        basicDetailsForm
                                            .get('email')
                                            .hasError('pattern')
                                    ">
                                    Invalid Email
                                </mat-error>

                                <!-- <mat-error *ngIf="basicDetailsForm.get('fullName').hasError('required')">
                                Full Name  is required
                            </mat-error> -->
                                <!-- <mat-error *ngIf="signInForm.get('fullName').hasError('required')">
                                Please enter a valid email address
                            </mat-error> -->
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card m-2" [formGroup]="adddressForm" style="border: none">
            <div class="card-header" style="border: none;">
                <h4>
                    Branch Address
                    <span class="text-danger">*</span>
                </h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Address Line 1
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="Area" class="bg-white" formControlName="line1" matInput
                                    placeholder="Address Line 1" (keypress)="handleStartingSpaceTextInput($event, adddressForm.get('line1').value)"/>
                                <mat-error *ngIf="adddressForm.get('line1').hasError('required')">
                                    Address Line 1 is required
                                </mat-error>
                                <!-- <mat-error *ngIf="
                                        adddressForm
                                            .get('line1')
                                            .hasError('pattern')
                                    ">
                                    Address Line 1 has invalid characters
                                </mat-error> -->
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">Address Line 2</div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="line2" formControlName="line2" class="bg-white" matInput
                                    placeholder="Address Line 2" (keypress)="handleStartingSpaceTextInput($event, adddressForm.get('line2').value)"/>
                                <!-- <mat-error *ngIf="basicDetailsForm.get('fullName').hasError('required')">
                                Full Name  is required
                            </mat-error> -->
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            State
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="role" style="width: 90%">
                                <mat-select [formControl]="stateFormCtrl" name="state" [placeholder]="'Select State'">
                                    <mat-option *ngFor="let state of statesList" [value]="state.id">{{ state.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="
                                        adddressForm
                                            .get('state')
                                            .hasError('required')
                                    ">
                                    State is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            City
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="role" style="width: 90%">
                                <mat-select [formControl]="cityFormCtrl" name="" cityId [placeholder]="'Select City'">
                                    <mat-option *ngFor="let city of citiesList" [value]="city.id">{{ city.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="
                                        adddressForm
                                            .get('cityId')
                                            .hasError('required')
                                    ">
                                    City is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            PIN Code
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="pincode" formControlName="pincode" class="bg-white"
                                    (keypress)="allowNumkeyPress($event)" matInput maxlength="6" matInput
                                    placeholder="Enter Pincode" />
                                <mat-error *ngIf="
                                        adddressForm
                                            .get('pincode')
                                            .hasError('required')
                                    ">
                                    PIN Code is required
                                </mat-error>
                                <!-- <mat-error *ngIf="basicDetailsForm.get('fullName').hasError('required')">
                                Full Name  is required
                            </mat-error> -->
                                <!-- <mat-error *ngIf="signInForm.get('fullName').hasError('required')">
                                Please enter a valid email address
                            </mat-error> -->
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card m-2" style="border: none">
            <div class="card-header" style="border: none ;">
                <h4>
                    Branch Timings
                    <span class="text-danger">*</span>
                </h4>
            </div>
            <div class="card-body">
                <div class="row justify-content-around" style="max-height:450px;">
                    <div class="col-sm-12 col-md-4">
                        <div class="d-flex flex-column align-items-center">
                            <div style="width: 60%">
                                <div class="fw-bold fieldName">Select Day</div>
                                <div class="">
                                    <div class="">
                                        <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%">
                                            <mat-select [placeholder]="'ALL'"
                                                (selectionChange)="selectedDays($event.value)" [(ngModel)]="days"
                                                multiple>
                                                <mat-checkbox class="" [checked]="allComplete"
                                                    [indeterminate]="someComplete()" (change)="setAll($event)">
                                                    Select All
                                                </mat-checkbox>

                                                <mat-option *ngFor="let day of daysList" [value]="day.value">
                                                    {{ day.viewValue }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div style="width: 60%">
                                <div class="fw-bold fieldName">
                                    Select Day Type
                                </div>
                                <div class="">
                                    <div class="">
                                        <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%">
                                            <mat-select [placeholder]="'ALL'" [disabled]="days.length <= 0"
                                                (selectionChange)="
                                                    selectedDayType(
                                                        $event.value
                                                    )
                                                " [(ngModel)]="daysType">
                                                <mat-option *ngFor="let day of dayType" [value]="day.value">
                                                    {{ day.viewValue }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div style="width: 60%">
                                <div class="fw-bold fieldName">Start Time</div>
                                <div class="">
                                    <div class="">
                                        <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%">
                                            <input matInput class="cursor-pointer" [disabled]="disableTime" mode="12h"
                                                [placeholder]="
                                                    'Select Start Time'
                                                " [(ngModel)]="startTime" matTimepicker>
                                            <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div style="width: 60%">
                                <div class="fw-bold fieldName">End Time</div>
                                <div class="">
                                    <div class="">
                                        <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%">
                                            <input class="cursor-pointer" matInput [placeholder]="
                                                    'Select End Time'
                                                " mode="12h" [disabled]="
                                                    disableTime || !startTime
                                                " [minDate]="startTime" [(ngModel)]="endTime" (ngModelChange)="
                                                    verifyTimeSlots()
                                                " matTimepicker />
                                            <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                                        </mat-form-field>
                                        <!-- <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                <input class="cursor-pointer" matInput [placeholder]="'Select End date'" [(ngModel)]="endTime" [ngxMatTimepicker]="endtimepicker">
                                <ngx-mat-timepicker #endtimepicker></ngx-mat-timepicker>
                                <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                            </mat-form-field> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end" *ngIf="showErrorMsgToAddTimeSlots">
                            <span class="text-danger">{{
                                errorMsgToAddTimeSlots
                                }}</span>
                        </div>
                        <div class="d-flex mt-3" style="float: right">
                            <button class="ml-4 addTimeBtn" [disabled]="disableAdd"
                                [ngClass]="{ 'btn-clr': !disableAdd }" mat-flat-button [color]="'primary'"
                                (click)="addTimeSlots()">
                                <span class="ml-2 mr-1 txt" style="color: white">Add</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 d-flex flex-column align-items-center"
                        style="max-height:450px; overflow-y:scroll;">
                        <table class="table" style="width: 90%">
                            <thead class="thead-dark"
                                style="background: #000; color: #fff;  position: sticky; top: 0; z-index: 1;">
                                <tr>
                                    <th scope="col">Days</th>
                                    <th scope="col">Day Type</th>
                                    <th scope="col">Start Time</th>
                                    <th scope="col">End Time</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let slot of sortedTimeSlots;">
                                    <th scope="row">{{ slot.day }}</th>
                                    <th>{{ slot.dayType }}</th>
                                    <th>{{ slot.startTime }}</th>
                                    <th>{{ slot.endTime }}</th>
                                    <td>
                                        <button mat-icon-button (click)="deleteDay(slot)">
                                            <mat-icon style="color: red" svgIcon="mat_outline:delete"></mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- <div class="row ">
                <div class="col-md-4 border-r">
 
                    <div class="d-flex flex-column ">
                        <div >
                            <div class="fw-bold fieldName">
                              Select Day
                            </div>
                            <div class="">
                                <div class="">
                                    <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                        <mat-select [placeholder]="'ALL'" (selectionChange)="selectedDays($event.value)"  [(ngModel)]="days"  >
                                            <mat-option *ngFor="let day of daysList"  [value]="day.value">
                                                {{day.viewValue}}
                                              </mat-option>
                                        </mat-select>
                                     
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div >
                            <div class="fw-bold fieldName">
                              Select Day Type
                            </div>
                            <div class="">
                                <div class="">
                                    <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                        <mat-select [placeholder]="'ALL'" (selectionChange)="selectedDayType($event.value)"  [(ngModel)]="days"  >
                                            <mat-option *ngFor="let day of dayType"  [value]="day.value">
                                                {{day.viewValue}}
                                              </mat-option>
                                        </mat-select>
                                     
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div >
                        <div class="fw-bold fieldName">
                          Start Time
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                    <input matInput class="cursor-pointer" [placeholder]="'Select Start date'" [(ngModel)]="startTime" [ngxMatTimepicker]="timepicker">
                                    <ngx-mat-timepicker #timepicker></ngx-mat-timepicker>
                                    <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                                </mat-form-field>
                                
                            </div>
                        </div>
                    </div>
                    <div >
                        <div class="fw-bold fieldName">
                          End Time 
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                    <input class="cursor-pointer" matInput [placeholder]="'Select End date'" [(ngModel)]="endTime" [ngxMatTimepicker]="endtimepicker">
                                    <ngx-mat-timepicker #endtimepicker></ngx-mat-timepicker>
                                    <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                                </mat-form-field>
                                
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="d-flex mt-3" style="float: right;">
                        <button
                        class="ml-4  addTimeBtn"
                        mat-flat-button
                        [color]="'primary'"
                        (click)="addTimeSlots()"
                     
                        >
                        <span class="ml-2 mr-1 txt">Add</span>
                        </button>
                    </div>
               
            
                </div>
                <div class="col-md-8">
                    <table class="table table-dark">
                        <thead class="">
                          <tr>
                            <th scope="col">Days</th>
                            <th scope="col">Day Type</th>
                            <th scope="col">Start Time</th>
                            <th scope="col">End Time</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let slot of timeSlots;let i=index">
                            <th scope="row">{{slot.day}}</th>
                            <th >{{slot.dayType}}</th>
                            <th >{{slot.startTime}}</th>
                            <th>{{slot.endTime}}</th>
                            <td> <button mat-icon-button (click)="deleteDay(i)">
                               <mat-icon style="color:red;"svgIcon="mat_outline:delete"></mat-icon>
                                </button></td>
                          </tr>
                        </tbody>
                      </table>
                </div>
            </div> -->
            </div>
        </div>
        <!-- <div class="card m-2">
        <div class="card-header">
            Branch Holidays
        </div>
        <div class="card-body">
            <div class="row ">
                <div class="col-md-4 border-r">  
 
                    <div class="d-flex flex-column ">
                        <div >
                            <div class="fw-bold fieldName">
                              Select Day
                            </div>
                            <div class="">
                                <div class="">
                                    <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                        <mat-select [placeholder]="'ALL'" (selectionChange)="selectedDays($event.value)"  [(ngModel)]="days"  >
                                            <mat-option *ngFor="let day of daysList"  [value]="day.value">
                                                {{day.viewValue}}
                                              </mat-option>
                                        </mat-select>
                                     
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div >
                            <div class="fw-bold fieldName">
                              Select Day Type
                            </div>
                            <div class="">
                                <div class="">
                                    <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                        <mat-select [placeholder]="'ALL'" (selectionChange)="selectedDayType($event.value)"  [(ngModel)]="days"  >
                                            <mat-option *ngFor="let day of dayType"  [value]="day.value">
                                                {{day.viewValue}}
                                              </mat-option>
                                        </mat-select>
                                     
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div >
                        <div class="fw-bold fieldName">
                          Start Time
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                    <input matInput class="cursor-pointer" [placeholder]="'Select Start date'" [(ngModel)]="startTime" [ngxMatTimepicker]="timepicker">
                                    <ngx-mat-timepicker #timepicker></ngx-mat-timepicker>
                                    <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                                </mat-form-field>
                                
                            </div>
                        </div>
                    </div>
                    <div >
                        <div class="fw-bold fieldName">
                          End Time 
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                    <input class="cursor-pointer" matInput [placeholder]="'Select End date'" [(ngModel)]="endTime" [ngxMatTimepicker]="endtimepicker">
                                    <ngx-mat-timepicker #endtimepicker></ngx-mat-timepicker>
                                    <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                                </mat-form-field>
                                
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="d-flex mt-3" style="float: right;">
                        <button
                        class="ml-4  addTimeBtn"
                        mat-flat-button
                        [color]="'primary'"
                        (click)="addTimeSlots()"
                     
                        >
                        <span class="ml-2 mr-1 txt">Add</span>
                        </button>
                    </div>
               
            
                </div>
                <div class="col-md-8">
                    <table class="table " style="width: 70%;">
                        <thead class="thead-dark" style="background: #000;color: #fff;">
                          <tr>
                            <th scope="col">Days</th>
                            <th scope="col">Day Type</th>
                            <th scope="col">Start Time</th>
                            <th scope="col">End Time</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let slot of timeSlots;let i=index">
                            <th scope="row">{{slot.day}}</th>
                            <th >{{slot.dayType}}</th>
                            <th >{{slot.startTime}}</th>
                            <th>{{slot.endTime}}</th>
                            <td> <button mat-icon-button (click)="deleteDay(i)">
                               <mat-icon style="color:red;"svgIcon="mat_outline:delete"></mat-icon>
                                </button></td>
                          </tr>
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
    </div>
     -->
        <!-- <div class="card m-2">
        <div class="card-header">
            Assign Devices
        </div>
        <div class="card-body">
            <div class="row ">
                <div class="col-md-4 border-r">
 
                    <div class="d-flex flex-column ">
                        <div >
                            <div class="fw-bold fieldName">
                              Select Day
                            </div>
                            <div class="">
                                <div class="">
                                    <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                        <mat-select [placeholder]="'ALL'" (selectionChange)="selectedDays($event.value)"  [(ngModel)]="days"  >
                                            <mat-option *ngFor="let day of daysList"  [value]="day.value">
                                                {{day.viewValue}}
                                              </mat-option>
                                        </mat-select>
                                     
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div >
                            <div class="fw-bold fieldName">
                              Select Day Type
                            </div>
                            <div class="">
                                <div class="">
                                    <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                        <mat-select [placeholder]="'ALL'" (selectionChange)="selectedDayType($event.value)"  [(ngModel)]="days"  >
                                            <mat-option *ngFor="let day of dayType"  [value]="day.value">
                                                {{day.viewValue}}
                                              </mat-option>
                                        </mat-select>
                                     
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div >
                        <div class="fw-bold fieldName">
                          Start Time
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                    <input matInput class="cursor-pointer" [placeholder]="'Select Start date'" [(ngModel)]="startTime" [ngxMatTimepicker]="timepicker">
                                    <ngx-mat-timepicker #timepicker></ngx-mat-timepicker>
                                    <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                                </mat-form-field>
                                
                            </div>
                        </div>
                    </div>
                    <div >
                        <div class="fw-bold fieldName">
                          End Time 
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                    <input class="cursor-pointer" matInput [placeholder]="'Select End date'" [(ngModel)]="endTime" [ngxMatTimepicker]="endtimepicker">
                                    <ngx-mat-timepicker #endtimepicker></ngx-mat-timepicker>
                                    <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                                </mat-form-field>
                                
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="d-flex mt-3" style="float: right;">
                        <button
                        class="ml-4  addTimeBtn"
                        mat-flat-button
                        [color]="'primary'"
                        (click)="addTimeSlots()"
                     
                        >
                        <span class="ml-2 mr-1 txt">Add</span>
                        </button>
                    </div>
               
            
                </div>
                <div class="col-md-8">
                    <table class="table " style="width: 70%;">
                        <thead class="thead-dark" style="background: #000;color: #fff;">
                          <tr>
                            <th scope="col">Days</th>
                            <th scope="col">Day Type</th>
                            <th scope="col">Start Time</th>
                            <th scope="col">End Time</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let slot of timeSlots;let i=index">
                            <th scope="row">{{slot.day}}</th>
                            <th >{{slot.dayType}}</th>
                            <th >{{slot.startTime}}</th>
                            <th>{{slot.endTime}}</th>
                            <td> <button mat-icon-button (click)="deleteDay(i)">
                               <mat-icon style="color:red;"svgIcon="mat_outline:delete"></mat-icon>
                                </button></td>
                          </tr>
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <app-file-upload-manager [OnSuccessfulCreateOrUpdate]="OnSuccessfulCreateOrUpdate"></app-file-upload-manager> -->
    <app-file-upload-manager #fileUploadComponent></app-file-upload-manager>
    </div>

    <div class="">
        <div class="bottomBtns mb-3 row">
            <button class="ml-4 addBtn" mat-flat-button (click)="resetForm()" *ngIf="!isUpdate">
                <span class="ml-2 mr-1 txt">Reset</span>
            </button>
            <button class="ml-4 addBtn" mat-flat-button (click)="back()" *ngIf="isUpdate">
                <span class="ml-2 mr-1 txt">Cancel</span>
            </button>
            <span matTooltip="userTimings should be added for all the seven days" *ngIf="!isUpdate"
                [matTooltipDisabled]="isCreateOrUpdatebranchValid()" style="width: fit-content"></span>
            <button *ngIf="!isUpdate" class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'"
                [disabled]="isCreateOrUpdatebranchValid()" [ngClass]="{ 'btn-clr': !isCreateOrUpdatebranchValid() }"
                (click)="createBranch()">
                <span class="ml-2 mr-1 txt" style="color: black">Create </span>
            </button>
            <button *ngIf="isUpdate" class="ml-4 mr-6 addBtn" [ngClass]="{ 'btn-clr': !isCreateOrUpdatebranchValid() }"
                mat-flat-button [color]="'primary'" [disabled]="isCreateOrUpdatebranchValid()" (click)="updateBranch()">
                <span class="ml-2 mr-1 txt" style="color: black">Update </span>
            </button>
            <!-- <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" 
            [disabled]="!basicDetailsForm.valid || !adddressForm.valid || disableCreate" (click)="createBranch()"
               >
                <span class="ml-2 mr-1 txt">Create </span>
            </button> -->
        </div>
    </div>
</div>