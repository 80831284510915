import { DatePipe, formatDate } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'app/api.service';
import { viewUser } from 'app/meta.service';
import { FileUploadManagerComponent } from 'app/pages/commonComponents/file-upload-manager/file-upload-manager.component';
import { environment } from 'environments/environment';
import moment from 'moment';

export interface Iuser {
  full_name: string;
  email: string;
  mobile: boolean;

}
@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss'],
  providers: [DatePipe]
})

export class ViewUserComponent {

  @ViewChild('fileUploadComponent', { static: false }) fileUploadComponent: FileUploadManagerComponent;

  existingFileDataForFileManager = []
  actualexistingFilesCountForFileManager = 0

  userDetails = [];
  userAddress = [];
  userBranch = [];
  userBranchNames = [];
  userTimings = [];
  reportToUserNames = [];
  user: viewUser;
  timeSlots = [
    { day: "Sunday", dayType: "weekEnd", startTime: "8:00 Pm", endTime: "9:00 Pm" },
    { day: "Sunday", dayType: "weekEnd", startTime: "8:00 Pm", endTime: "9:00 Pm" }

  ]
  daysList = [
    { value: 'SUN', viewValue: 'Sunday' },
    { value: 'MON', viewValue: 'Monday' },
    { value: 'TUE', viewValue: 'Tuesday' },
    { value: 'WED', viewValue: 'Wednesday' },
    { value: 'THU', viewValue: 'Thursday' },
    { value: 'FRI', viewValue: 'Friday' },
    { value: 'SAT', viewValue: 'Saturday' },
  ];
  dayType = [
    { value: 'WORK_DAY', viewValue: 'Working Day' },
    { value: 'DAY_OFF', viewValue: 'Day Off' },


  ]
  newDate: any;
  myDate = new Date();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiService: ApiService, private datePipe: DatePipe
  ) { }
  ngOnInit() {
    // console.log(this.myDate)

    this.newDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    // console.log(this.newDate)
    this.getUserById();


  }
  getUserById() {
    this.userDetails = this.data.data;
    //  this.userAddress = this.data.data.
    this.userDetails.forEach(element => {
      this.userAddress.push(element.address);
      this.userBranch = element.branches;
      this.userTimings = element.timings;
      element.report_to_users.forEach(userObj => {
        this.reportToUserNames.push(userObj.full_name)
      })
      element.createdAt = element.created_at;
      if (element.created_by) {
        element.createdBy = element.created_by.full_name;
        element.createdByRole = element.created_by.role.name;
      }
      // const newdata = element.timings.map((ele) => {
      //   return (
      //     {
      //       "day": this.daysList.find(each => each.value == ele.day_of_week).viewValue,
      //       "dayType": this.dayType.find(each => each.value == ele.day_type).viewValue,
      //       // "start_time": moment(new Date(ele.start_time)).format("hh:mm A").toString(),
      //       // "end_time": moment(new Date(ele.end_time)).format("HH:mm").toString(),
      //       // "start_time": new Date(ele.end_time).toLocaleTimeString(undefined, {hour: '2-digit', minute:'2-digit'}),
      //       // "startTime": ele.start_time ? ele.start_time.substring(0, ele.start_time.length - 3) : ele.start_time,
      //       "startTime": this.convertToIst(ele.start_time),
      //       "endTime" : this.convertToIst(ele.end_time),
      //       // "endTime": ele.end_time ? ele.end_time.substring(0, ele.end_time.length - 3) : ele.end_time,
      //     }
      //   )
      // })
      // this.userTimings = newdata;
      this.userTimings.forEach(element => {
        if (element.start_time != null) {
          element.start_time = this.convertToIst(element.start_time);
          // element.start_time = element.start_time;
        }
        if (element.end_time != null) {
          element.end_time = this.convertToIst(element.end_time);
          // element.end_time = element.end_time;
        }
      });
      this.userBranch.forEach(branch => {
        this.userBranchNames.push(branch.name)
      })

      this.existingFileDataForFileManager = element.file_name_urls.map((ele) => {
        return {
          fileName: ele.filename,
          fileBaseUrl: ele.file_url,
          fileUrl: environment.apiUrl + ele.file_url
        }
      })
      this.actualexistingFilesCountForFileManager = this.actualexistingFilesCountForFileManager = this.existingFileDataForFileManager.length

    });
    this.sortTimeSlots()
    this.initializeValues()
    // this.apiService.getUserById(this.data.id).subscribe((resp) => {
    //   this.userDetails.push(resp.data.user);
    //   this.initializeValues()
    // })

  }

  initializeValues() {
    for (const key in this.userDetails) {
      // this.user.full_name =key.full_name
    }
    // this.userDetails.map(element => {
    //   this.user.full_name=element.full_name

    // });
    // this.user.full_name=this.userDetails.full_name

  }
  convertToIst(date: any) {
    const timeString = date;
    const time = new Date(`2000-01-01T${timeString}`);
    const formattedTime = time.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    return formattedTime;
    // date = new Date(this.newDate + "T" + date + "Z");
    // var endTime = moment(new Date(date)).format("hh:mm A").toString();
    // console.log(endTime)
    // return endTime
  }
  constDaysList = [
    { value: 'SUN', viewValue: 'Sunday' },
    { value: 'MON', viewValue: 'Monday' },
    { value: 'TUE', viewValue: 'Tuesday' },
    { value: 'WED', viewValue: 'Wednesday' },
    { value: 'THU', viewValue: 'Thursday' },
    { value: 'FRI', viewValue: 'Friday' },
    { value: 'SAT', viewValue: 'Saturday' },
  ]

  sortTimeSlots() {
    let daysListInOrder = []
    this.constDaysList.forEach(elem => {
      if (!daysListInOrder.includes(elem.value)) {
        daysListInOrder.push(elem.value)
      }
    })
    // console.log(this.userTimings)
    this.userTimings = this.userTimings.sort(
      (a, b) => (daysListInOrder.indexOf(a.day_of_week) < daysListInOrder.indexOf(b.day_of_week)) ? -1 : (daysListInOrder.indexOf(a.day_of_week) > daysListInOrder.indexOf(b.day_of_week)) ? 1 : 0
    ).sort(
      (a, b) => {
        if (((daysListInOrder.indexOf(a.day_of_week) < daysListInOrder.indexOf(b.day_of_week)) ? -1 : (daysListInOrder.indexOf(a.day_of_week) > daysListInOrder.indexOf(b.day_of_week)) ? 1 : 0) == 0 && a.day_type=='WORK_DAY') {
          var nowDate = new Date().toISOString().split('T')[0];
          let aZone = a.start_time.slice(-3,)
          let bZone = b.start_time.slice(-3,)
          
          var astartTime = new Date(nowDate + ' ' + a.start_time.slice(0,-2));
          var bstartTime = new Date(nowDate + ' ' + b.start_time.slice(0,-2));
          // console.log(astartTime, bstartTime)
  
          if (aZone == bZone) {
            return (astartTime < bstartTime) ? -1 : (astartTime > bstartTime) ? 1 : 0
          } else if (aZone == 'PM' && bZone == 'AM') {
            return 1
          } else if (aZone == 'AM' && bZone == 'PM') {
            return -1
          }
        }
      }
    )
  }

}
