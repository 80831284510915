<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">
        <div class="row mt-8 justify-content-between">
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
            <h1 class="h3 ml-4 mr-6 heading">SCHEDULES</h1>
            </div>
    </div>
    <div class="row ">
                
        <div class="col-sm-12 col-md-4 col-lg-2" *ngIf="isUserAllowedToViewBranches">
            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                  <mat-select [placeholder]="'All Branches'" [(ngModel)]="selectedFilterbranchId" (selectionChange)="onGetAllSchedules()">
                    <mat-option value="">All Branches</mat-option>
                    <mat-option *ngFor="let branch of branchesList" [value]="branch.id">{{branch.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
                <mat-form-field
                class="flex-auto gt-xs:pr-3"
                style="width: 90%"
                [subscriptSizing]="'dynamic'"
            >
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:search'"
                ></mat-icon>
                <input
                    matInput
                    (keyup)="applyFilter($event)"
                    [autocomplete]="'off'"
                    [placeholder]="'Search Schedule'"
                />
            </mat-form-field>
        </div>
    </div>
    
    <div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="Customer">
                <th mat-header-cell *matHeaderCellDef >Customer</th>
                <td mat-cell *matCellDef="let row"> {{row.customer | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="Plan">
                <th mat-header-cell *matHeaderCellDef >Plan</th>
                <td mat-cell *matCellDef="let row"> {{row.plan | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="PlanType">
                <th mat-header-cell *matHeaderCellDef >Plan Type</th>
                <td mat-cell *matCellDef="let row"> {{row.planType | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="PlanSubType">
                <th mat-header-cell *matHeaderCellDef >Plan Sub Type</th>
                <td mat-cell *matCellDef="let row"> {{row.planSubType | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="PlanCategory">
                <th mat-header-cell *matHeaderCellDef >Plan Category</th>
                <td mat-cell *matCellDef="let row"> {{row.planCategory | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="PlanDuration">
                <th mat-header-cell *matHeaderCellDef >Plan Duration</th>
                <td mat-cell *matCellDef="let row"> {{row.planDuration}} </td>
            </ng-container>

            <ng-container matColumnDef="PlanStatus">
                <th mat-header-cell *matHeaderCellDef >Plan Status</th>
                <td mat-cell *matCellDef="let row"> {{row.planStatus}} </td>
            </ng-container>

            <ng-container matColumnDef="Branch">
                <th mat-header-cell *matHeaderCellDef >Branch</th>
                <td mat-cell *matCellDef="let row"> {{row.branch | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                    <button mat-button [matMenuTriggerFor]="menu"><mat-icon style="color:#0038FF;font-size: 30px;" svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                      <button *ngIf="row.planTypeAlias=='PLAN' && isUserAllowedToViewSchedules" mat-menu-item (click)="getPlanSchedules(row)">View Plan Schedules</button>
                      
                      <button *ngIf="row.planTypeAlias=='ADD_ON_PACK' && isUserAllowedToViewSchedules" mat-menu-item (click)="getAddonPlanSchedules(row)">View Addon Plan Schedules</button>
                      <button *ngIf="row.planTypeAlias=='ADD_ON_PACK' && isUserAllowedToCreateSchedules" mat-menu-item (click)="createAddonPlanSchedule(row)">Create Addon Plan Schedule</button>
                    </mat-menu>
                  
                  </td>
                  
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- when there is no matching data it will be visible -->
            <div>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">
                        <h3 style="display: flex;justify-content: center; margin:10%;">
                            No Records Found
                        </h3>
                    </td>
                </tr>
            </div>

        </table>

    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>
