<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">
        <div class="row mt-8 justify-content-between">
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
              <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">
                PLANS&nbsp;SUB&nbsp;TYPES
              </h1>
            </div>
      
            <div class="col-xs-12 col-sm-6 col-md-4  mb-2 d-flex justify-content-end" *ngIf="isUserAllowedToCreatePlanSubType">
                <button class="ml-4 addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
                (click)="addPlanSubType()">
                <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 txt" style="color: white">Create Plan SubType</span>
            </button>
          </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-2">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                   <mat-select [placeholder]="'Active Plan Sub Type'" [(ngModel)]="IDPlanSubType.planTypeActive" (selectionChange)="onFilterPlanType()">
                       <mat-option value="">All</mat-option>
                       <mat-option *ngFor="let plan of PlanStatusSubType" [value]="plan.value">{{plan.viewValue}}</mat-option>
                   </mat-select>
               </mat-form-field>
           </div>
           <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
               <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;" [subscriptSizing]="'dynamic'">
                   <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                   <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'"
                       [placeholder]="'Search Plan Sub Type'">
               </mat-form-field>
           </div>
     </div>



    
    <div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="PlanSubType">
                <th mat-header-cell *matHeaderCellDef >Plan Sub Type</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.name}}
                </td>
            </ng-container>
            <ng-container matColumnDef="PlanType">
                <th mat-header-cell *matHeaderCellDef>Plan Type</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.parent_plan_type.name}}
                </td>
            </ng-container>
            <ng-container matColumnDef="CreatedBy">
                <th mat-header-cell *matHeaderCellDef>Created By</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.created_by?.full_name}}({{row.created_by?.role.name}}) </td>

            </ng-container>
            <ng-container matColumnDef="CreatedDate">
                <th mat-header-cell *matHeaderCellDef>Created Date</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.created_at  | dateFormat}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon style="color:#0038FF;font-size: 30px;" svgIcon="heroicons_outline:dots-vertical"></mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="getPlanSubTypeById(row)">View</button>
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToEditPlanSubTypes"
                            (click)="editPlanSubType(row)">Edit</button>
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToDeactivatePlanSubType"
                            (click)="verifyDeactivate(row.id)">Deactivate</button>
                        <button mat-menu-item *ngIf="row.is_removed && isUserAllowedToReactivatePlanSubType"
                            (click)="reactivatePlanSubType(row.id)">Reactivate</button>
                    </mat-menu>

                </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <div>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">
                        <h3 style="display: flex;justify-content: center; margin:10%;">
                            No Records Found
                        </h3>
                    </td>
                </tr>
            </div>
        </table>
    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>