<div class="bgCard" >
    <div class="dilogStl">
        <h1 style="
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;" mat-dialog-title>Usage Report</h1>
           <button align="end" mat-icon-button mat-dialog-close >
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>       
          </button>
    </div>
    <mat-dialog-content class="mat-typography" >
      <div class="card dataCard">
        <div class="col-12 row"  >
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="fw-bold fieldName">
                From Date
                <span class="text-danger">*</span>
            </div>
            <div class="">
                <mat-form-field class="flex-auto gt-xs:pr-3 w-full">
                    <input matInput readonly [matDatepicker]="picker" [placeholder]="'From Date'" [(ngModel)]="fromDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
          </div>
      <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="fw-bold fieldName">
              To Date
              <span class="text-danger">*</span>
          </div>
          <div class="">
          
              <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;">
                  <input matInput readonly [matDatepicker]="picker1" [(ngModel)]="toDate"
                      [placeholder]="'To Date'" [min]="fromDate" [max]="maxDate">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>

          </div>
        </div>
        </div>
      <div class="col-12 row"  >
          <div class=" col-sm-12 col-md-12 col-lg-4">
              <div class="fw-bold fieldName">
                  Branch
              </div>
              <div class="" >
                  <mat-form-field class="flex-auto gt-xs:pr-3 w-full"  >
                    <mat-select [placeholder]="'All Branches'"  [(ngModel)]="selectedBranchIds" (ngModelChange)="getUsers()" multiple>
                      <mat-checkbox class="" [checked]="allBranchesChecked" [indeterminate]="someBranchesChecked()" (change)="setAllBranchesChecked($event)">
                          Select All
                      </mat-checkbox>
                      <mat-option *ngFor="let branch of branchIdNameList" [value]="branch.id">{{branch.name | titlecase  }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                 
                </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="fw-bold fieldName">
                 Role
              </div>
              <div class="" >
                  <mat-form-field class="flex-auto gt-xs:pr-3 w-full"  >
                    <mat-select [placeholder]="'All Roles'"  [(ngModel)]="selectedroleIds" (ngModelChange)="getUsers()" multiple>
                      <mat-checkbox class="" [checked]="allRolesChecked" [indeterminate]="someRolesChecked()" (change)="setAllRolesChecked($event)">
                          Select All
                      </mat-checkbox>
                      <mat-option *ngFor="let role of roleIdNameList" [value]="role.id">{{role.name | titlecase }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="fw-bold fieldName">
                  User
              </div>
              <div class="" >
                  <mat-form-field class="flex-auto gt-xs:pr-3 w-full" >
                    <mat-select [placeholder]="'All Users'" [(ngModel)]="selectedUserIds" multiple >
                      <mat-checkbox class="" [checked]="allUsersChecked" [indeterminate]="someUsersChecked()" (change)="setAllUsersChecked($event)">
                          Select All
                      </mat-checkbox>
                      <mat-option *ngFor="let user of userIdNameList" [value]="user.id">{{user.full_name | titlecase }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
          </div>
      </div>
      <div class=" mt-8 mb-4 d-flex justify-content-center">
        <div class="">
            <button class="ml-4 addBtn" [ngClass]="{'btn-clr': (fromDate || toDate)}" mat-flat-button [color]="'primary'" [disabled]="!fromDate || !toDate" (click)="getUsageReport()" >
                <mat-icon class="white-icon" [svgIcon]="'mat_outline:file_download'"></mat-icon>
              <span class="ml-2 mr-1 txt" style="color: black">Download</span>
            </button>
          </div>
      </div>
      </div>
    </mat-dialog-content>
</div>
