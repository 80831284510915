<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-2  mt-8 w-auto">
      <h1 class="h3 ml-4 mr-6 heading">
        ATTENDANCE
      </h1>
    </div>
      <div class="col-sm-12 col-md-6 col-lg-2 mt-8" *ngIf="isUserAllowedToViewBranches">
        <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;">
          <mat-select [placeholder]="'All Branches'" [(ngModel)]="IAttendance.branchId"
            (selectionChange)="onFilterAttendance()">
            <mat-option value="">All Branches</mat-option>
            <mat-option *ngFor="let branch of branches_list" [value]="branch.id">{{branch.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-2 mt-8" *ngIf="isUserAllowedToViewRoles">
        <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;">
          <mat-select [placeholder]="'All Branches'" [(ngModel)]="IAttendance.roleId"
            (selectionChange)="onFilterAttendance()">
            <mat-option value="">All Roles</mat-option>
            <mat-option *ngFor="let role of roleList" [value]="role.id">{{role.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-2 mt-8">
        <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;" [subscriptSizing]="'dynamic'">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'" [placeholder]="'Search User'">
        </mat-form-field>
      </div>
    <div class="col-sm-12 col-md-6 col-lg-4 mt-8 w-auto justify-content-end">
      <div>
        <button class="addBtn m-1" style="width: 170px" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
          (click)="downloadReport()" *ngIf="isUserAllowedToDownloadAttendanceReport">
          <mat-icon class="white-icon" [svgIcon]="'mat_outline:file_download'"></mat-icon>
          <span class="" style="color: white">Attendace Report</span>
        </button>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8 mt-8 overflow-auto">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

      <!-- ID Column -->
      <ng-container matColumnDef="UserName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
        <td mat-cell *matCellDef="let row"> {{row.full_name | titlecase}} </td>

      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell *matCellDef="let row"> {{row.role?.name}}
        </td>
      </ng-container>
      <ng-container matColumnDef="branch">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Branch</th>

        <td mat-cell class="txt_over_flow" *matCellDef="let row" matTooltipPosition="above"
          matTooltip={{row.branchNames}}>
          <!-- <span *ngFor="let funct of row?.branches">{{funct.name}}</span> -->
          {{row.branchNames?.join(", ")}}
        </td>
      </ng-container>
      <ng-container matColumnDef="LastActiveTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Active Time</th>
        <td mat-cell *matCellDef="let row"> {{row.last_active_date_time | dateTimeFormat}}
        </td>
      </ng-container>


      <!-- <ng-container matColumnDef="Actions">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
            <mat-cell class="" style="height: 52px;" *matCellDef="let row;">
              <button mat-icon-button *ngIf="!row.is_removed" (click)="viewUserAttendance(row.id)">
                <mat-icon style="color:#0FAE1F" svgIcon="heroicons_outline:pencil"></mat-icon>
              </button>
               
              </mat-cell>
              
          </ng-container> -->
      <ng-container matColumnDef="Actions">
        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
        <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row ">
          <button mat-button [matMenuTriggerFor]="menu"><mat-icon style="color:#0038FF;font-size: 30px;"
              svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="viewUserAttendance(row.id)">View</button>

          </mat-menu>

        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <!-- Row shown when there is no matching data. -->
      <div>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="12">
            <h3 style="display: flex;justify-content: center; margin:10%;">
              No Records Found
            </h3>
          </td>
        </tr>
      </div>
    </table>
  </div>
  <mat-paginator style="display:flex;justify-content: end;" 
  (page)="onPaginateChange($event)" [length]="totalCount"
  [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
  aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>