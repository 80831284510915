<div class="bgCard">
    <div class="dilogStl">
        <h1 style="
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;" mat-dialog-title>View Logs </h1>
           <button align="end" mat-icon-button mat-dialog-close >
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>       
          </button>
    </div>
    <mat-dialog-content class="mat-typography" >
        <div class="card dataCard">
            <div class="mt-2 ml-3 " >
                
                <div class="row" >
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                        <div class="listFont">
                            User Name:
                        </div>
                        <div class="listFont">
                            {{fullName}}
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                        <div class="listFont">
                          Role:
                        </div>
                        <div class="listFont">
                            {{role}}
                        </div>
                    </div>
                   
                  
                 
                </div>
                <!-- <div class="row mt-8">
                    <div class="col-md-3">
                        <div class="listFont">
                            Gender:
                        </div>
                        <div class="">
                          {{val.gender}}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="listFont">
                           Date of Birth
                        </div>
                        <div class="">
                           {{val.dob}}
                        </div>
                    </div>
                </div> -->
            </div>
         
           
          </div>
          <!-- <div class="col-12 d-flex flex-column align-items-center" >
            <table class="table table-bordered" style="width: 70%; margin-top: 5%;">
                <thead class="thead-dark" style="background: #000;color: #fff;">
                    <tr>
                        <th scope="col">Log Type</th>
                        <th scope="col" class="d-flex justify-content-center">Log Time</th>
                    </tr>
                </thead>
                <tbody style="background-color: #fff;"  *ngFor="let row of attendance_logs">
                    <tr >
                        <th scope="row"> 
                            <span *ngIf="row.log_type == 'CHECK_IN'">Check In </span>
                            <span *ngIf="row.log_type == 'CHECK_OUT'">Check Out </span>

                        </th>
                        <th class="d-flex justify-content-center">
                            <span class="mt-1">{{row.log_date_time | dateFormat}}</span>
                        </th>
                    </tr>
                </tbody>
            
            </table>
           
        </div> -->


        <div class="col-12 d-flex flex-column align-items-center" >
            <table class="table table-bordered" style="width: 70%; margin-top: 5%;">
                <thead class="thead-dark" style="background: #000;color: #fff;">
                    <tr>
                        <th scope="col" class="">Check In</th>
                        <th scope="col" class="">Check Out</th>
                    </tr>
                </thead>
                <tbody style="background-color: #fff;"  *ngFor="let row of attendance_logs">
                    <tr >
                        <td class="fw-bold"> 
                            <span *ngIf="row?.check_in_date_time">{{row.check_in_date_time | date:'dd-MM-YYYY, h:mm a'}}</span>
                        </td>
                        <td class="fw-bold">
                            <span *ngIf="row?.check_out_date_time">{{row.check_out_date_time | date:'dd-MM-YYYY, h:mm a'}}</span>
                        </td>
                        <!-- <th>Krishna</th>
                        <th>Krishna</th> -->
                        <!-- <td> <button mat-icon-button (click)="deleteDay(i)">
                                <mat-icon style="color:red;" svgIcon="mat_outline:delete"></mat-icon>
                            </button></td> -->
                    </tr>
                </tbody>
            
            </table>
           
        </div>

    </mat-dialog-content>
</div>