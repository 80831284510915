<div class="container-fluid">
    <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-4  mt-8 w-auto">
            <h1 class="h3 ml-4 mr-6  heading" style="width: 30%;">
                ADD&nbsp;ON&nbsp;TYPES
            </h1>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-2  mt-8">
            <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 90%;">
                <mat-select [placeholder]="'Active Plan Type'" [(ngModel)]="IDPlanType.planTypeActive" (selectionChange)="filterPlanType($event)">
                    <mat-option value="">All</mat-option>
                    <mat-option *ngFor="let plan of PlanStatusType" [value]="plan.value">{{plan.viewValue}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-2  mt-8">
            <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;" [subscriptSizing]="'dynamic'">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'"
                    [placeholder]="'Search Add On'">
            </mat-form-field>
        </div>
        <div class="col-sm-6 col-lg-4 mt-8 w-auto" *ngIf="isUserAllowedToCreateAddonTypes">
            <button class="ml-4 addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
                (click)="createAddOn()">
                <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 txt" style="color: white">Create Add On Type</span>
            </button>
        </div>
    </div>
    <div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="AddOnName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Add On Type Name</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.name}}
                </td>
            </ng-container>
            <ng-container matColumnDef="CreatedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.created_by.full_name}}({{row.created_by.role.name}}) </td>

            </ng-container>
            <ng-container matColumnDef="CreatedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.created_at  | dateFormat}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef>Actions</th>
                <mat-cell style="height: 52px;align-items: center;justify-content: center;" *matCellDef="let row;">
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon style="color:#0038FF;font-size: 30px;" svgIcon="heroicons_outline:dots-vertical"></mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="getAddOnTypeById(row)">View</button>
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToEditAddonTypes"
                            (click)="editAddOnType(row)">Edit</button>
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToDeactivateAddonTypes"
                            (click)="deactivatePlanType(row.id)">Deactivate</button>
                        <button mat-menu-item *ngIf="row.is_removed && isUserAllowedToReactivateAddonTypes"
                            (click)="reactivatePlanType(row.id)">Reactivate</button>
                    </mat-menu>

                </mat-cell>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <div>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">
                        <h3 style="display: flex;justify-content: center; margin:10%;">
                            No Records Found
                        </h3>
                    </td>
                </tr>
            </div>
        </table>
    </div>
    <mat-paginator style="display:flex;justify-content: end;" [pageSizeOptions]="[ 10, 25, 100]"
    aria-label="Select page of users"></mat-paginator>
</div>