<div class="container-fluid">


    <div>
        <div class="d-flex m-3">
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{title}}</h2>
        </div>

        <div class="card"  style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="mt-2 ml-3 mb-4">
                <div class="row  mr-3 ml-3 mt-3">
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Select Start Date
                            <span class="text-danger">*</span> (Start Of The Month)
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-75">
                                <input id="FromDate" name="FromDate" required #FromDate="ngModel" matInput readonly [matDatepicker]="picker" [(ngModel)]="fromDate"
                                    [placeholder]="'From Date'" >
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="FromDate.invalid && FromDate.touched && FromDate.errors.required">Start Date is required</mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Select End Date (End Of The Month)
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-75">
                                <input matInput readonly [matDatepicker]="picker1" [(ngModel)]="toDate" [min] = "fromDate" [disabled]="!fromDate"
                                    [placeholder]="'End Date'" >
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>

                        </div>
                    </div>
                    <mat-hint class="hintStyle">**Note: Monthly Pay or Session Pay any one is required</mat-hint>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Enter Monthly Pay
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="Monthly Pay" style="background: #FFFFFF;" matInput placeholder="Enter Monthly Pay"
                                [(ngModel)]="monthlypay" oninput="if(!this.value.match('^[1-9][0-9]*$')) this.value='';">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Enter Session Pay
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="Session Pay" style="background: #FFFFFF;" matInput placeholder="Enter Session Pay"
                                [(ngModel)]="sessionlypay" oninput="if(!this.value.match('^[1-9][0-9]*$')) this.value='';">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                           Over Time
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                                    <mat-select id="OverTimeId" name="OverTimeId" required #OverTimeId="ngModel" [placeholder]="'Select Over Time'"  [(ngModel)]="Overtime">
                                        <mat-option *ngFor="let overtime of OverTime" [value]="overtime.value">{{overtime.viewValue}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="OverTimeId.invalid && OverTimeId.touched && OverTimeId.errors.required">Over time is required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                


            </div>


        </div>
    </div>

    <div class="btnposition">
        <div class="bottomBtns mb-3 row">
            <button class="ml-4 addBtn"  mat-flat-button (click) = 'resetUserPayable()' *ngIf="!isUpdate">
                <span class="ml-2 mr-1 txt">Reset</span>
            </button>
            <button class="ml-4 addBtn" mat-flat-button (click)="back()" *ngIf="isUpdate">
                <span class="ml-2 mr-1 txt">Cancel</span>
            </button>
            <!-- Why tghis span is added here -->
            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" [ngClass]="{'btn-clr': !(!fromDate || Overtime == null || (!monthlypay && !sessionlypay ))}"(click)="createPlan()" [disabled]="!fromDate || Overtime == null || (!monthlypay && !sessionlypay )" *ngIf="!isUpdate">
                <span class="ml-2 mr-1 txt" style="color: white">Create </span>
            </button>
            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" [ngClass]="{'btn-clr': !(!fromDate || Overtime == null || (!monthlypay && !sessionlypay ))}" [disabled]="!fromDate || Overtime == null || (!monthlypay && !sessionlypay )" (click)="updatePlan()" *ngIf="isUpdate" >
                <span class="ml-2 mr-1 txt" style="color: white">Update </span>
            </button>
           
        </div>
    </div>
    <!-- <div class="loader" *ngIf="showloading">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div> -->
</div>