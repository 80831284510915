import { Component, Input, OnInit } from '@angular/core';
import { FileServerService } from 'app/fileServer.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-file-upload-manager',
    templateUrl: './file-upload-manager.component.html',
    styleUrls: ['./file-upload-manager.component.scss'],
})
export class FileUploadManagerComponent implements OnInit{

  // @Input() OnSuccessfulCreateOrUpdate: Subject<boolean>;
  
  files: any[] = [];
  maxSize: number = 5;

  /* filename and fileUrl will be there in every object*/
  @Input() existingFileData = []
  @Input() actualexistingFilesCount = 0
  @Input() shouldShowFileUploader: boolean = true
  @Input() shouldShowExistingFiles: boolean = true

    constructor(
        public fileServerService: FileServerService,
        private toastr: ToastrService
    ) { }
  
  ngOnInit() {

    // this.OnSuccessfulCreateOrUpdate.subscribe(result => {
    //   if (result) {
    //     console.log("I got true from parent", result)
    //   }
    //   console.log("I got fasle from parent", result)

    //   this.uploadFiles()

    // })
  }

    /**
     * on file drop handler
     */
    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(files) {
        this.prepareFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    /**
     * Simulate the upload process
     */
    uploadFilesSimulator(index: number) {
        setTimeout(() => {
            if (index === this.files.length) {
                return;
            } else {
              const progressInterval = setInterval(() => {
                  // console.log("index of file---", this.files[index])
                    if (this.files[index].progress === 100) {
                        clearInterval(progressInterval);
                        this.uploadFilesSimulator(index + 1);
                    } else {
                        this.files[index].progress += 5;
                    }
                }, 200);
            }
        }, 1000);
    }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
  prepareFilesList(files: Array<any>) {
        var is_invalid_file = false;
        for (const file of files) {
            if (this.isValidFileSize(file)) {
                is_invalid_file = true;
            } else {
              file.progress = 0;
              this.files.push(file);

            }
        }
        if (is_invalid_file == false) {
            this.uploadFilesSimulator(0);
        }
    }

    private isValidFileSize(file) {
        var fileSizeinMB = file.size / (1024 * 1000);
        /** convert upto 2 decimal place */
        var size = Math.round(fileSizeinMB * 100) / 100;
        if (size > this.maxSize) {
          this.toastr.error(
            'Error (File Size): ' + file.name + ': exceed file size limit of ' +
            this.maxSize + 'MB ( ' + size + 'MB )'
          );
          return true;
        }
        return false;
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes, decimals) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    }
  
    reset() {
      this.files = [];
    }
  
  
  deleteFiles() {

    let deletableFileUrls = []

    this.fileServerService.deleteFiles(deletableFileUrls).subscribe((resp) => {
      // Files deleted successfully
    }, (err) => {
      this.toastr.error("Something went wrong")
    });
    
  }

  uploadedFileUrls = []
  uploadFiles(pathToStoreFiles) {

    // This function is called only if there is atleast one file
    
    const formData = new FormData();
      [...this.files].forEach((file) => {
        formData.append("uploadable_files", file, file.name);
      });
      formData.append('path_to_store_files', pathToStoreFiles)

      return this.fileServerService.uploadFiles(formData)
  }


  deleteExistingFile(fileItem) {

    let fileIndex = this.existingFileData.findIndex(
      existingFileItem => existingFileItem == fileItem
    )
    this.existingFileData.splice(fileIndex, 1)
  }
  
}
