import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import {   ITagActions, MetaService, featureConstants } from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';
import { CreateTagComponent } from '../create-tag/create-tag.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { VerifyDeactivateComponent } from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';



export interface Element {
  is_removed: boolean;
  id: String;
}


@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['Name', 'Alias', 'CreatedBy', 'CreatedDate' ,'Actions'];
  dataSource = new MatTableDataSource<Element>();
  TagsStatusType = [
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }

  ]
  IDTag: ITagActions = {
    tagActive: "",
    tagId: "",
  }
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValue=null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewTags = this.userAllowedFeatures.indexOf(featureConstants.VIEW_TICKETS) > -1
  isUserAllowedToCreateTags = this.userAllowedFeatures.indexOf(featureConstants.CREATE_TICKETS) > -1
  isUserAllowedToEditTags = this.userAllowedFeatures.indexOf(featureConstants.EDIT_TICKETS) > -1
  isUserAllowedToDeactivateTags = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_TICKETS) > -1
  isUserAllowedToReactivateTags = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_TICKETS) > -1

  constructor(private router: Router,public meta: MetaService, public dialog: MatDialog, public apiService: ApiService, private toastr: ToastrService, private _liveAnnouncer: LiveAnnouncer) {
    this.getScreenSize();
  }
  
  ngOnInit() {
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');

    this.filterTags()
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getTags(){
    this.apiService.getTags().subscribe((resp)=>{
      if (resp.status == "success") {
        this.dataSource.data = resp.data.tags;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
   
    // }
    this.onFilterTags()
  }


  filterTags() {

    const data = {
      filter_by_active_status: this.IDTag.tagActive,
      filter_by_search_term: this.filterValue || null,
      offset: this.bOffset,
      limit: this.bLimit,
    }

    this.apiService.getTagsByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data =  resp.data.tags;
        // this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.totalCount=resp.data.total_count;
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }
  
  
  scrHeight: number;
  scrWidth: number;
  viewWidth:any
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  createTag() {
    if(this.scrWidth < 1450){
      this.viewWidth='90%'
    }else{
      this.viewWidth='40%'
    }
    let dialogRef = this.dialog.open(CreateTagComponent, {
      // data: {
      //   data: data,
      // },
      // // height: auto,
      width: this.viewWidth,
      
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getTags()
    });
  }
  editTags(row) {
    if(this.scrWidth < 1450){
      this.viewWidth='90%'
    }else{
      this.viewWidth='40%'
    }
    let dialogRef = this.dialog.open(CreateTagComponent, {
      data: {
        data: row,
      },
      // // height: auto,
      width: this.viewWidth,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getTags()
    });
  }

  verifyDeactivate(tag_id:string){
    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
        "message": "This Tag won't be visible on Mobile App."
      }, // Pass data to the dialog
      width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {

      if (this.meta.dialogClosed) {
        this. deactivateTags(tag_id=tag_id)
      }
    });
  }


  deactivateTags(tag_id: String) {
    this.apiService.deactivateTags(tag_id = tag_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          tag => {
            if (tag.id == tag_id)
            tag.is_removed = true
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  reactivateTags(tag_id: String) {
    this.apiService.reactivateTag(tag_id = tag_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          tag => {
            if (tag.id == tag_id){
              tag.is_removed = false
            }
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }
  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.filterTags(); 
  }


  onFilterTags(){
    this.bOffset=0;
    this.pageIndex=0;
    this.filterTags()
  }

}
