<div class="card m-2" style="border: none">
    <div class="card-header" style="border: none;">
        <h4>
            File Upload Manager
        </h4>
    </div>
    <div class="card-body" style="border: none">
        <div class="row m-3 justify-content-between">
            <div class="col-5" *ngIf="shouldShowExistingFiles">
                <h3> Existing Files </h3>
                <div class="" style="max-height:450px; overflow-y:scroll;">
                    <div class="" *ngFor="let fileItem of existingFileData">
                        <div class="card w-95 m-1">
                            <div class="card-body d-flex">
                                <h5 class="card-title col-8 d-flex justify-content-start">{{fileItem.fileName}}</h5>
                                <div class="col-1"></div>
                                <div class="col-3 d-flex justify-content-end">
                                    <button>
                                        <a [href]="fileItem.fileUrl" download>
                                            <mat-icon style="color: blue" [svgIcon]="'heroicons_solid:arrow-down'"></mat-icon>
                                        </a>
                                    </button>
                                    <button *ngIf="shouldShowFileUploader">
                                        <mat-icon class="" style="color: red" svgIcon="mat_outline:delete" (click)="deleteExistingFile(fileItem)"></mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-1"></div>
        
            <div class="col-6" *ngIf="shouldShowFileUploader">
                <div class="row">
                 <div class="col-xl-7 col-md-12 ">
                <h3 class=""> Upload New Files </h3>
                <div class="d-flex">
                <div
                    class="container"
                    appDnd
                    (fileDropped)="onFileDropped($event)"
                >
                    <input
                        type="file"
                        #fileDropRef
                        id="fileDropRef"
                        multiple
                        (change)="fileBrowseHandler($event.target.files)"
                    />
                    <h3>Drag and drop file here</h3>
                    <h4>or</h4>
                    <label for="fileDropRef">Browse File</label>
                    <h6 style="color: grey">Maximum file size is 5 MB</h6>
                </div>
            </div>
            </div>
            <div class="col-xl-4 col-md-12 upload-file" >
                <div class="files-list " style="max-height:450px;width: 200px;">
                    <div
                        class="single-file file-body"
                        *ngFor="let file of files; let i = index"
                       
                    >
                        <div class="info">
                            <h4 class="name">
                                {{ file?.name }}
                            </h4>
                            <p class="size">
                                {{ formatBytes(file?.size) }}
                            </p>
                            <app-progress
                                [progress]="file?.progress"
                            ></app-progress>
                        </div>

                        <div class="delete" (click)="deleteFile(i)">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="18"
                                viewBox="0 0 14 18"
                            >
                                <path
                                    fill="#B1B1B1"
                                    fill-rule="nonzero"
                                    d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"
                                />
                            </svg>
                        </div>
                    </div>
                        </div>
                    </div>
               
            </div>
            </div>
        </div>
    </div>
</div>
