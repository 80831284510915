<div class="container-fluid" style="background-color: #F1F5F9; height:100%;">
    <div class="d-flex m-2">
        <div class=" mr-2">
            <button mat-icon-button (click)="back()">
                <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
            </button>
        </div>
        <h2>EDIT LOGS</h2>
        <div class="log_date">
            <h3>Date:{{log_date}}</h3>
        </div>
    </div>

    <div class="card dataCard">
        <div class="mt-2 ml-3 ">

            <div class="d-flex  m-4">
                <div style="width: 30%;" class="mr-6">
                    <div class="fw-bold">
                        Select Branch
                    </div>
                    <div class="">
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                            <mat-select [placeholder]="'All Branches'" [(ngModel)]="branch" >
                              <mat-option  value="">All Branches</mat-option>
                              <mat-option *ngFor="let branch of branches_list" [value]="branch.id">{{branch.name}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                    </div>
                </div>
                <div style="width: 30%;" class="mr-6">
                    <div class="fw-bold">
                        Select Device
                        <span class="text-danger">*</span>
                    </div>
                    <div class="">
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                            <mat-select [placeholder]="'Select Device'" [(ngModel)]="attendance_device_id" >
                              <mat-option *ngFor="let device of devices_list" [value]="device.id">{{device.name}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex  m-4">
                <div style="width: 30%;" class="mr-6">
                    <div class="fw-bold">
                        Select Time
                        <span class="text-danger">*</span>
                    </div>
                    <div class="">
                        <div class="">
                            <!-- <mat-form-field class="flex-auto gt-xs:pr-3 "style="width: 100%;">
                                <input matInput class="cursor-pointer"  [placeholder]="'Select Start Time'" [(ngModel)]="startTime"
                                    matTimepicker>
                                <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                            </mat-form-field> -->
                            <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                <input matInput class="cursor-pointer" mode="12h" [placeholder]="'Select Time'"
                                    [(ngModel)]="startTime" matTimepicker>
                                <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                            </mat-form-field>

                        </div>
                    </div>
                </div>
                <div style="width: 30%;">
                    <div class="fw-bold">
                        Select Check In or Check Out
                        <span class="text-danger">*</span>
                    </div>
                    <div class="">
                        <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                            <mat-select [placeholder]="'Check-In / Check-Out'" [(ngModel)]="check_type">
                                <mat-option value="CHECK_IN">CHECK_IN</mat-option>
                                <mat-option value="CHECK_OUT">CHECK_OUT</mat-option>
                                <!-- <mat-option value="country-3">Country 3</mat-option> -->
                            </mat-select>
                            <!-- <mat-icon
                                class="icon-size-5"
                                matPrefix
                                [svgIcon]="'heroicons_solid:location-marker'"></mat-icon> -->
                        </mat-form-field>
                    </div>
                </div>

                <div class="d-flex mb-1 mr-3" style="float: right; margin-top: 30px;">
                    <button style="border-radius: 10px !important; width: 140px;" class="ml-4 addBtn" mat-flat-button
                    [color]="'primary'" (click)="AddUersLogs()" [disabled]="!attendance_device_id || !startTime || !check_type">
                        <!-- <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon> -->
                        <span class="ml-2 mr-1 txt" style="color: white">ADD</span>
                    </button>
                </div>


            </div>

        </div>
    </div>

    <div class="col-12 d-flex flex-column align-items-center">
        <table class="table table-bordered" style="width: 50%; margin-top: 5%;">
            <thead class="thead-dark" style="background: #000;color: #fff;">
                <tr>
                    <th scope="col">Log Type</th>
                    <th scope="col" class="">Log Time</th>
                    <!-- <th scope="col">Start Time</th>
                        <th scope="col">End Time</th>
                        <th scope="col"></th> -->
                </tr>
            </thead>
            <tbody style="background-color: #fff;" *ngFor="let row of attendance_logs;let i=index">
                <tr>
                    <th scope="row">
                        <span *ngIf="row.log_type == 'CHECK_IN'">Check In </span>
                        <span *ngIf="row.log_type == 'CHECK_OUT'">Check Out </span>

                    </th>
                    <th class="d-flex">
                        <span class="mt-1">{{row.log_date_time | timeFormat}} </span>
                        <button mat-icon-button (click)="deleteRow(i)" *ngIf="row.is_removable">
                            <mat-icon style="color:red;" svgIcon="mat_outline:delete"></mat-icon>
                        </button>
                    </th>
                    <!-- <th>Krishna</th>
                        <th>Krishna</th> -->
                    <!-- <td> <button mat-icon-button (click)="deleteDay(i)">
                                <mat-icon style="color:red;" svgIcon="mat_outline:delete"></mat-icon>
                            </button></td> -->
                </tr>
            </tbody>

        </table>

    </div>
    <div class="" style="float: right;">
        <button class="ml-4 addBtn" mat-flat-button [color]="'primary'" (click)="updateLogs()" [disabled]="disableUpdate">
            <span class="ml-2 mr-1 txt" style="color: black">Update</span>
        </button>
    </div>
</div>