import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-reschedule',
  templateUrl: './update-reschedule.component.html',
  styleUrls: ['./update-reschedule.component.scss']
})
export class UpdateRescheduleComponent {

  rescheduleSlotId = null;
  previousSlotBookingId = null;
  previousSlotInfo = null;
  requestedDateTimeSlots = []

  selectedSlotDate = null;
  availableSlotTimes = []
  selectedSlotTimeAlias = null;
  availableTrainers = []
  selectedTrainerId = null;

  back() {
    sessionStorage.removeItem('rescheduleSlotIdForUpdateSchedule')
    this.router.navigate(['Admin/reschedules'])
  }

  constructor(private router: Router, public apiService: ApiService, public toastr: ToastrService) {
    this.rescheduleSlotId = sessionStorage.getItem('rescheduleSlotIdForUpdateSchedule')

    if (!this.rescheduleSlotId) {
      this.back()
    }
    this.getRescheduleSlotInfo()
  }


  getRescheduleSlotInfo() {

    this.apiService.getRescheduleInfoById(this.rescheduleSlotId).subscribe((response) => {
      if (response.status == "success") {
        this.previousSlotBookingId = response.data.reschedule.previous_slot.id
        this.previousSlotInfo = response.data.reschedule.previous_slot
        this.previousSlotInfo.slotDate = moment(new Date(this.previousSlotInfo.start_date_time)).format("YYYY-MM-DD").toString();
        this.previousSlotInfo.slotTime = moment(new Date(this.previousSlotInfo.start_date_time)).format("hh:mm:ss A").toString();
        
        // Reschedules V1 API
        // this.requestedDateTimeSlots = response.data.reschedule.reschedule_to_time_slots.map(dateTimeSlots => {
        //   return {
        //     "slotDate": dateTimeSlots.slot_date,
        //     "timeSlots": dateTimeSlots.time_slots.map(time_slot => {
        //       // adding dummy date for conversion purpose
        //       return moment(new Date("2023-01-01 " + time_slot.start_time)).format("hh:mm:ss A").toString();
        //     })
        //   }
        // })

        // Reschedules V2 API
        this.requestedDateTimeSlots = response.data.reschedule.reschedule_to_time_slots.map(dateTimeSlot => {
          return {
            "slotDate": dateTimeSlot.slot_date,
            "timeSlot": moment(new Date("2023-01-01 " + dateTimeSlot.start_time)).format("hh:mm:ss A").toString()
            // adding dummy date for conversion purpose
          }
        })
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  getAvailableTimeSlots() {
    this.selectedSlotTimeAlias = null;
    this.selectedTrainerId = null;
    let slotDateString = moment(new Date(this.selectedSlotDate)).format("YYYY-MM-DD").toString();

    let requestBody = {
      "slot_booking_id": this.previousSlotBookingId,
      "slot_date": slotDateString
    }
    this.apiService.getAvailableTimeSlotsToCreateupdateSchedule(requestBody).subscribe((response) => {
      if (response.status == "success") {
        this.availableSlotTimes = response.data.timeslots
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    }
    )
  }

  getAvailableTrainers() {
    this.selectedTrainerId = null;
    let slotDateString = moment(new Date(this.selectedSlotDate)).format("YYYY-MM-DD").toString();
    let requestBody = {
      "time_slot": this.selectedSlotTimeAlias,
      "slot_date": slotDateString,
      "slot_booking_id": this.previousSlotBookingId,
    }
    this.apiService.getSchedulesAvailableTrainers(requestBody).subscribe((response) => {
      if (response.status == "success") {
        this.availableTrainers = response.data.available_trainers
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    }
    )
  }

  updateReSchedule() {
    let slotDateString = moment(new Date(this.selectedSlotDate)).format("YYYY-MM-DD").toString();
    let requestBody = {
      "time_slot": this.selectedSlotTimeAlias,
      "slot_date": slotDateString,
      "trainer_id": this.selectedTrainerId
    }
    let pathParams = {
      "reschedule_slot_booking_id": this.rescheduleSlotId
    }

    this.apiService.updateReschedule(requestBody, pathParams).subscribe((response) => {
      if (response.status == "success") {
        this.back()
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })

  }


  reset() {
    this.selectedSlotDate = null;
    this.selectedSlotTimeAlias = null;
    this.selectedTrainerId = null;
    this.availableTrainers = []
    this.availableSlotTimes = []
  }






}
