<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-4 col-md-2 col-lg-2 mt-8 d-flex w-auto">
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon class="mt-3" style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h1 class="h3 heading">{{FirstName}}</h1>
        </div>
        </div>
        <div class="row">
            <div class="col-sm-4 col-md-3 col-lg-2 mt-2 mr-2">
                <mat-form-field class="flex-auto gt-xs:pr-3 w-full">
                    <input matInput readonly [matDatepicker]="picker" [(ngModel)]="IDPlanSubType.filter_by_from_date" [min]="newDate"
                        [placeholder]="'From Date'" >
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-sm-4 col-md-3 col-lg-2 mt-2 mr-2">
                <mat-form-field class="flex-auto gt-xs:pr-3 w-full">
                    <input matInput readonly [matDatepicker]="picker1" [(ngModel)]="IDPlanSubType.filter_by_to_date" [min]="IDPlanSubType.filter_by_from_date"
                        [placeholder]="'To Date'" (dateChange)="onFilterPlanType()" >
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>

            </div>
            <div class="col-sm-4 col-md-3 col-lg-2 mt-2 mr-2">
                <mat-form-field class="flex-auto gt-xs:pr-3 w-full">
                    <mat-select [placeholder]="'Leave Status'" [(ngModel)]="IDPlanSubType.filter_by_leave_status" (selectionChange)="onFilterPlanType()">
                        <mat-option value="">All</mat-option>
                        <mat-option *ngFor="let Leave of LeaveStatus" [value]="Leave.value">{{Leave.viewValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-4 col-md-3 col-lg-2 mt-2 w-auto d-flex justify-content-end">
                <button class="addBtn mt-1.5" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
                    (click)="reset()">
                    <span class="ml-1 mr-1 txt" style="color: white">Reset</span>
                </button>
            </div>
    </div>
    <div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="Title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                <td mat-cell *matCellDef="let row"> {{row.title}} </td>
            </ng-container>
            
            <ng-container matColumnDef="NumOfDays">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Number Of Days</th>
                <td mat-cell *matCellDef="let row"> {{row.total_leaves_count}} </td>
            </ng-container>

            <ng-container matColumnDef="PaidLeaves">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Number Of Paid Days</th>
                <td mat-cell *matCellDef="let row"> {{row.paid_leaves_count}} </td>
            </ng-container>

            <ng-container matColumnDef="FromandToDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>From Date/To Date</th>
                <td mat-cell *matCellDef="let row"> 
                    {{row.from_date | dateFormat}} 
                    {{"To"}} 
                    {{row.to_date | dateFormat}} 
                </td>
            </ng-container>

            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let row"> {{row.status | titlecase}} </td>
            </ng-container>
            
            <ng-container matColumnDef="LastRespondedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Responded By</th>
                <td mat-cell *matCellDef="let row"> {{row.last_responded_by?.full_name}}<span *ngIf="row.last_responded_by?.role">({{row.last_responded_by.role.name}})</span> </td>
            </ng-container>
            <ng-container matColumnDef="LastRespondedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Responded At</th>
                <td mat-cell *matCellDef="let row"> {{row?.last_responded_at | dateTimeFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="Actions" *ngIf="isUserAllowedToApproveLeaves">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon style="color:#0038FF;font-size: 30px;"
                            svgIcon="heroicons_outline:dots-vertical"></mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="getLeaveStatusById(row)" *ngIf="isUserAllowedToViewLeaves">View</button>
                        <button mat-menu-item (click)="approveLeaveStatusById(row)" *ngIf="isUserAllowedToApproveLeaves">Approve/Reject</button>
                        <button mat-menu-item (click)="editLeaveById(row)" *ngIf="isUserAllowedToEditLeaves">Edit</button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <div>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="12">
                  <h3 style="display: flex;justify-content: center; margin:10%;">
                    No Records Found
                  </h3>
                  </td>
                </tr>
              </div>
        </table>

    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>