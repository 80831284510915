<div class="container-fluid">
    <div class="row w-full">
      <div class="row mt-8 justify-content-between">
        <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
          <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">Tickets&nbsp;List</h1>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4  mb-2 d-flex justify-content-end" *ngIf="isUserAllowedToCreateTickets">
            <button class="ml-4 addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
            (click)="createTicket()">
            <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            <span class="ml-2 mr-1 txt" style="color: white">Create Ticket</span>
        </button>
        </div>
        </div>
        <div class="row">
          
            <div class="col-sm-12 col-md-4 col-lg-2" >
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                  <mat-select [placeholder]="'All Tags'" [(ngModel)]="ITicket.tagId"
                    (selectionChange)="onFilterTickets()">
                    <mat-option value="">Select Tags</mat-option>
                    <mat-option *ngFor="let tag of tagsList" [value]="tag.id">{{tag.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-2" >
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                  <mat-select [placeholder]="'All Cutomers'" [(ngModel)]="ITicket.userId"
                    (selectionChange)="onFilterTickets()">
                    <mat-option value="">Select Customer</mat-option>
                    <mat-option *ngFor="let customer of customersList" [value]="customer.id">{{customer.full_name}}</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
              <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;" [subscriptSizing]="'dynamic'">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'" [placeholder]="'Search Ticket'">
              </mat-form-field>
              </div>
         
    </div>

    <div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="ticketid">
                <th mat-header-cell *matHeaderCellDef>Ticket Code</th>
                <td mat-cell *matCellDef="let row"> {{row.ticket_code}} </td>
            </ng-container>

            <ng-container matColumnDef="customer">
                <th mat-header-cell *matHeaderCellDef>Customer</th>
                <td mat-cell *matCellDef="let row"> {{row.user.full_name | titlecase}} </td>
            </ng-container>
            <ng-container matColumnDef="subject">
              <th mat-header-cell *matHeaderCellDef>Subject</th>
              <td mat-cell *matCellDef="let row"> {{row.subject | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="issuetype">
                <th mat-header-cell *matHeaderCellDef>Issue Type</th>
                <td mat-cell *matCellDef="let row"> {{row.tag.name}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef >Status</th>
                <td mat-cell *matCellDef="let row"> {{row.status}} </td>
            </ng-container>

          <ng-container matColumnDef="CreatedBy">
              <th mat-header-cell *matHeaderCellDef >Created By</th>
              <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                  {{row.created_by?.full_name}}<span *ngIf="row.created_by?.role">({{row.created_by.role.name}})</span>
              </td>
          </ng-container>
          <ng-container matColumnDef="CreatedDate">
              <th mat-header-cell *matHeaderCellDef >Created Date</th>
              <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.created_at  | dateFormat}}
              </td>
          </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                    <button mat-button [matMenuTriggerFor]="menu"><mat-icon style="color:#0038FF;font-size: 30px;"
                        svgIcon="heroicons_outline:dots-vertical"></mat-icon>
                      </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToUpdateTickets" (click)="viewTicket(row)">View</button>
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToResolveTickets" (click)="resolveTicket(row)">Resolve</button>
            </mat-menu>
             </td>
                  
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <div>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="12">
                <h3 style="display: flex;justify-content: center; margin:10%;">
                  No Records Found
                </h3>
                </td>
              </tr>
            </div>
        </table>
    </div>    
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>