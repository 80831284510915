import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileServiceService {

  private profilePicUrlSubject: BehaviorSubject<string> = new BehaviorSubject(localStorage.getItem("profilePicUrl"));
  public profilePicUrl: Observable<string>;

  private profileNameSubject: BehaviorSubject<string> = new BehaviorSubject(localStorage.getItem("profileName"));
  public profileName: Observable<string>;

  constructor() {
    this.profilePicUrl = this.profilePicUrlSubject.asObservable();
    this.profileName = this.profileNameSubject.asObservable();
  }

  public setProfilePicUrl(picUrl: string) {
    this.profilePicUrlSubject.next(picUrl);
  }

  public setProfileName(name: string) {
    this.profileNameSubject.next(name);
  }

}
