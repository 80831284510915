import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { FileServerService } from 'app/fileServer.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { environment } from "environments/environment";
import { ProfileServiceService } from 'app/shared/profile-service.service';
import { DISPLAY_PROFILE_PIC_URL } from 'app/meta.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  branchType = [
    { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }
  ]

  canEditProfile = false;
  userDetails = [];
  fullName = "";
  maxDate = new Date();

  baseProfilePicUrl = null;;
  profilePicUrl = null
  displayProfilePicUrl = DISPLAY_PROFILE_PIC_URL

  allowedProfilePicExtensions = "jpg,jpeg,png"

  public updateUserForm: FormGroup;

  constructor( private _formBuilder: FormBuilder,public apiService: ApiService, private toastr: ToastrService, private router: Router, public fileServerService: FileServerService, private profileService: ProfileServiceService){
    

  }
  ngOnInit() {
    this.buildForm()
    // this.cancelEdit()
    this.getUserDetails()

  }

  public buildForm() {
  
    this.updateUserForm = this._formBuilder.group({
      fullname: new FormControl({ value: null, disabled: true }, [Validators.required]),
      email: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[a-z.-]+\\.[a-z]{2,4}$")]),
      phoneNumber: new FormControl({ value: null, disabled: true }),
      gender: new FormControl({ value: null, disabled: true }),
      height: new FormControl({ value: null, disabled: true }),
      weight: new FormControl({ value: null, disabled: true }),
      designation: new FormControl({ value: null, disabled: true }),
      dob: new FormControl({ value: null, disabled: true }),
      doj: new FormControl({ value: null, disabled: true }),
    });
  }

  getUserDetails(){
    this.apiService.getUserProfile().subscribe((resp) => {
      if (resp.status == "success") {
        this.fullName = resp.data.user.full_name
        this.updateUserForm.controls.fullname.setValue(resp.data.user.full_name);
        this.updateUserForm.controls.email.setValue(resp.data.user.email);
        this.updateUserForm.controls.phoneNumber.setValue(resp.data.user.mobile_number);
        this.updateUserForm.controls.gender.setValue(resp.data.user.gender)
        this.updateUserForm.controls.designation.setValue(resp.data.user.designation)
        this.updateUserForm.controls.height.setValue(resp.data.user.height)
        this.updateUserForm.controls.weight.setValue(resp.data.user.weight)

        if (resp.data.user.profile_pic_url) {
          this.baseProfilePicUrl = resp.data.user.profile_pic_url
          if (this.baseProfilePicUrl) {
            this.profilePicUrl = environment.apiUrl + this.baseProfilePicUrl
            this.displayProfilePicUrl = this.profilePicUrl
          }
        }

        if (resp.data.user.dob) {
          this.updateUserForm.controls.dob.setValue(moment(new Date(resp.data.user.dob)).format("YYYY-MM-DD").toString())
        }
        if (resp.data.user.doj) {
          this.updateUserForm.controls.doj.setValue(moment(new Date(resp.data.user.doj)).format("YYYY-MM-DD").toString())
        }
      }
    }, (err) => {
        this.toastr.error(err.error.message);
        this.router.navigateByUrl('Admin/home');
    });
  }

  allowEdit() {
    this.canEditProfile = true;
    this.updateUserForm.get('fullname')?.enable();
    this.updateUserForm.get('email')?.enable();
    this.updateUserForm.get('phoneNumber')?.enable();
    this.updateUserForm.get('gender')?.enable();
    this.updateUserForm.get('designation')?.enable();
    this.updateUserForm.get('height')?.enable();
    this.updateUserForm.get('weight')?.enable();
    this.updateUserForm.get('dob')?.enable();
    this.updateUserForm.get('doj')?.enable();
  }

  cancelEdit() {
    this.getUserDetails()
    this.disableAllFields()
  }
  disableAllFields() {
    this.canEditProfile = false;
    this.updateUserForm.get('fullname')?.disable();
    this.updateUserForm.get('email')?.disable();
    this.updateUserForm.get('phoneNumber')?.disable();
    this.updateUserForm.get('gender')?.disable();
    this.updateUserForm.get('designation')?.disable();
    this.updateUserForm.get('height')?.disable();
    this.updateUserForm.get('weight')?.disable();
    this.updateUserForm.get('dob')?.disable();
    this.updateUserForm.get('doj')?.disable();
  }

  saveProfile() {
    if (this.updateUserForm.controls.dob.value) {
      this.updateUserForm.controls.dob.setValue(moment(new Date(this.updateUserForm.controls.dob.value)).format("YYYY-MM-DD").toString())
    }
    if (this.updateUserForm.controls.doj.value) {
      this.updateUserForm.controls.doj.setValue(moment(new Date(this.updateUserForm.controls.doj.value)).format("YYYY-MM-DD").toString())
    }
    let reqBody = {
      "user": {
        "gender": this.updateUserForm.controls.gender.value,
        "dob": this.updateUserForm.controls.dob.value,
        "avatar": null,
        "height": this.updateUserForm.controls.height.value,
        "weight": this.updateUserForm.controls.weight.value,
        "doj": this.updateUserForm.controls.doj.value,
        "designation": this.updateUserForm.controls.designation.value,
        "full_name": this.updateUserForm.controls.fullname.value,
        "profile_pic_url": this.baseProfilePicUrl
      }
    }

    this.apiService.updateUserProfile(reqBody).subscribe((resp) => {
      if (resp.status = "success") {
        this.toastr.success(resp.message);
        this.disableAllFields()
        localStorage.setItem("profileName", this.updateUserForm.controls.fullname.value)
        this.profileService.setProfileName(this.updateUserForm.controls.fullname.value)
      }
    }, (err) => {
      this.toastr.error(err.error.message);
    });
    
  }

  private isValidExtension(file) {
		const allExtensionsArray = (this.allowedProfilePicExtensions.split(','))
                          .map( (x) => { return x.toLocaleUpperCase().trim() });
		var extension = file.name.toUpperCase().split('.').pop() || file.name;
		const exists = allExtensionsArray.includes(extension)
		if(!exists) {
		 this.toastr.error("Invalid Extension: " + file.name + "Only " + this.allowedProfilePicExtensions + " are allowed")
		 return false
		}
		return true
	}
    
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {

      if (this.isValidExtension(event.target.files[0])) {
        const formData = new FormData();
        formData.append('uploadable_files', event.target.files[0], event.target.files[0].name);
        formData.append('path_to_store_files', "users/profiles");
        
        this.fileServerService.uploadFiles(formData).subscribe((resp) => {
          this.baseProfilePicUrl = resp.file_urls[0]
          this.profilePicUrl = environment.apiUrl + this.baseProfilePicUrl
          this.displayProfilePicUrl = this.profilePicUrl

          localStorage.setItem("profilePicUrl", this.profilePicUrl)

          this.profileService.setProfilePicUrl(this.profilePicUrl)
        
        this.saveProfile()
      },
        (err) => {
        this.toastr.error("Failed to upload image");
        });
      }
    }
  }
}
