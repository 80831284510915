
import { DatePipe, formatDate } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { AuthService } from 'app/core/auth/auth.service';
import { MetaService } from 'app/meta.service';
import { Dictionary } from 'lodash';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';

@Component(
    {
        selector: 'app-edit-ticket',
        templateUrl: './edit-ticket.component.html',
        styleUrls: ['./edit-ticket.component.scss']
    }
)
export class EditTicketComponent {
    
    handleStartingSpaceTextInput = handleStartingSpaceTextInput

    description = null;
    ticketData;
    shouldShowApprovalScreen = false;
    Status;
    tagId = null;
    tagsList = [];

    constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router, private meta: MetaService, public dialog: MatDialog, private route: ActivatedRoute, public apiService: ApiService, public _authService: AuthService, private datePipe: DatePipe, private _formBuilder: FormBuilder, private toastr: ToastrService,
    private dialogRef: MatDialogRef <EditTicketComponent>) { }

    ngOnInit() {
        this.getTicketById();
        if (this.shouldShowApprovalScreen) {
            this.getTags();
        }
        else {
            this.getTags();
        }
    }
    getTicketById() {
        this.ticketData = this.data.ticketData
        this.shouldShowApprovalScreen = this.data.shouldShowApprovalScreen

        this.tagId = this.ticketData.tag.id
        this.Status = this.ticketData.status
        this.description = this.ticketData.response
    }

    getTags() {
        this.apiService.getTags().subscribe((resp) => {
            if (resp.status == "success") {
                this.tagsList = resp.data.tags
            }
            
        })
    }

    resolveStatusChnage() {
        this.description = null
    }

    updateTicket() {
        let requestData = {
            status: this.Status,
            response: this.description,
            tag_id: this.tagId
        }
        this.apiService.updateTicket(requestData, this.ticketData.id).subscribe(
            (resp) => {
                if (resp.status == 'success') {
                    this.dialogRef.close();
                        this.router.navigateByUrl('Admin/tickets');
                    }
                (err) => {
                    this.toastr.error(err.error.message);
                }
            }
        );
    }
}