import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component( {
    selector: 'app-dashboard',
    templateUrl: './pts-component.html',
    styleUrls: ['./pts-component.scss']
})
export class PersonalTrainingComponent {

    fromDate = null;
    toDate = null;
    selectedBranchIds = [];
    userIdNameList = [];
    branchIdNameList = [];
    selectedUserIds = [];
    data: any;
    constructor(public apiService: ApiService, public toastr: ToastrService, private router: Router) {
      }
    ngOnInit() {
        this.getBranches();
        this.getUsers();
    }
    getBranches() {
        let data = {
          "filter_by_branch_active_status": true
        }
        this.apiService.getBranchByFilter(data).subscribe((resp) => {
          if (resp.status == "success") {
            this.branchIdNameList = resp.data.branches.map(branch => {
              return {
                "id": branch.id, "name": branch.name
              }
            })
          }
        },
          (err) => {
            this.toastr.error(err.error.message)
          })
      }
    
    getUsers() {
        this.resetCheckedUsers()
        let data = {
            "filter_by_branch_ids": this.selectedBranchIds
        }
        this.apiService.getUserByFilterReport(data).subscribe((resp) => {
            if (resp.status == "success") {
            this.userIdNameList = resp.data.users.map(user => {
                return {
                "id": user.id, "full_name": user.full_name
                }
            })
            }
        })
    }
     // SelecteAll checkbox for Users
    allBranchesChecked = false
    someBranchesChecked() {
        if (this.selectedBranchIds.length == this.branchIdNameList.length) {
        this.allBranchesChecked = true
        } else {
        this.allBranchesChecked = false
        }
        return this.selectedBranchIds.length > 0 && !this.allBranchesChecked;
    }
    setAllBranchesChecked(change) {
        if (change.checked) {
        this.allBranchesChecked = true;
        this.selectedBranchIds = this.branchIdNameList.map(e => e.id);
        } else {
        this.allBranchesChecked = false;
        this.selectedBranchIds = [];
        }
        this.resetCheckedBranches()
        this.getBranches()
    }
    // SelecteAll checkbox for Users
    allUsersChecked = false
    someUsersChecked() {
        if (this.selectedUserIds.length == this.userIdNameList.length) {
        this.allUsersChecked = true
        } else {
        this.allUsersChecked = false
        }
        return this.selectedUserIds.length > 0 && !this.allUsersChecked;
    }
    setAllUsersChecked(change) {
        if (change.checked) {
        this.allUsersChecked = true;
        this.selectedUserIds = this.userIdNameList.map(e => e.id);
        } else {
        this.allUsersChecked = false;
        this.selectedUserIds = [];
        }
    }
    getPersonalTrainingSessions() {
        let requestBody = {
            from_date: moment(new Date(this.fromDate)).format("YYYY-MM-DD").toString(),
            to_date: moment(new Date(this.toDate)).format("YYYY-MM-DD").toString(),
            filter_by_branch_ids: this.selectedBranchIds,
            filter_by_user_ids: this.selectedUserIds
        }
        this.apiService.getPersonalTrainingSessions(requestBody).subscribe((resp)=>{
            if (resp.status == "success") {
              this.data = resp.data.pt_sessions;
            }
          },
            (err) => {
              this.toastr.error(err.error.message)
            })
    }
    // Resetting details
    resetCheckedUsers() {
        this.allUsersChecked = false;
        this.selectedUserIds = []
    }
    resetCheckedBranches() {
        this.allBranchesChecked = false;
        this.selectedBranchIds = []
    }

    resetAll() {
        this.fromDate = null;
        this.toDate = null;
        this.selectedBranchIds=[];
        this.selectedUserIds=[];
    }
}