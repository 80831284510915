import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ExcelDownloadService } from 'app/excel-download.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MetaService } from 'app/meta.service';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'app/app.component';
@Component({
  selector: 'app-excel-report',
  templateUrl: './excel-report.component.html',
  styleUrls: ['./excel-report.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ExcelReportComponent {
  branchesList = [];
  checked_branches_list = [];
  checked_roles_list = [];
  roleList = [];
  checked_users_list = [];
  userList = [];
  fromDate: string;
  toDate: string;
  today: any;
  firstDay: any;
  basicDetailsForm: FormGroup;
  dateDetailsForm: FormGroup;
  maxDate = new Date();
  minDate: any;
  MyJoiningDateString: any
  constructor(public apiService: ApiService, private toastr: ToastrService, private excelDownloadService: ExcelDownloadService, public _formBuilder: FormBuilder, public meta: MetaService, private datePipe: DatePipe) { }
  ngOnInit() {
    this.buildForm();
    this.getRoles();
    this.getBranches();
    this.getUsers();

    // this.today = new Date();
    // this.today.setDate(this.today.getDate())
    // var firstDay = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
    // this.firstDay = moment(new Date(firstDay)).format("YYYY-MM-DD").toString();
    // this.today = moment(new Date(this.today)).format("YYYY-MM-DD").toString();
    // this.fromDate = this.firstDay;
    // this.toDate=this.today
  }
  getRoles() {
    let data = { "filter_by_role_active_status": true }
    this.apiService.getRolesByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.roleList = resp.data.roles;
        // this.dataSource.data = resp.data
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  getBranches() {
    let data = { "filter_by_branch_active_status": true }
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branchesList = resp.data.branches
        // this.toastr.success("Branches Loaded successfully");
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  getUsers() {
    this.resetCheckedUsers()
    let data = {
      "filter_by_branch_ids": this.checked_branches_list,
      "filter_by_role_ids": this.checked_roles_list
    }
    this.apiService.getUserByFilterReport(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.userList = resp.data.users
      }
    })
  }
  addEvent(event) {
    if (this.dateDetailsForm.controls['fromDate'].value) {
      this.minDate = this.dateDetailsForm.controls['fromDate'].value
    }

    // // this.toDate = moment(new Date(this.dateDetailsForm.controls.fromDate.value)).format("YYYY-MM-DD").toString();
    // this.dateDetailsForm.controls.fromDate.setValue(this.toDate)
    // this.MyDateString = moment(new Date(this.dateDetailsForm.controls.fromDate.value)).format("YYYY-MM-DD").toString();
    //   // var date1=this.MyDateString.toUTCString();  
    //   // console.log(newDate)
    //   this.toDate = moment(new Date(this.dateDetailsForm.controls.fromDate.value)).format("YYYY-MM-DD").toString();
    //   this.MyJoiningDateString = moment(new Date(this.basicDetailsForm.controls.dateOfJoining.value)).format("YYYY-MM-DD").toString();
    //   // this.dateDetailsForm.controls.fromDate.setValue(MyDateString)
  }
  public buildForm() {
    this.dateDetailsForm = this._formBuilder.group({
      fromDate: new FormControl('', [Validators.required]),
      toDate: new FormControl('', [Validators.required]),
    })
    this.basicDetailsForm = this._formBuilder.group({
      roleId: new FormControl(''),
      branchId: new FormControl(''),
      userId: new FormControl(''),
    });

  }
  downloadReport() {
    // console.log(this.checked_branches_list, this.checked_users_list, this.checked_roles_list)
    let filter_by_role_ids = this.checked_roles_list.filter(elem => elem !== '');
    let filter_by_branch_ids = this.checked_branches_list.filter(elem => elem !== '');
    let filter_by_user_ids = this.checked_users_list.filter(elem => elem !== '');
    let payload = {
      from_date: moment(new Date(this.dateDetailsForm.controls.fromDate.value)).format("YYYY-MM-DD").toString(),
      to_date: moment(new Date(this.dateDetailsForm.controls.toDate.value)).format("YYYY-MM-DD").toString(),
      filter_by_role_ids: filter_by_role_ids,
      filter_by_branch_ids: filter_by_branch_ids ,
      filter_by_user_ids: filter_by_user_ids,
    }
    // console.log(payload)
    this.apiService.getLogsReport(payload).subscribe((resp) => {
      let sampleData = resp.data.attendance_report_logs
      sampleData.forEach(element => {
        element.attendance_date = this.formateDate(element.attendance_date)
      });
      // console.log(sampleData)
      let fileName = "UserAttendanceReport_" + payload["from_date"] + "_" + payload["to_date"] + '.' + 'xlsx'
      if (resp.status == "success") {
        let headerNames = ['User', 'User Active Status', 'Branch', 'Role' ,'Attendance Date','Day Type','No of Check-ins','No of Check-outs','Total Active Time (In Minutes)', 'Arrival Status'];
        this.excelDownloadService.downloadExcelFile(sampleData, headerNames, fileName);
        this.meta.dialogClosed = false;
      }
    })
    this.resetDetails()
  }
  formateDate(date) {
    const formattedDate = this.datePipe.transform(date, 'dd-MM-yyyy');
    // console.log(formattedDate)
    return formattedDate
  }
  resetDetails(){
    this.checked_branches_list=[];
    this.checked_roles_list=[];
    this.checked_users_list=[];
  }



  // All Checkboxes implementation
  allBranchesChecked = false
  someBranchesChecked() {
    if (this.checked_branches_list.length == this.branchesList.length) {
      this.allBranchesChecked = true
    } else {
      this.allBranchesChecked = false
    }
    return this.checked_branches_list.length > 0 && !this.allBranchesChecked;
  }
  setAllBranchesChecked(change) {
    if (change.checked) {
      this.allBranchesChecked = true;
      this.checked_branches_list = this.branchesList.map(e => e.id);
    } else {
      this.allBranchesChecked = false;
      this.checked_branches_list = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  allRolesChecked = false
  someRolesChecked() {
    if (this.checked_roles_list.length == this.roleList.length) {
      this.allRolesChecked = true
    } else {
      this.allRolesChecked = false
    }
    return this.checked_roles_list.length > 0 && !this.allRolesChecked;
  }
  setAllRolesChecked(change) {
    if (change.checked) {
      this.allRolesChecked = true;
      this.checked_roles_list = this.roleList.map(e => e.id);
    } else {
      this.allRolesChecked = false;
      this.checked_roles_list = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  allUsersChecked = false
  someUsersChecked() {
    if (this.checked_users_list.length == this.userList.length) {
      this.allUsersChecked = true
    } else {
      this.allUsersChecked = false
    }
    return this.checked_users_list.length > 0 && !this.allUsersChecked;
  }
  setAllUsersChecked(change) {
    if (change.checked) {
      this.allUsersChecked = true;
      this.checked_users_list = this.userList.map(e => e.id);
    } else {
      this.allUsersChecked = false;
      this.checked_users_list = [];
    }
  }
  resetCheckedUsers() {
    this.allUsersChecked = false;
    this.checked_users_list = []
  }
}
