import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'app/api.service';
import { MetaService } from 'app/meta.service';

@Component({
  selector: 'app-create-level',
  templateUrl: './create-level.component.html',
  styleUrls: ['./create-level.component.scss']
})
export class CreateLevelComponent {
  levelName:String;
  isUpdate:boolean=false
  constructor(public apiService: ApiService,public meta: MetaService,private dialogRef: MatDialogRef<CreateLevelComponent>,@Inject(MAT_DIALOG_DATA) public data: any,){}
  ngOnInit(){
    if(this.data){
      this.levelName = this.data.data.name;
      this.isUpdate =true;
    }
  }
  createLevel(){
    let payload={
        name: this.levelName
      }
    this.apiService.createLevel(payload).subscribe((resp)=>{
      if(resp.status== "success" ){
        this.dialogRef.close();
      }
     })
  }
 updateLevel(){
  let payload={
    name: this.levelName
  }
this.apiService.updateLevel(payload,this.data.id).subscribe((resp)=>{
  if(resp.status== "success" ){
    this.dialogRef.close();
  }
 })
 }
}
