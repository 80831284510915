import { DatePipe } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'app/api.service';
import { FileUploadManagerComponent } from 'app/pages/commonComponents/file-upload-manager/file-upload-manager.component';
import { environment } from 'environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-view-branch',
  templateUrl: './view-branch.component.html',
  styleUrls: ['./view-branch.component.scss']
})
export class ViewBranchComponent {

  @ViewChild('fileUploadComponent', { static: false }) fileUploadComponent: FileUploadManagerComponent;

  existingFileDataForFileManager = []
  actualexistingFilesCountForFileManager = 0


branchDetails= []
newDate: any;
myDate = new Date();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public apiservice: ApiService,private datePipe: DatePipe,) { }
  timeSlots = []
  constDaysList = [
    { value: 'SUN', viewValue: 'Sunday' },
    { value: 'MON', viewValue: 'Monday' },
    { value: 'TUE', viewValue: 'Tuesday' },
    { value: 'WED', viewValue: 'Wednesday' },
    { value: 'THU', viewValue: 'Thursday' },
    { value: 'FRI', viewValue: 'Friday' },
    { value: 'SAT', viewValue: 'Saturday' },
  ]
ngOnInit(){
  this.branchDetails = this.data.branchDetails;
  this.branchDetails[0].createdAt = this.branchDetails[0].created_at;
  this.branchDetails[0].createdBy = this.branchDetails[0].created_by.full_name;
  this.branchDetails[0].createdByRole = this.branchDetails[0].created_by.role.name;

  this.timeSlots=this.data.timeSlots;
  this.timeSlots.forEach(element => {
    if (element.start_time != null) {
      element.start_time = this.convertToIst(element.start_time);
      // element.start_time = convertTime(element.start_time);
    }
    if (element.end_time != null) {
      element.end_time = this.convertToIst(element.end_time);
      // element.end_time = convertTime(element.end_time);
    }
  });
  this.sortTimeSlots()
  

  const convertTime = timeStr => {
    let zone = "AM"
   if(timeStr != null){
    timeStr = moment(timeStr).format("HH:mm:ss");
    let [hours, minutes,seconds] = timeStr.split(':');
    if (hours >= '12') {
      zone = "PM"
       if (hours > '12') {
         hours = parseInt(hours, 10) - 12;
       }else{
        hours = parseInt(hours, 10);
      }
     }
     if (hours < '10' && typeof (hours) == 'number') {
        hours = '0' + hours
     }
     if (hours == '00') {
       hours = '12'
     }
 
     return `${hours}:${minutes} ${zone}`;
   } else {
     return null;
   }
  }

  this.existingFileDataForFileManager = this.branchDetails[0].file_name_urls.map((ele) => {
    return {
      fileName: ele.filename,
      fileBaseUrl: ele.file_url,
      fileUrl: environment.apiUrl + ele.file_url
    }
  })
  this.actualexistingFilesCountForFileManager = this.actualexistingFilesCountForFileManager = this.existingFileDataForFileManager.length

}
sortTimeSlots() {
  let daysListInOrder = []
  this.constDaysList.forEach(elem => {
    if (!daysListInOrder.includes(elem.value)) {
      daysListInOrder.push(elem.value)
    }
  })
  this.timeSlots = this.timeSlots.sort(
    (a, b) => (daysListInOrder.indexOf(a.day_of_week) < daysListInOrder.indexOf(b.day_of_week)) ? -1 : (daysListInOrder.indexOf(a.day_of_week) > daysListInOrder.indexOf(b.day_of_week)) ? 1 : 0
  ).sort(
    (a, b) => {
      if (((daysListInOrder.indexOf(a.day_of_week) < daysListInOrder.indexOf(b.day_of_week)) ? -1 : (daysListInOrder.indexOf(a.day_of_week) > daysListInOrder.indexOf(b.day_of_week)) ? 1 : 0) == 0 && a.day_type=='WORK_DAY') {
        var nowDate = new Date().toISOString().split('T')[0];
        let aZone = a.start_time.slice(-3,)
        let bZone = b.start_time.slice(-3,)
        
        var astartTime = new Date(nowDate + ' ' + a.start_time.slice(0,-2));
        var bstartTime = new Date(nowDate + ' ' + b.start_time.slice(0,-2));
        // console.log(astartTime, bstartTime)

        if (aZone == bZone) {
          return (astartTime < bstartTime) ? -1 : (astartTime > bstartTime) ? 1 : 0
        } else if (aZone == 'PM' && bZone == 'AM') {
          return 1
        } else if (aZone == 'AM' && bZone == 'PM') {
          return -1
        }
      }
    }
  )
}
convertToIst(date: any) {
  const timeString = date;
const time = new Date(`2000-01-01T${timeString}`);
const formattedTime = time.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
return formattedTime;
  // if(date != null){
  //   this.newDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
  //   date = new Date(this.newDate + "T" + date + "Z");
  //   var endTime = moment(new Date(date)).format("hh:mm A").toString();
  //   return endTime
  // }else{
  //   return null
  // }
 
}

}
