<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-lg-8 mt-8 d-flex flex-column flex-sm-row">
            <h1 class="h3 ml-4 mr-6 heading">UPLOAD FAQS</h1>
        </div>
    </div>
    <div class="row justify-content-center" *ngIf="isUserAllowedToUploadFAQs">
        <div class="col-sm-10 col-lg-6 mt-8">
            <div class="container" appDnd (fileDropped)="onFileDropped($event)">
                <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" />
                <h3>Drag and drop file here</h3>
                <h4>or</h4>
                <label for="fileDropRef">Browse File</label>
                <h6 style="color: grey">Maximum file size is 5 MB</h6>
            </div>
            <div class="single-file" *ngIf="file">
                <div class="info">
                    <h4 class="name">
                        {{ file?.name }}
                    </h4>
                    <app-progress [progress]="file?.progress"></app-progress>
                </div>

                <div class="delete" (click)="deleteFile()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                        <path fill="#B1B1B1" fill-rule="nonzero"
                            d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
  <div class="bottomBtns mb-3 row " *ngIf="isUserAllowedToUploadFAQs">
    <button class="ml-4 mr-6 addBtn"  mat-flat-button [color]="'primary'"
              (click)="onUpload()"  [disabled]="!file" [ngClass]="{'btn-clr': file}">
                <span class="ml-2 mr-1 txt" style="color: white">Upload </span>
              </button>
    </div>
</div>