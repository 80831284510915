<!-- <h1  mat-dialog-title>View User</h1> -->
<div class="bgCard">

    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>VIEW ADD ON TYPE</h1>
        <button align="end" mat-icon-button mat-dialog-close>
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>
        </button>
    </div>
    <div>
        <mat-dialog-content class="mat-typography">
            <div class="card dataCard" *ngFor="let val of AddOnplans">
                <div class="mt-2 ml-3 ">
                    <h1 class="headings">
                        Details
                    </h1>
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Add-On Type Name:
                            </div>
                            <div class="fw-bold">
                                {{val.name}}
                            </div>
                           
                        </div>

                    </div>
                </div>


            </div>
            <div class="card dataCard mt-4" style="height: auto !important;">
                <div class="mt-2 ml-3 ">
                    <h4 class="headings">
                        Plan Benefits
                    </h4>
                    <div class="col-sm-12 col-md-6 d-flex flex-column align-items-center m-auto"
                        style="max-height:450px; overflow-y:scroll;">
                        <table class="table" style="width: 90%">
                            <thead class="thead-dark"
                                style="background: #000; color: #fff;  position: sticky; top: 0; z-index: 1;">
                                <tr>
                                    <th scope="col">Benefit List</th>
                                    <!-- <th scope="col"></th> -->

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let val of planBenefits">
                                    <td>{{ val }}</td>
                                  </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </mat-dialog-content>
    </div>


</div>

<!-- <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
    </mat-dialog-actions> -->