import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
export interface Element {
  BranchName: string;
  Location: string;
  Number: string;
  viewUser: number;
  IsRemoved:boolean;
  CreatedBy:string;
}
const ELEMENT_DATA: Element[] = [
  {BranchName: 'Jubilee Hills Branch', Location: 'Hyderabad', IsRemoved: false,Number:'+91-9542999999',viewUser:120, CreatedBy: 'ADMIN'},
  {BranchName: 'Gachibowli Branch', Location: 'Hyderabad', IsRemoved: false,Number:'+91-9542999999',viewUser:90, CreatedBy: 'ADMIN'},
 
];
@Component({
  selector: 'app-assign-device',
  templateUrl: './assign-device.component.html',
  styleUrls: ['./assign-device.component.scss']
})
export class AssignDeviceComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['deviceId', 'deviceName', 'deviceMac','Actions'];
  dataSource = new MatTableDataSource<Element>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  constructor( private router: Router,public dialog: MatDialog) {
    
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
getBranches(){
}

createRole(){
  this.router.navigateByUrl('Admin/create-role');
}
editUser(){
  this.router.navigateByUrl('Admin/edit-role');

}
viewUser() {
 
}
}
