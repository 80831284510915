import { Component, HostListener, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator ,PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ViewRoleComponent } from '../view-role/view-role.component';
import { ApiService } from 'app/api.service';
import { IroleActions, MetaService, featureConstants } from 'app/meta.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { BreakpointState } from '@angular/cdk/layout';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import {VerifyDeactivateComponent} from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';

export interface Element {
  Name: string;
  Description: string;
  IsRemoved: boolean;
  CreatedBy: any;
}
export interface Irole {
  id: String;
  is_removed: boolean;
  name: string;
  AvailabilityTimings: boolean;
  AddAddress: boolean;
  AddBranch: boolean;

}

/** Constants used to fill up our data base. */

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss']
})
export class RolesListComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['name', 'roleType', 'createdBy', 'createdAt', 'Actions'];
  dataSource = new MatTableDataSource<Irole>();
  roleList: Irole[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  roleTypes = [
    { value: 'ALL_ROLES', viewValue: 'All Roles' },
    { value: 'DEFAULT_ROLES', viewValue: 'Default Roles' },
    { value: 'CUSTOM_ROLES', viewValue: 'Custom Roles' },

  ]
  roleStatuses = [
    { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' },
   

  ]
  Irole: IroleActions = {
    roleAction: "",
    roleType: "ALL_ROLES",
    roleStatus: "",
  }
  selectedfeaturesList=[];
  scrHeight: number;
  scrWidth: number;
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10;
  filterValue=null;
  bOffset:number= 0;
  bLimit :number=10;
  breakpointObserve: any;
  filterByFeature = ''
  userAllFeatures = []
  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToRoleCreate = this.userAllowedFeatures.indexOf(featureConstants.CREATE_ROLES) > -1
  isUserAllowedToRoleEdit = this.userAllowedFeatures.indexOf(featureConstants.EDIT_ROLES) > -1
  isUserAllowedToRoleDeactivate = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_ROLES) > -1
  isUserAllowedToRoleReactivate = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_ROLES) > -1;
  userTimeZone: string;
  userRegion: string;
  constructor(private router: Router, public dialog: MatDialog,public meta: MetaService, public apiService: ApiService,private datePipe : DatePipe,private toastr:ToastrService) { 
    this.getScreenSize();
  }
  @HostListener('window:resize', ['$event'])
  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationData');
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.filterValue=paginationData.filterValue;
      this.filterByFeature=paginationData.filter_by_features;
      this.Irole.roleType=paginationData.filterByRole;
      this.Irole.roleStatus=paginationData.filterByStatus;
    }
    this.filterRole();
    this.getUserAllFeatures();
    this.getUserRegion();
  }
  
 

  getUserRegion(): void {
    this.userRegion = Intl.DateTimeFormat().resolvedOptions().timeZone.split('/')[0];
    // console.log(this.userRegion)
  }
  getAd() {
    throw new Error('Method not implemented.');
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // this.getAd(); // This is get ad on every time screen is resized
  }
  onResize(event: any) {
    this.scrWidth = event.target.innerWidth; // window width    
  }
  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    
    this.onFilterRoleForSearch()
   
  }

  getUserAllFeatures() {
    this.apiService.getAllUserFeatures().subscribe((resp) => {
      this.userAllFeatures = resp.data.features;
    })
  }

  // getRolesByFilter() {
  //   const data = {
  //     role_filter: "DEFAULT_ROLES",
  //     status: true,
  //     startRecords: 0,
  //     to_value: 10
  //   }
  //   this.apiService.getRolesByFilter(data).subscribe((resp) => {
  //     if (resp.status == "success") {
  //       this.roleList = resp.data;
  //       // this.dataSource = new MatTableDataSource<Irole>(this.roleList)
  //       this.dataSource.data = this.roleList
  //     }
  //   })
  // }


  // Get all Roles 
  // getRoles() {
  //   this.apiService.getRoles().subscribe((resp) => {
  //     if (resp.status == "success") {
  //       this.dataSource.data = resp.data.roles;
  //       this.dataSource.sort = this.sort;
  //     }
   
  // },
  // (err) => {
  //   this.toastr.error(err.error.message)
  // })
  // }

  // Filtering the data based on the Role Type
  filterRole() {
    const data = {
      filter_by_role_group_type: this.Irole.roleType || null,
      filter_by_role_active_status: this.Irole.roleStatus || null,
      limit: this.bLimit,
      offset: this.bOffset,
      filter_by_features: this.filterByFeature || null,
      search_term: this.filterValue || null
    }
    this.apiService.getRolesByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data = resp.data.roles;
        this.dataSource.sort = this.sort;
        // this.toastr.success(resp.message)
        this.totalCount = resp.data.total_roles_count;
      }
    },
    (err) => {
      // this.alert = {
      //     type: 'error',
      //     message: err.error.message
      // };
      this.toastr.error(err.error.message)
      // Show the alert
      // this.showAlert = true;
  })
  }


  verifyDeactivate(role_id:string){
    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
        "message": "The role will be deactivated and people with that role cannot access the application."
      }, // Pass data to the dialog
      width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {

      if (this.meta.dialogClosed) {
        this.deactivateRole(role_id=role_id)
      }
    });
  }


  deactivateRole(role_id: String) {
    this.apiService.deactivateRole(role_id = role_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          role => {
            if (role.id == role_id)
              role.is_removed = true 
          }
        )
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    }
    )
  }

  reactivateRole(role_id: String) {
    this.apiService.reactivateRole(role_id = role_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          role => {
            if (role.id == role_id)
              role.is_removed = false 
          }
        )
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    }
    )
  }
  // Filtering the data based on the Role Status
  // selectRoleType(value) {
  //   this.Irole.roleStatus = value;
  //   const data = {
  //     role_filter: this.Irole.roleType,
  //     status: this.Irole.roleStatus,
  //     startRecords: this.startrecords,
  //     to_value: this.to_value
  //   }
  //   this.apiService.getRolesByFilter(data).subscribe((resp) => {
  //     if (resp.status == "success") {
  //       this.dataSource.data = resp.data
  //     }
  //   })
  // }
  // Creating New Role
  createRole() {
    sessionStorage.removeItem('editRoleId')
    this.router.navigateByUrl('Admin/create-role');
  }
  // Edit Role
  editRole(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue,
      filterByFeature: this.filterByFeature,
      filterByRole:this.Irole.roleType,
      filterByStatus:this.Irole.roleStatus
    };
    sessionStorage.setItem('paginationData', JSON.stringify(paginationData));
    sessionStorage.setItem('editRoleId', row)
    this.router.navigateByUrl('Admin/edit-role');
  }
  GetRoleDetailsById(row) {
    this.selectedfeaturesList=[]
    // let role_id=this.data.id
    this.apiService.viewRoleById(row.id).subscribe((resp) => {
      if(resp.status == "success"){
        this.selectedfeaturesList = resp.data
        this.viewRole(this.selectedfeaturesList)
      }
    }, (err) => {
      // this.alert = {
      //     type: 'error',
      //     message: err.error.message
      // };
      this.toastr.error(err.error.message)
      // Show the alert
      // this.showAlert = true;
  })
  }
  // View Role with popup
  viewWidth:any
  viewRole(data) {
    if(this.scrWidth < 1450){
      this.viewWidth='80%'
    }else{
      this.viewWidth='50%'
    }
    let dialogRef = this.dialog.open(ViewRoleComponent, {
      data: {
        data: data,
      },

      // height: '90%',
      width: this.viewWidth
    });
  }

  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
    
    this.filterRole(); 
  }

  onFilterRoleForSearch(){
    this.pageIndex=0;
    this.bOffset=0;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue,
      filterByFeature: this.filterByFeature,
      filterByRole:this.Irole.roleType,
      filterByStatus:this.Irole.roleStatus
    };
    sessionStorage.setItem('paginationData', JSON.stringify(paginationData));
    this.filterRole();
    
    
  }

  onFilterRole(){
    this.pageIndex=0;
    this.bOffset=0;
    this.filterValue=null;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue,
      filterByFeature: this.filterByFeature,
      filterByRole:this.Irole.roleType,
      filterByStatus:this.Irole.roleStatus
    };
    sessionStorage.setItem('paginationData', JSON.stringify(paginationData));
    this.filterRole();
  }

}
