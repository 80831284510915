import { Component, ViewChild } from '@angular/core';
import { MetaService } from 'app/meta.service';

@Component({
  selector: 'app-otp-conformation',
  templateUrl: './otp-conformation.component.html',
  styleUrls: ['./otp-conformation.component.scss']
})
export class OtpConformationComponent {
  disableAdd:boolean;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any; config = {
    allowNumbersOnly: true,

    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    // placeholder:'0',
    containerStyles: {
        "display": 'flex',
        "justify-content": 'space-around',
    },
    inputStyles: {
        'background': '#ffffff',
        'border': '2px solid #DCDCDC',
        'border-radius': '10px',
    }
};
  constructor(public meta: MetaService) {
    this.meta.dialogClosed = false;
  }
  
  onOtpChange(otp) {
    this.meta.otpValue = otp;
    if(this.meta.otpValue?.length == 6){
      this.disableAdd = true
    } else {
      this.disableAdd = false
    }
  }
  onNoClick(){
    this.meta.dialogClosed = false;
  }
  onOkClick() {
    this.meta.dialogClosed = true;
  }
}
