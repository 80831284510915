
<div class="container-fluid">
    
  
            
    
     <div class="dilogStl"> 
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style="height: 100px;width: 100px;color:red;" class="caution-icon">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
          </svg>         
        <h1 style="
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;" mat-dialog-title>Are you sure to Deactivate?</h1>
    </div>
    <div mat-dialog-content>
        <p class="warning-message">NOTE:&nbsp;</p>
        <p style="color: grey;font-weight: 600;text-align: center;">{{ receivedData.message }}</p>
    </div>
    <div mat-dialog-actions style="justify-content: end;">
      <button mat-button mat-dialog-close (click)="onNoClick()">Cancel</button>
      <button mat-button mat-dialog-close  (click)="onOkClick()">Confirm</button>
    </div>
</div>