<div class="container-fluid">

  
    <div>
        <div class="d-flex m-3">
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{title}}</h2>
        </div>

        <div class="card" [formGroup]="basicDetailsForm" style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="mt-2 ml-3 mb-4">
                <h4 style="color: #00FF00">
                    Basic Details
                </h4>
                <div class="row mr-3 ml-3">
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            User Name
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="fullName" style="background: #FFFFFF;" matInput placeholder="Enter User Name"
                                    formControlName="fullName" required pattern="^[a-zA-Z][\w\s]*$"
                                    (keypress)="handleStartingSpaceTextInput($event, basicDetailsForm.get('fullName').value)"
                                    >
                                <mat-error *ngIf="basicDetailsForm.get('fullName').hasError('required')">
                                    Full Name is required
                                </mat-error>
                                <mat-error *ngIf="basicDetailsForm.get('fullName').hasError('pattern')">
                                    Full Name has invalid characters
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Mobile Number
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="mobile" style="background: #FFFFFF;" (keypress)="allowNumkeyPress($event)" matInput
                                    maxlength="10" placeholder="Enter Mobile Number" formControlName="mobileNumber">
                                <mat-error *ngIf="basicDetailsForm.get('mobileNumber').hasError('required')">
                                    Mobile number is required
                                </mat-error>
                                <mat-error *ngIf="basicDetailsForm.get('mobileNumber').hasError('minlength')">
                                    Phone number must be at least 10 numbers
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Email ID
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="email" style="background: #FFFFFF;" matInput placeholder="Enter Email ID"
                                    formControlName="email" (keypress)="handleStartingSpaceTextInput($event, basicDetailsForm.get('email').value)">
                                <mat-error *ngIf="basicDetailsForm.get('email').hasError('required')">
                                    Email is required
                                </mat-error>
                                <mat-error *ngIf="basicDetailsForm.get('email').hasError('pattern')">
                                    Please enter a valid email address
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <div class="row  mr-3 ml-3 mt-3">
                    <div class="col-md-4" *ngIf="!isUpdate">
                        <div class="fw-bold fieldName">
                            Role
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <div class="">

                                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="role" style="width: 90%;">
                                    <mat-select [placeholder]="'Select Roles'"
                                        (selectionChange)="selectRole($event.value)" formControlName="role">
                                        <mat-option *ngFor="let role of roleList"
                                            [value]="role.id">{{role.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="isUpdate">
                        <div class="fw-bold fieldName">
                            Role
                        </div>
                        <div class="">
                            <div class="">

                                <mat-form-field class="w-full">
                                    <input id="role" style="background: #FFFFFF;" matInput readonly
                                        formControlName="role">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Gender
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;">
                                    <mat-select [placeholder]="'Select Gender'" formControlName="gender">
                                        <mat-option value="MALE">MALE</mat-option>
                                        <mat-option value="FEMALE">FEMALE</mat-option>
                                        <mat-option value="OTHER">OTHER</mat-option>
                                    </mat-select>

                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Date of Birth
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;">
                                <input matInput readonly [matDatepicker]="picker" [max]="maxDate"
                                    (dateInput)="addDOBEvent('input', $event)" (dateChange)="addDOBEvent('change',$event)"
                                    formControlName="dateOfBirth" [placeholder]="'Select DOB'">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker style="color:#00FF00"></mat-datepicker>
                            </mat-form-field>

                        </div>
                    </div>
                </div>
                <div class="row  mr-3 ml-3 mt-3">
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Date of Joining
                        </div>
                        <div class="">

                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;">
                                <input readonly matInput [matDatepicker]="picker1" [max]="maxDate"
                                    (dateInput)="addDOJEvent('input', $event)" (dateChange)="addDOJEvent('change',$event)"
                                    formControlName="dateOfJoining" [placeholder]="'Select DOJ'">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Designation
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="designation" style="background: #FFFFFF;" matInput
                                    placeholder="Enter Designation" formControlName="designation" 
                                    (keypress)="handleStartingSpaceTextInput($event, basicDetailsForm.get('designation').value)">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Report to Users
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="w-full">
                                    <mat-select [placeholder]="'Select Report to Users'"
                                        [(ngModel)]="selected_report_to_user_ids" multiple
                                        [ngModelOptions]="{standalone: true}">
                                        <mat-option *ngFor="let user of report_to_users" [value]="user.id">
                                            {{user.full_name}} ({{user.role.name}})
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Monthly Sick Leaves Count
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="SickLeavesCount" style="background: #FFFFFF;" matInput placeholder="Enter Monthly Sick Leaves count"
                                    formControlName="sickLeavesCount" (keypress)="allowNumkeyPress($event)">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="fw-bold fieldName">
                            Monthly&nbsp;Casual&nbsp;Leaves&nbsp;Count
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="CasualLeaves" style="background: #FFFFFF;" matInput placeholder="Enter Monthly Casual Leaves"
                                formControlName="casualLeavesCount" (keypress)="allowNumkeyPress($event)">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

            </div>


        </div>
        <!-- *ngIf="should_add_address" -->
        <div class="card mt-5" [formGroup]="userAddressForm"
            style="background-color: #FFFFFF;height: auto;padding: 10px; " *ngIf="should_add_address">
            <div class="mt-2 ml-3 mb-4">
                <h4 style="color: #00FF00;">
                    User Address
                </h4>
                <div class="row  mr-3 ml-3">
                    <div class="col-md-6">
                        <div class="fw-bold fieldName">
                            Address Line 1 <span style="color: red;">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="address1" style="background: #FFFFFF;" matInput placeholder="Enter Address"
                                    formControlName="line1" (keypress)="handleStartingSpaceTextInput($event, userAddressForm.get('line1').value)">
                                <mat-error *ngIf="userAddressForm.get('line1').hasError('required')">
                                    Address Line 1 is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="fw-bold fieldName">
                            Address Line 2
                            <!-- <span class="text-danger">*</span> -->
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                    <input id="line2" formControlName="line2" class="bg-white" matInput
                                    placeholder="Address Line 2" 
                                    (keypress)="handleStartingSpaceTextInput($event, userAddressForm.get('line2').value)"/>
                            </mat-form-field>
                        </div>
                    </div>


                </div>
                <div class="row mt-3 mr-3 ml-3">

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            State
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">

                            <!-- <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="role"  style="width: 90%;">
                                <mat-select [placeholder]="'All Roles'" (selectionChange)="selectedState($event.value)" formControlName="state">
                                  <mat-option *ngFor="let role of statesList" [value]="role.id">{{role.name}}</mat-option>
                                </mat-select>
                              </mat-form-field> -->

                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="role" style="width: 90%;">
                                <mat-select [formControl]="stateFormCtrl" name="state" [placeholder]="'Select State'">
                                    <mat-option *ngFor="let state of statesList" [value]="state.id">{{state.name
                                        |uppercase}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="userAddressForm.get('state').hasError('required')">
                                    State is required
                                </mat-error>
                            </mat-form-field>
                            <!-- <br> -->
                            <!-- <mat-slide-toggle [checked]="stateCtrl.disabled"
                                  (change)="stateCtrl.disabled ? stateCtrl.enable() : stateCtrl.disable()">
                                  Disable Input?
                                </mat-slide-toggle> -->

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            City
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="role" style="width: 90%;">
                                <mat-select [formControl]="cityFormCtrl" name="" [placeholder]="'Select City'" cityId>
                                    <mat-option *ngFor="let city of citiesList" [value]="city.id">{{city.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="userAddressForm.get('cityId').hasError('required')">
                                    City is required
                                </mat-error>
                            </mat-form-field>
                            <!-- <br> -->
                            <!-- <mat-slide-toggle [checked]="stateCtrl.disabled"
                                  (change)="stateCtrl.disabled ? stateCtrl.enable() : stateCtrl.disable()">
                                  Disable Input?
                                </mat-slide-toggle> -->
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            PIN Code
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="pincode" style="background: #FFFFFF;" (keypress)="allowNumkeyPress($event)" matInput
                                    maxlength="6" placeholder="Enter pincode" formControlName="pincode" required (keypress)="allowNumkeyPress($event)">
                                <mat-error *ngIf="userAddressForm.get('pincode').hasError('required')">
                                    PIN Code is required
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- *ngIf="should_add_branch_at_user_creation" -->
        <div class="card mt-5" style="background-color: #FFFFFF;height: auto;padding: 10px; "
            *ngIf="should_add_branch_at_user_creation">
            <div class="mt-2 ml-3 mb-4">
                <h4 style="color: #00FF00;">
                    Branch
                </h4>
                <div class="row  mr-3 ml-3">
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Select Branch
                            <span class="text-danger">*</span>
                        </div>
                        <div class="" *ngIf="can_add_multiple_branches">
                            <mat-form-field class="w-full">
                                <mat-select [placeholder]="'Select Branch'" [(ngModel)]="branch_list" multiple
                                    name="branch_list">
                                    <mat-option *ngFor="let branch of branchesList" [value]="branch.id">
                                        {{branch.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="" *ngIf="!can_add_multiple_branches">
                            <mat-form-field class="w-full">
                                <mat-select [placeholder]="'Select Branch'" [(ngModel)]="selected_branch_id" name="branch_list">
                                    <mat-option *ngFor="let branch of branchesList" [value]="branch.id">
                                        {{branch.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>


                </div>

            </div>


        </div>
        <div class="card mt-5" style="background-color: #FFFFFF;height: auto;padding: 10px; "
            *ngIf="should_add_availability_timings">
            <div class="mt-2 ml-3 mb-4 ">
                <h4 style="color: #00FF00;">
                    User Timings
                </h4>
            </div>
            <div class="row justify-content-around" style="max-height:450px;">
                <div class="col-sm-12 col-md-4 ">
                    <div class="d-flex flex-column align-items-center">
                        <div style="width: 75%;">
                            <div class="fw-bold fieldName">
                                Select Day
                            </div>
                            <div class="">
                                <div class="">
                                    <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                        <mat-select [placeholder]="'ALL'" (selectionChange)="selectedDays($event.value)"
                                            [(ngModel)]="days" multiple>
                                            <mat-checkbox class="" [checked]="allComplete"
                                                [indeterminate]="someComplete()" (change)="setAll($event)">
                                                Select All
                                            </mat-checkbox>
                                            <mat-option *ngFor="let day of daysList" [value]="day.value">
                                                {{day.viewValue}}
                                            </mat-option>
                                        </mat-select>

                                    </mat-form-field>
                                    <!-- 
                                    <mat-form-field>
                                        <mat-label>Toppings</mat-label>
                                        <mat-select [formControl]="toppings"  multiple>
                                          <mat-select-trigger>
                                            {{toppings.value?.[0] || ''}}
                                            <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                                              (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
                                            </span>
                                          </mat-select-trigger>
                                          <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                                        </mat-select>
                                      </mat-form-field> -->
                                </div>
                            </div>
                        </div>
                        <div style="width: 75%;">
                            <div class="fw-bold fieldName">
                                Select Day Type
                            </div>
                            <div class="">
                                <div class="">
                                    <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                        <mat-select [placeholder]="'ALL'" [disabled]="days.length <= 0"
                                            (selectionChange)="selectedDayType($event.value)" [(ngModel)]="daysType">
                                            <mat-option *ngFor="let day of dayType" [value]="day.value">
                                                {{day.viewValue}}
                                            </mat-option>
                                        </mat-select>

                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div style="width: 75%;">
                            <div class="fw-bold fieldName">
                                Start Time
                            </div>
                            <div class="">
                                <div class="">
                                    <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                        <input matInput class="cursor-pointer" [disabled]="disableTime" mode="12h"
                                            [placeholder]="'Select Start Time'" [(ngModel)]="startTime" matTimepicker>
                                        <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                                    </mat-form-field>


                                </div>
                            </div>
                        </div>
                        <div style="width: 75%;">
                            <div class="fw-bold fieldName">
                                End Time
                            </div>
                            <div class="">
                                <div class="">
                                    <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                        <input class="cursor-pointer" matInput [placeholder]="'Select End Time'"
                                            mode="12h" [disabled]="disableTime || !startTime" [minDate]="startTime"
                                            [(ngModel)]="endTime" (ngModelChange)="verifyTimeSlots()" matTimepicker>
                                        <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                                    </mat-form-field>
                                    <!-- <mat-form-field class="flex-auto gt-xs:pr-3 " style="width: 100%;">
                                <input class="cursor-pointer" matInput [placeholder]="'Select End date'" [(ngModel)]="endTime" [ngxMatTimepicker]="endtimepicker">
                                <ngx-mat-timepicker #endtimepicker></ngx-mat-timepicker>
                                <mat-icon svgIcon="mat_outline:access_time"></mat-icon>
                            </mat-form-field> -->

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end" *ngIf="showErrorMsgToAddTimeSlots">
                        <span class="text-danger">{{ errorMsgToAddTimeSlots }}</span>
                    </div>
                    <div class="d-flex mt-3" style="float: right;">
                        <button class="ml-4  addTimeBtn" [ngClass]="{'btn-clr': !disableAdd}" [disabled]="disableAdd"
                            mat-flat-button [color]="'primary'" (click)="addTimeSlots()">
                            <span class="ml-2 mr-1 txt" style="color: black">Add</span>
                        </button>
                    </div>

                </div>
                <div class=" col-sm-12 col-md-6  d-flex flex-column align-items-center"
                    style="max-height:450px; overflow-y:scroll;">
                    <table class="table " style="width: 90%;">
                        <thead class="thead-dark"
                            style="background: #000;color: #fff; position: sticky; top: 0; z-index: 1;">
                            <tr>
                                <th scope="col">Days</th>
                                <th scope="col">Day Type</th>
                                <th scope="col">Start Time</th>
                                <th scope="col">End Time</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let slot of sortedTimeSlots">
                                <th scope="row">{{slot.day}}</th>
                                <th>{{slot.dayType}}</th>
                                <th>{{slot.startTime }}</th>
                                <th>{{slot.endTime}}</th>
                                <td> <button mat-icon-button (click)="deleteDay(slot)">
                                        <mat-icon style="color:red;" svgIcon="mat_outline:delete"></mat-icon>
                                    </button></td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
        <app-file-upload-manager #fileUploadComponent></app-file-upload-manager>
    </div>

    <div class="btnposition">
        <div class="bottomBtns mb-3 row">
            <button class="ml-4 addBtn" mat-flat-button (click)="resetForm()" *ngIf="!isUpdate">
                <span class="ml-2 mr-1 txt">Reset</span>
            </button>
            <button class="ml-4 addBtn" mat-flat-button (click)="back()" *ngIf="isUpdate">
                <span class="ml-2 mr-1 txt">Cancel</span>
            </button>
            <span matTooltip="userTimings should be added for all the seven days"
                [matTooltipDisabled]="!(basicDetailsForm.invalid || (should_add_branch_at_user_creation && branch_list.length<=0) || (should_add_address && userAddressForm.invalid) || (should_add_availability_timings && !isValidTimings))"
                style="width: fit-content;">
            </span>
            <!-- Why tghis span is added here -->
            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="generateOtp()"
                *ngIf="!isUpdate" [disabled]="isCreateOrUpdateUserValid()"
                [ngClass]="{'btn-clr': !isCreateOrUpdateUserValid()}">
                <span class="ml-2 mr-1 txt" style="color: white">Create </span>
            </button>
            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="generateOtp()"
                *ngIf="isUpdate" [disabled]="isCreateOrUpdateUserValid()"
                [ngClass]="{'btn-clr': !isCreateOrUpdateUserValid()}">
                <span class="ml-2 mr-1 txt" style="color: white">Update </span>
            </button>
        </div>
    </div>
    <!-- <div class="loader" *ngIf="showloading">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div> -->
</div>