import { Component, ViewChild } from "@angular/core";
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { IAttendance, MetaService, featureConstants } from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ITicketActions } from "app/meta.service";
import { CreateTimeSlotDiscountsComponent } from "../create-timeslot-discounts/create-timeslot-discounts.component";
import { MatTableModule } from '@angular/material/table'  
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { getuserAllowedFeatures } from "app/utils/auth_utils";
import { VerifyDeactivateComponent } from "app/pages/commonComponents/verify-deactivate/verify-deactivate.component";
export interface Element {
    is_removed: boolean;
    id: String;
  }
@Component(
    {
        selector: 'app-timeslot-discounts',
        templateUrl: './timeslot-discounts.component.html',
        styleUrls: ['./timeslot-discounts.component.scss']
    }
)
export class TimeSlotDiscountsComponent {
    searchTerm = "";

    timeslotDiscountDetails = [];
    scrHeight: number;
    scrWidth: number;
    offSet = null;
    limit = null;
    viewWidth:any;
    filter_by_timeslot_active_status = null;
    searchInputControl: UntypedFormControl = new UntypedFormControl();
    
    timeslotStatus = [
      // { value: '', viewValue: 'All' },
      { value: 'true', viewValue: 'Active' },
      { value: 'false', viewValue: 'In Active' }
    ]

    displayedColumns = ["TimeSlot", "StartDate", "EndDate","DiscountPercentage", "createdBy", "createdDate","Actions"]; 
    dataSource = new MatTableDataSource<Element>();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValue= null;
    // Get User Feature Accesses
    userAllowedFeatures = getuserAllowedFeatures()
    isUserAllowedToViewTimeslotDiscounts = this.userAllowedFeatures.indexOf(featureConstants.VIEW_TIMESLOT_DISCOUNTS) > -1
    isUserAllowedToCreateTimeslotDiscounts = this.userAllowedFeatures.indexOf(featureConstants.CREATE_TIMESLOT_DISCOUNTS) > -1
    isUserAllowedToUpdateTimeslotDiscounts = this.userAllowedFeatures.indexOf(featureConstants.EDIT_TIMESLOT_DISCOUNTS) > -1
    isUserAllowedToDeactivateTimeslotDiscounts = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_TIMESLOT_DISCOUNTS) > -1
    isUserAllowedToReactivateTimeslotDiscounts = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_TIMESLOT_DISCOUNTS) > -1

    constructor(public apiService: ApiService,public meta: MetaService, public dialog: MatDialog, public toastr: ToastrService, private router: Router, private _liveAnnouncer: LiveAnnouncer) {
        this.getScreenSize();
    }

    getScreenSize(event?: any) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
    }
    ngOnInit() {
      const paginationDataString = sessionStorage.getItem('paginationDataForTimeslotDiscount');
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForDevice')
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');

   
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.filterValue=paginationData.filterValue;
      this.filter_by_timeslot_active_status=paginationData.filterByTimeslotActiveStatus;
    }
        this.filterTimeslotDiscounts()
    }
    ngAfterViewInit() {
      // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    announceSortChange(sortState: Sort) {
      if (sortState.direction) {
        this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
      } else {
        this._liveAnnouncer.announce('Sorting cleared');
      }
    }
    
    applyFilter(event: Event) {
        this.filterValue = (event.target as HTMLInputElement).value;
        this.onFilterTimeslotDiscountsForSearch()
      }

    filterTimeslotDiscounts() {
      
        let data =  {
            filter_by_timeslot_active_status: this.filter_by_timeslot_active_status,
            offset: this.bOffset,
            limit: this.bLimit,
            search_term: this.filterValue || null
            }

        this.apiService.getTimeslotDiscountsByFilter(data).subscribe((resp) => {
          if (resp.status == "success") {
            this.dataSource.data = resp.data.timeslot_discounts;
            this.dataSource.data.map((element: any) => {
              element.timeSlot = element.time_slot_alias.name
            });
            this.totalCount=resp.data.total_count
          }
        },
        (err) => {
          this.toastr.error(err.error.message)
        }
        );
    }
  createTimeslotDiscount() {
      sessionStorage.removeItem('editTimeslotDiscountId')
      this.router.navigateByUrl('Admin/create-timeslot-discount');
    }
  editTimeslotDiscount(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByTimeslotActiveStatus: this.filter_by_timeslot_active_status,
      filterValue: this.filterValue
    };
    sessionStorage.setItem('paginationDataForTimeslotDiscount', JSON.stringify(paginationData)); 
    sessionStorage.setItem('editTimeslotDiscountId', row.id)
    this.router.navigateByUrl('Admin/edit-timeslot-discount');
    // this.router.navigate(['Admin/create-timeslot-discount', {timeslot_discount_id: row.id}]);
  }


  verifyDeactivate(timeslot_discount_id:string){
    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
        "message": "This TimeSlot Discount won't be visible on Mobile App."
      }, // Pass data to the dialog
      width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {

      if (this.meta.dialogClosed) {
        this.deactivateTimeslotDiscount(timeslot_discount_id=timeslot_discount_id)
      }
    });
  }

    deactivateTimeslotDiscount(timeslot_discount_id: String) {
        this.apiService.deactivateTimeslotDiscount(timeslot_discount_id = timeslot_discount_id).subscribe((resp) => {
          if (resp.status == "success") {
            this.dataSource.data.map(
              timeslot_discount => {
                if (timeslot_discount.id == timeslot_discount_id)
                timeslot_discount.is_removed = true
              }
            )
          }
        },
          (err) => {
            this.toastr.error(err.error.message)
          }
        )
      }
    
      reactivateTimeslotDiscount(timeslot_discount_id: String) {
        this.apiService.reactivateTimeslotDiscount(timeslot_discount_id = timeslot_discount_id).subscribe((resp) => {
          if (resp.status == "success") {
            this.dataSource.data.map(
                timeslot_discount => {
                if (timeslot_discount.id == timeslot_discount_id){
                    timeslot_discount.is_removed = false
                }
              }
            )
          }
        },
          (err) => {
            this.toastr.error(err.error.message)
          }
        )
      }
  onPaginateChange(event: PageEvent): void {

        this.bLimit = event.pageSize;
        this.bOffset = (event.pageIndex) * event.pageSize;
        this.pageIndex = event.pageIndex;
      
      
        this.filterTimeslotDiscounts(); 
      }

onFilterTimeslotDiscountsForSearch(){
    this.pageIndex=0;
    this.bOffset=0;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByTimeslotActiveStatus: this.filter_by_timeslot_active_status,
      filterValue: this.filterValue
    };
    sessionStorage.setItem('paginationDataForTimeslotDiscount', JSON.stringify(paginationData)); 
    this.filterTimeslotDiscounts()
  }

  onFilterTimeslotDiscounts(){
    this.pageIndex=0;
    this.bOffset=0;
    this.filterValue=null;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByTimeslotActiveStatus: this.filter_by_timeslot_active_status,
      filterValue: this.filterValue
    };
    sessionStorage.setItem('paginationDataForTimeslotDiscount', JSON.stringify(paginationData)); 
    this.filterTimeslotDiscounts()
  }
}