import { Component, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox'
import { ActivatedRoute, Router } from '@angular/router';
import { Irole } from '../roles-list/roles-list.component';
import { IroleActions } from 'app/meta.service';
import { ApiService } from 'app/api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
}


@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss']
})

export class CreateRoleComponent {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  handleStartingSpaceTextInput = handleStartingSpaceTextInput

  displayedColumns: string[] = ['position', 'name', 'weight'];
  step = 0;
  to_value = 10;
  startrecords = 0;
  roleList: [];
  featuresview=[{
    "userMangement":{
      "View User":{web_App:true,mobile_App:false},
      "Edit User":{web_App:true,mobile_App:false},
      "1 User":{web_App:true,mobile_App:false}
    }
    
  }
   
  ]
  webFeaturesList = [];
  mobileFeaturesList = [];
  selectedfeaturesList = [];
  featuresList: [];
  heading = [];
  headingArr=[];
  dataArr=[];
  disbaledAdd:boolean =false;
  Irole: IroleActions = {
    roleAction: "",
    roleType: null,
    roleStatus: "true",
  }
  role_id:string;
  isUpdate:boolean=false;
  title:string="CREATE ROLE"
  public roleBasicDetailsForm: FormGroup;
 
  constructor(private router: Router, public apiService: ApiService, private route: ActivatedRoute, private _formBuilder: FormBuilder,private toastr:ToastrService) {
    // this.route.params.subscribe((params) => {
    //   this.role_id = params['role_id'];
    // });

    this.role_id = sessionStorage.getItem('editRoleId')

  }
  ngOnInit() {
    if(this.role_id){
      this.getRoleByID();
      this.isUpdate=true;
      this.title= "EDIT ROLE"
      // this.apiService.getRoleById(this.role_id).subscribe((resp)=>{
      // })
    }else{
      this.filterRole();
      this.getFeatures();
    }
    this.buildForm();
  
  }

  shouldAddAddressControl = new FormControl(false);
  shouldAddAvailabilityTimingsControl = new FormControl(false);
  shouldAddBranchControl = new FormControl(false);
  canAddMultipleBranchesControl = new FormControl(false);
  // shouldAddLevel = new FormControl(false)

  public buildForm() {
    this.roleBasicDetailsForm = this._formBuilder.group({
      name: new FormControl('', [Validators.required]),
      description: ['', []],
      shouldAddAddress: this.shouldAddAddressControl,
      shouldAddAvailabilityTimings: this.shouldAddAvailabilityTimingsControl,
      shouldAddBranch: this.shouldAddBranchControl,
      canAddMultipleBranches: this.canAddMultipleBranchesControl,
      // shouldAddLevel:this.shouldAddLevel,
    });
  }
  resetForm() {
    this.buildForm();
    this.shouldAddAddressControl = new FormControl(false);
    this.shouldAddAvailabilityTimingsControl = new FormControl(false);
    this.shouldAddBranchControl = new FormControl(false);
    this.canAddMultipleBranchesControl = new FormControl(false);
    this.Irole.roleType = "";
    this.webFeaturesList = [];
    this.mobileFeaturesList = [];
    this.selectedfeaturesList = [];
    this.featuresList = [];
    this.heading = [];
    this.headingArr = [];
    this.dataArr = [];
    this.getFeatures();
  }

  filterRole() {
    const data = {
      "filter_by_role_group_type": "DEFAULT_ROLES",
      "filter_by_role_active_status": true,
    }
    this.apiService.getRolesByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.roleList = resp.data.roles
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }
  getRoleByID() {
    this.headingArr=[];
    this.dataArr=[];
    this.heading=[];
    let role=""
    if(this.role_id){
      role = this.role_id
    }else{
      role = this.Irole.roleType
    }

    this.apiService.getRoleById(role).subscribe((resp) => {
      if (resp.status == "success") {
        if (this.role_id) {
          // this.title=resp.data.role.name
          this.roleBasicDetailsForm.controls.name.setValue(resp.data.role.name);
          this.roleBasicDetailsForm.controls.description.setValue(resp.data.role.description);
          this.shouldAddAddressControl.setValue(resp.data.role.should_add_address);
          this.shouldAddAvailabilityTimingsControl.setValue(resp.data.role.should_add_availability_timings);
          this.shouldAddBranchControl.setValue(resp.data.role.should_add_branch_at_user_creation);
          this.canAddMultipleBranchesControl.setValue(resp.data.role.can_add_multiple_branches);
        }
        this.selectedfeaturesList = resp.data.role.features;
        this.heading = Object.entries(this.selectedfeaturesList);
        this.heading.forEach(element => {
          this.headingArr.push(element[0])
          this.dataArr.push(element[1])
        });
        this.dataArr.forEach(element => {
          element.forEach(ele => {
            if (ele.is_checked_on_web_app == true) {
              this.webFeaturesList.push(ele.alias)
            }
            if (ele.is_checked_on_mobile_app == true) {
              this.mobileFeaturesList.push(ele.alias)
            }
          });
        });
        this.shouldAddAddressControl.setValue(resp.data.role.should_add_address);
        this.shouldAddAvailabilityTimingsControl.setValue(resp.data.role.should_add_availability_timings);
        this.shouldAddBranchControl.setValue(resp.data.role.should_add_branch_at_user_creation);
        this.canAddMultipleBranchesControl.setValue(resp.data.role.can_add_multiple_branches);
        // this.shouldAddLevel.setValue(resp.data.role.should_add_level)
      }
    },
      (err) => {
        this.toastr.error(err.error.message);
        this.router.navigateByUrl('Admin/roles');
      });
  }

  
  getFeatures() {
    this.apiService.getFeatures().subscribe((resp) => {
      this.featuresList = resp.data.features;
      this.heading = Object.entries(this.featuresList);
      this.heading.forEach(element => {
        this.headingArr.push(element[0]) 
        this.dataArr.push(element[1])
      });    
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }
  selectedWed(name, event) {
  
    if (event.checked == true) {
      this.webFeaturesList.push(name);
    } else {
      this.webFeaturesList = this.webFeaturesList.filter(each => each != name)
    }
  }
  selectedMobile(name, event) {
   
    if (event.checked == true) {
      this.mobileFeaturesList.push(name);
    } else {
      this.mobileFeaturesList = this.mobileFeaturesList.filter(each => each != name)
    }
  }

  createRole(){
    const payload: any = {}
    payload['role']={
      name: this.roleBasicDetailsForm.controls.name.value,
      description:this.roleBasicDetailsForm.controls.description.value,
      should_add_availability_timings: this.roleBasicDetailsForm.controls.shouldAddAvailabilityTimings.value,
      should_add_address: this.roleBasicDetailsForm.controls.shouldAddAddress.value,
      should_add_branch_at_user_creation: this.roleBasicDetailsForm.controls.shouldAddBranch.value,
      can_add_multiple_branches: this.roleBasicDetailsForm.controls.canAddMultipleBranches.value,
      // should_add_level:this.roleBasicDetailsForm.controls.shouldAddLevel.value,
      parent_role_id:this.Irole.roleType,
    }
    payload.role['features'] = [
      {
        device_type : "WEB_APP",
        allowed_features:this.webFeaturesList
      },
      {
        device_type : "MOBILE_APP",
        allowed_features:this.mobileFeaturesList
      }
    ]

    // if(this.webFeaturesList.length > 0){
    // }
    // if(this.mobileFeaturesList.length>0){
    //   payload.role.features = {
    //     device_type : "MOBILE_APP",
    //     allowed_features:this.mobileFeaturesList
    //   }
    // }
    this.apiService.createRole(payload).subscribe((resp) => {
      this.router.navigateByUrl('Admin/roles');
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  updateRole(){
    const payload: any = {}
    payload['role']={
      name: this.roleBasicDetailsForm.controls.name.value,
      description:this.roleBasicDetailsForm.controls.description.value,
      should_add_availability_timings: this.roleBasicDetailsForm.controls.shouldAddAvailabilityTimings.value,
      should_add_address: this.roleBasicDetailsForm.controls.shouldAddAddress.value,
      should_add_branch_at_user_creation: this.roleBasicDetailsForm.controls.shouldAddBranch.value,
      can_add_multiple_branches: this.roleBasicDetailsForm.controls.canAddMultipleBranches.value,
      // should_add_level:this.roleBasicDetailsForm.controls.shouldAddLevel.value,
      parent_role_id:this.Irole.roleType,
    }
    payload.role['features'] = [
      {
        device_type : "WEB_APP",
        allowed_features:this.webFeaturesList
      },
      {
        device_type : "MOBILE_APP",
        allowed_features:this.mobileFeaturesList
      }
    ]

    // if(this.webFeaturesList.length > 0){
    // }
    // if(this.mobileFeaturesList.length>0){
    //   payload.role.features = {
    //     device_type : "MOBILE_APP",
    //     allowed_features:this.mobileFeaturesList
    //   }
    // }
    this.apiService.updateRole(payload,this.role_id).subscribe((resp) => {
      this.router.navigateByUrl('Admin/roles');
   }, (err) => {
    // this.alert = {
    //     type: 'error',
    //     message: err.error.message
    // };
    this.toastr.error(err.error.message)
    // Show the alert
    // this.showAlert = true;
})
  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  back() {
    this.router.navigateByUrl('Admin/roles');
  }

}
