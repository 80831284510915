import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { catchError, map, Observable, throwError } from "rxjs";
import { getOrCreateDeviceId } from "./utils/auth_utils";

@Injectable({
    providedIn: 'root',
})

export class ResourceServiceService {
    access_token: string;

    public apiUrl: string = environment.apiUrl;

    headers: any
    private isDebugging: boolean = false;
    constructor(public httpClient: HttpClient) {
        this.headers = new HttpHeaders({
            "device-id": getOrCreateDeviceId(),
            "device-type": "WEB_APP",
            // "authorization":this.access_token
        })
        this.access_token = "Bearer" + localStorage.getItem('accessToken');
    }

    public requestPost(path: any, param: any, HttpParams: any = false, queryParams: any = false): Observable<any> {
        if (HttpParams) {
            //Adding path params in url - Send httpParams only when path params are present
            path = path(HttpParams);
        }

        const url = this.apiUrl + path;

        if (this.isDebugging) {
        }
        if (!queryParams){
            queryParams = {}
        }

        const base = this.httpClient.post(url, param, {
            headers: this.headers, params: queryParams
        }).pipe(catchError(this.handleError));
        const request = base.pipe(
            map((response) => {
                if (this.isDebugging) {
                }
                return response;
            })
        );
        return request;
    }
    // GET API
    public requestGet(path: any, httpParams?: any, queryParams?: any): Observable<any> {
        if (httpParams) {
            // Adding path params in url - Send httpParams only when path params are present
            path = path(httpParams);
        }

        let url = this.apiUrl + path;
        if (this.isDebugging) {
        }

        if (url.includes('/sdk/v2/')) {
            url = url.replace('api/v2/', '');
        }

        const base = this.httpClient.get(url, { headers: this.headers, params: queryParams }).pipe(catchError(this.handleError));
        const request = base.pipe(
            map((response) => {
                if (this.isDebugging) {
                }
                return response;
            })
        );
        return request;
    }

    // PUT API
    public requestPut(path: any, param: any,): Observable<any> {
        const url = this.apiUrl + path;

        if (this.isDebugging) {
        }

        const base = this.httpClient.put(url, param, {
            headers: this.headers
        }).pipe(catchError(this.handleError));
        const request = base.pipe(
            map((response) => {
                if (this.isDebugging) {
                }
                return response;
            })
        );
        return request;
    }
    // PUT API
    public requestPatch(path: any, param: any, pathParams?: any): Observable<any> {

        if (pathParams) {
            //Adding path params in url - Send httpParams only when path params are present
            path = path(pathParams);
        }

        const url = this.apiUrl + path;

        if (this.isDebugging) {
        }

        const base = this.httpClient.patch(url, param, {
            headers: this.headers
        }).pipe(catchError(this.handleError));
        const request = base.pipe(
            map((response) => {
                if (this.isDebugging) {
                }
                return response;
            })
        );
        return request;
    }

    // DELETE API
    public requestDelete(path: string): Observable<any> {
        const url = this.apiUrl + path;

        if (this.isDebugging) {
        }

        const base = this.httpClient.delete(url).pipe(catchError(this.handleError));
        const request = base.pipe(
            map((response) => {
                if (this.isDebugging) {
                }

                return response;
            })
        );
        return request;
    }

    private handleError(errorResponse: HttpErrorResponse) {
        let errorMessage = '';
        if (errorResponse.error instanceof ErrorEvent) {
            errorMessage = `Error: Client Side Error: ${errorResponse.error.message}`;
        } else {
            // errorMessage = `Error: Server side error: ${errorResponse.error.message}`;
            // errorMessage = "Server side error: There is a problem with the server. We are notified & working on it. Please try again later!"
        }

        //         window.alert(errorMessage);
        return throwError(errorResponse);
    }
}