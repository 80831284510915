import { Component, ViewChild } from "@angular/core";
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { IAttendance, featureConstants } from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ITicketActions } from "app/meta.service";
import e from "cors";
import { EditTicketComponent } from "../edit-ticket/edit-ticket.component";
import { getuserAllowedFeatures } from "app/utils/auth_utils";
@Component(
    {
        selector: 'app-tickets-list',
        templateUrl: './tickets-list.component.html',
        styleUrls: ['./tickets-list.component.scss']
    }
)
export class TicketsListComponent {

    searchTerm = "";

    scrHeight: number;
    scrWidth: number;
    
    totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValue= null;
    roleId: string
    customersList: [];
    tagsList: [];
    ticketDetails = [];
    ITicket: ITicketActions = {
        tagId: "",
        userId:"",
        ticketId: ""
      }
    searchInputControl: UntypedFormControl = new UntypedFormControl();

    displayedColumns = ["ticketid", "customer", "subject","issuetype", "status", "CreatedBy", "CreatedDate", "Actions"]; 
    dataSource = new MatTableDataSource<Element>();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    // Get User Feature Accesses
    userAllowedFeatures = getuserAllowedFeatures()
    isUserAllowedToViewTickets = this.userAllowedFeatures.indexOf(featureConstants.VIEW_TICKETS) > -1
    isUserAllowedToCreateTickets = this.userAllowedFeatures.indexOf(featureConstants.CREATE_TICKETS) > -1
    isUserAllowedToUpdateTickets = this.userAllowedFeatures.indexOf(featureConstants.EDIT_TICKETS) > -1
    isUserAllowedToViewRoles = this.userAllowedFeatures.indexOf(featureConstants.VIEW_ROLES) > -1
    isUserAllowedToResolveTickets = this.userAllowedFeatures.indexOf(featureConstants.RESOLVE_TICKETS) > -1

    constructor(public apiService: ApiService, public dialog: MatDialog, public toastr: ToastrService, private router: Router) {
        this.getScreenSize();
    }

    getScreenSize(event?: any) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
    }

    // Get Tickets, Customers, Roles on Initialize
    ngOnInit() {
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
        this.getCustomers();
        if (this.isUserAllowedToViewRoles) {
            this.getRoles();
          } 
        this.getTags();
        this.filterTickets();  
    }
    ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    }
    getTickets() {
        this.apiService.getTicketDetails().subscribe((resp)=>{
            if (resp.status == "success") {
              this.dataSource.data = resp.data.tickets;
            }
          },
            (err) => {
              this.toastr.error(err.error.message)
            })
    }

    applyFilter(event: Event) {
        this.filterValue = (event.target as HTMLInputElement).value;
        this.onFilterTicketsForSearch()
       
      }
    getRoles() {
        // Get Customer Role Id to get the users with customer role
        let data = {"search_term": "CUSTOMER"}
        this.apiService.getRolesByFilter(data).subscribe((resp) => {
            if (resp.status == "success") {
                if (resp.data.roles) {
                    this.roleId = resp.data.roles[0].id
                }
            }
        },
        (err) => {
            this.toastr.error(err.error.message)
        });
    }
    filterTickets() {
        let data =  {
            filter_by_tag_id: this.ITicket.tagId,
            filter_by_user_id: this.ITicket.userId,
            offset: this.bOffset,
            limit: this.bLimit,
            search_term: this.filterValue || null
            }
            
        this.apiService.getTicketsByFilter(data).subscribe((resp) => {
            if (resp.status == "success") {
                this.dataSource.data=resp.data.tickets;
                this.totalCount=resp.data.total_count
            }
        });
    }
    getCustomers() {
        // Get the users filtered by customer id
        let data = {"filter_by_role_id": this.roleId}
        this.apiService.getUserByFilter(data).subscribe((resp) => {
            if (resp.status == "success") {
            this.customersList = resp.data.users // Add the given users to customers_list
            }
            
        })
    }
    getTags() {
        // Get the tags
        this.apiService.getTags().subscribe((resp) => {
            if (resp.status == "success") {
            this.tagsList = resp.data.tags // Add the given users to customers_list
            }
            
        })
    }
    createTicket(){
        this.router.navigateByUrl('Admin/create-ticket');
    }
    
    viewTicket(row) {
        this.apiService.getTicketById(row.id).subscribe((resp) => {
            if (resp.status == "success") {
                this.viewTicketPopUp(resp.data.ticket, false)
            }
        },
        (err) => {
          this.toastr.error(err.error.message)
        })
    }

    resolveTicket(row) {
        this.apiService.getTicketById(row.id).subscribe((resp) => {
            if (resp.status == "success") {
                this.viewTicketPopUp(resp.data.ticket, true)
            }
        },
        (err) => {
          this.toastr.error(err.error.message)
        })
    }

    viewWidth:any
    viewTicketPopUp(ticket_data, shouldShowApprovalScreen) {
        if(this.scrWidth < 1450){
            this.viewWidth='80%'
          }else{
            this.viewWidth='50%'
          }
          let dialogRef = this.dialog.open(EditTicketComponent, {
            data: {
                  ticketData: ticket_data,
                  shouldShowApprovalScreen: shouldShowApprovalScreen
            },
            // height: auto,
            width: this.viewWidth,
          });
          dialogRef.afterClosed().subscribe(result => { 
            this.getTickets()
          });
    }
    onPaginateChange(event: PageEvent): void {

        this.bLimit = event.pageSize;
        this.bOffset = (event.pageIndex) * event.pageSize;
        this.pageIndex = event.pageIndex;
      
      
        this.filterTickets(); 
      }
    
onFilterTicketsForSearch(){
        this.bOffset=0;
        this.pageIndex=0;
        this.filterTickets()
    }
    
  onFilterTickets(){
    this.bOffset=0;
    this.pageIndex=0;
    this.filterTickets()
  }

}