<!-- <h1  mat-dialog-title>View User</h1> -->
<div class="bgCard">

    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>VIEW PLAN</h1>
        <button align="end" mat-icon-button mat-dialog-close>
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>
        </button>
    </div>
    <div>
        <mat-dialog-content class="mat-typography">
            <div class="card dataCard" *ngFor="let val of plans">
                <div class="mt-2 ml-3 ">
                    <h1 class="headings">
                        Details
                    </h1>
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Plan Name:
                            </div>
                            <div class="fw-bold">
                                {{val.name}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Plan Type:
                            </div>
                            <div class="fw-bold">
                               {{val.plan_type.name}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Plan Sub Type:
                            </div>
                            <div class="fw-bold">
                                {{val.plan_sub_type.name}}
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Plan Hierarchy:
                            </div>
                            <div class="fw-bold">
                                {{val.plan_hierarchy}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Trainer Roles:
                            </div>
                            <div class="fw-bold">
                                {{val.trainerRoleNames.join(', ')}}
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                <mat-checkbox labelPosition="after" [checked]="val.can_book_slots" [disabled]="true">
                                    <span class="listFont fw-bold">Can Book Slots</span>
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Slot Booking Days:
                            </div>
                            <div class="fw-bold">
                                {{val.slot_booking_days.join(', ')}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                SAC Code:
                            </div>
                            <div class="fw-bold">
                                {{val.sac_code}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                No.of Free Reschedules Per Month:
                            </div>
                            <div class="fw-bold">
                                {{val.no_of_reschedules_per_month}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Suitable For Customers:
                            </div>
                            <div class="fw-bold">
                                {{suitableForCustomers}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <div class="listFont fw-bold">
                                Created By:
                            </div>
                            <div class="fw-bold">
                                {{val.created_by.full_name}}<span *ngIf="val.created_by.role">({{val.created_by.role.name}})</span>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <div class="listFont fw-bold">
                                Created Date:
                            </div>
                            <div class="fw-bold">
                                {{val.created_at | dateFormat }}
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="card dataCard mt-4" style="height: auto !important;">
                <div class="mt-2 ml-3 ">
                    <h4 class="headings">
                        Plan Duration and Price
                    </h4>
                    <div class="col-sm-12 col-md-6 d-flex flex-column  m-auto" style="max-height: 450px; overflow-y: scroll;">
                        <table class="table table-responsive" style="width: 90%">
                            <thead class="thead-dark" style="background: #000; color: #fff; position: sticky; top: 0; z-index: 1;">
                                <tr>
                                    <th scope="col">Duration</th>
                                    <th scope="col">Plan Price</th>
                                    <th scope="col">SGST</th>
                                    <th scope="col">CGST</th>
                                    <th scope="col">IGST</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let val of planDuration" [ngClass]="{'delete-records-style': val.is_removed}">
                                    <td>{{ val.validity_in_months}}</td>
                                    <td>{{ val.price }}</td>
                                    <td>{{ val.sgst_tax_price }}</td>
                                    <td>{{ val.cgst_tax_price }}</td>
                                    <td>{{ val.igst_tax_price }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    

                </div>
            </div>
            <div class="card dataCard mt-4" style="height: auto !important;">
                <div class="mt-2 ml-3 ">
                    <h4 class="headings">
                        Plan Benefits
                    </h4>
                    <div class="col-sm-12 col-md-6 d-flex flex-column align-items-center m-auto"
                        style="max-height:450px; overflow-y:scroll;">
                        <table class="table" style="width: 90%">
                            <thead class="thead-dark"
                                style="background: #000; color: #fff;  position: sticky; top: 0; z-index: 1;">
                                <tr>
                                    <th scope="col">PlanBenefits</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let val of PlanBenefits">
                                    <td>{{ val}}</td>
                                  </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </mat-dialog-content>
    </div>


</div>

<!-- <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
    </mat-dialog-actions> -->