<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-lg-8 mt-8 d-flex flex-column flex-sm-row">
           
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{FirstName}}</h2>
            
        </div>
        <div class="col-sm-12 col-lg-4 mt-8 d-flex justify-content-end ">
            <button class="ml-4 addBtn" mat-flat-button [color]="'primary'" (click)="createPayable()" *ngIf="isUserAllowedToCreatePayables"
              [ngClass]="{'btn-clr': true}">
              <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
              <span class="ml-2 mr-1 txt" style="color: white">Create Pay</span>
            </button>
          </div>
    </div>
    
    <div class="mat-elevation-z8 mt-8 overflow-auto">
       
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="Monthly">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Monthly Pay</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.monthly_pay_in_rupees}} </td>
            </ng-container>
            
            <ng-container matColumnDef="Session">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Session Pay</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.session_pay_in_rupees}} </td>
            </ng-container>

            <ng-container matColumnDef="FromDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>From Date</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.start_date | dateFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="ToDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>To Date</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row?.end_date | dateFormat}} </td>
            </ng-container>

            <ng-container matColumnDef="OverTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Over Time</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.allowed_for_overtime ? 'YES' : 'NO'}} </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <mat-cell class="" style="height: 52px;" *matCellDef="let row;">
                    <button mat-button [matMenuTriggerFor]="menu" [disabled]="row.is_removed">
                        <mat-icon style="color:#0038FF;font-size: 30px;" svgIcon="heroicons_outline:dots-vertical"></mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToEditPayables" (click)="editPayable(row)">Edit</button>
                    </mat-menu>
                </mat-cell>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>