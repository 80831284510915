
import { DatePipe, formatDate } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { AuthService } from 'app/core/auth/auth.service';
import { MetaService, viewUser } from 'app/meta.service';
import { FileUploadManagerComponent } from 'app/pages/commonComponents/file-upload-manager/file-upload-manager.component';
import { environment } from 'environments/environment';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-leave-status',
  templateUrl: './update-leave-status.component.html',
  styleUrls: ['./update-leave-status.component.scss']
})
export class UpdateLeaveStatusComponent {

  @ViewChild('descriptionId') control: any

  @ViewChild('fileUploadComponent', { static: false }) fileUploadComponent: FileUploadManagerComponent;

  existingFileDataForFileManager = []
  actualexistingFilesCountForFileManager = 0

  leaveData;
  shouldShowApprovalScreen = false;
  LeaveDates;
  Status = null;
  description = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router, private meta: MetaService, public dialog: MatDialog, private route: ActivatedRoute, public apiService: ApiService, public _authService: AuthService, private datePipe: DatePipe, private _formBuilder: FormBuilder, private toastr: ToastrService,
    private dialogRef: MatDialogRef <UpdateLeaveStatusComponent>) { }
  ngOnInit() {
    this.getLeavesById();
  }
  getLeavesById() {
    this.leaveData = this.data.leaveData;
    this.shouldShowApprovalScreen = this.data.shouldShowApprovalScreen;
    this.description = this.leaveData.reason
    this.Status = this.leaveData.status
    // Assuming only one set of dates will be chosen
    this.LeaveDates = this.leaveData.leave_dates[0];

    this.existingFileDataForFileManager = this.leaveData.file_name_urls.map((ele) => {
      return {
        fileName: ele.filename,
        fileBaseUrl: ele.file_url,
        fileUrl: environment.apiUrl + ele.file_url
      }
    })
    this.actualexistingFilesCountForFileManager = this.existingFileDataForFileManager.length
  }

  createLeaveStatus() {
    let requestData = {
      approve_status: this.Status,
      reason: this.description,
    };

    this.apiService.createLeaveStatus(requestData, this.leaveData.id).subscribe(
        (resp) => {
            if (resp.status == 'success') {
              this.dialogRef.close();
                this.router.navigateByUrl('Admin/user-leaves');
            }
        },
        (err) => {
            this.toastr.error(err.error.message);
        }
    );
  }
  
  LeaveStatusChanged(event, descriptionId=1) {
    this.description = null;
    this.description.control.markAsUntouched()
  }

  reset() {
    this.description = this.leaveData.reason;
    this.Status = this.leaveData.status;
  }

}


