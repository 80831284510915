import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { IDeviceActions, featureConstants } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';



export interface Element {
  is_removed: boolean;
  id: String;
  BranchName: string;
  Location: string;
  Number: string;
  viewUser: number;
  IsRemoved: boolean;
  CreatedBy: string;
}


@Component({
  selector: 'app-plan-level',
  templateUrl: './plan-level.component.html',
  styleUrls: ['./plan-level.component.scss']
})
export class PlanLevelComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['PlanType', 'CreatedBy', 'CreatedDate' ,'Actions'];
  dataSource = new MatTableDataSource<Element>();
  branches_list = [];
  roleType = [
    // { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }

  ]
  IDevice: IDeviceActions = {
    deviceActive: "",
    branchId: "",
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1
  isUserAllowedToDeviceCreate = this.userAllowedFeatures.indexOf(featureConstants.CREATE_ATTENDANCE_DEVICES) > -1
  isUserAllowedToDeviceEdit = this.userAllowedFeatures.indexOf(featureConstants.EDIT_ATTENDANCE_DEVICES) > -1
  isUserAllowedToDeviceDeactivate = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_ATTENDANCE_DEVICES) > -1
  isUserAllowedToDeviceReactivate = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_ATTENDANCE_DEVICES) > -1

  constructor(private router: Router, public dialog: MatDialog, public apiService: ApiService, private toastr: ToastrService) {

  }
  ngOnInit() {
    if (this.isUserAllowedToViewBranches) {
      this.getBranches();
    }
    this.getDevices()
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getDevices(){
    this.apiService.getDevicesList().subscribe((resp)=>{
      if (resp.status == "success") {
        this.dataSource.data = resp.data.attendance_devices;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  getBranches() {
    let data = { "filter_by_branch_active_status": true }
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branches_list = resp.data.branches
        // this.toastr.success("Branches Loaded successfully");
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  filterDevice(value) {
    let data = {}
    // if (this.IBranch.branchId) {
    data = {
      filter_by_branch_id: this.IDevice.branchId,
      filter_by_attendance_device_active_status: this.IDevice.deviceActive,

    }

    this.apiService.getDeviceByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data =  resp.data.attendance_devices;
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }
  addDevice() {
    this.router.navigateByUrl('Admin/create-device');
  }
  editDevice(row) {
   
    this.router.navigate(['Admin/create-device', { device_id:row.id }]);
   
  }
  deactivateDevice(device_id: String) {
    this.apiService.deactivateDevice(device_id = device_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          device => {
            if (device.id == device_id)
              device.is_removed = true
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  reactivateDevice(device_id: String) {
    this.apiService.reactivateDevice(device_id = device_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          device => {
            if (device.id == device_id)
              device.is_removed = false
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

}
