import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-coupon',
  templateUrl: './view-coupon.component.html',
  styleUrls: ['./view-coupon.component.scss']
})
export class ViewCouponComponent {

  coupon_id = null;
  coupon;

  constructor(private router: Router, public apiService: ApiService, public toastr: ToastrService) {
    this.coupon_id = sessionStorage.getItem('viewCouponId')
  }

  ngOnInit() {
    if (this.coupon_id) {
      this.getCouponById();
    } else {
      this.router.navigateByUrl('Admin/coupons');
    }

  }

  getCouponById() {
    this.apiService.getCouponById(this.coupon_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.coupon = resp.data.coupon
        // this.couponName = resp.data.coupon.name;
        // this.Discount_Percentage = resp.data.coupon.discount_percentage;
        // this.Discount_Price = resp.data.coupon.discount_price;
        // this.Minimum_Purchase_Price = resp.data.coupon.min_purchase_price;
        // this.Maximum_Usages = resp.data.coupon.max_usages;
        // this.fromDate = resp.data.coupon.valid_from;
        // this.toDate = resp.data.coupon.valid_to;
        // this.customerType = resp.data.coupon.applicable_to;
        // this.couponType = resp.data.coupon.coupon_type.id;
        // this.termsAndConditions = resp.data.coupon.terms_and_conditions
      }},
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

}
