<div class="container-fluid">
  <div class="d-flex m-2 mb-4">
    <div class=" mr-2">
      <button mat-icon-button (click)="back()">
        <mat-icon style="color:#00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
      </button>
    </div>
    <h2 class="h3 createHeading">{{title}}</h2>
  </div>

  <div class="card m-2" [formGroup]="roleBasicDetailsForm"
    style="background-color: #FFFFFF;height: auto;padding: 10px; ">
    <!-- <div class="mt-2 ml-3 mb-4">
      <h4 style="color: #00A844;">
        Basic Details
      </h4>
    </div> -->

    <div class="row mr-3 ml-3 mt-5">
      <div class="col-md-4">
        <div class="fw-bold fieldName">
          Role Sub Category
          <span style="color: red;">*</span>
        </div>
        <div class="">
          <mat-form-field class="w-full">
            <input id="name" style="background: #FFFFFF;" matInput placeholder="Enter Role Name" formControlName="name" pattern="^[a-zA-Z][\w\s]*$" 
            (keypress)="handleStartingSpaceTextInput($event, roleBasicDetailsForm.get('name').value)">
            <mat-error *ngIf="roleBasicDetailsForm.get('name').hasError('required')">
              Role Name is required
            </mat-error>
            <mat-error *ngIf="roleBasicDetailsForm.get('name').hasError('pattern')">
              Role Name has invalid characters
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!isUpdate">
        <div class="fw-bold fieldName">
          Role Category
          <span class="text-danger">*</span>
        </div>
        <div class="">
          <div class="">

            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
              <mat-select [placeholder]="'Select Default Role'" [(ngModel)]="Irole.roleType"
                [ngModelOptions]="{standalone: true}" (selectionChange)="getRoleByID()">
                <mat-option *ngFor="let role of roleList" [value]="role.id">{{role.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="fw-bold fieldName">
          Description
        </div>
        <div class="">
          <mat-form-field class="w-full">
            <input id="description" style="background: #FFFFFF;" matInput placeholder="Enter Role Description"
              formControlName="description" 
              (keypress)="handleStartingSpaceTextInput($event, roleBasicDetailsForm.get('description').value)">
          </mat-form-field>
        </div>
      </div>

    </div>

    <!-- <div class="row  mr-3 ml-3 mt-3" *ngIf="!isUpdate">
        <div class="col-md-4">
          <div class="fw-bold fieldName">
            Select Default Role
          </div>
          <div class="">
            <div class="">

              <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                <mat-select [placeholder]="'Select Role'" [(ngModel)]="Irole.roleType"
                  [ngModelOptions]="{standalone: true}" (selectionChange)="getRoleByID()">
                  <mat-option *ngFor="let role of roleList" [value]="role.id">{{role.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div> -->


  </div>
  <!-- <div class="card m-2" style="background-color: #FFFFFF;height: auto;padding: 10px; display: flex;flex-wrap: wrap;">
    <div class="mt-2 ml-3 mb-4 ">
      <h4 style="color: #00FF00;">
        General Details
      </h4>
    </div>

    <div class="row mr-5 ">
      <div class="justify-content-between featureCheckbox">
        
        <div class="col-lg-3"><mat-checkbox [formControl]="shouldAddAddressControl">
            <span class="fw-bold fieldName">Should&nbsp;Add&nbsp;Address</span>
          </mat-checkbox></div>
        <div class="col-lg-3 "><mat-checkbox [formControl]="shouldAddAvailabilityTimingsControl">
            <span class="fw-bold fieldName">Should&nbsp;Add&nbsp;Availability&nbsp;Timings</span>
          </mat-checkbox></div>

        <div class="col-lg-3 "> <mat-checkbox [formControl]="shouldAddBranchControl">
            <span class="fw-bold fieldName">Should&nbsp;Add&nbsp;Branch</span>
          </mat-checkbox></div>

        <div class="col-lg-3 col-md-6"> <mat-checkbox [formControl]="canAddMultipleBranchesControl">
            <span class="fw-bold fieldName">Can&nbsp;Add&nbsp;Multiple&nbsp;Branches</span>
          </mat-checkbox></div>





        <!-- <mat-checkbox [formControl]="shouldAddLevel">
        <span class="fw-bold">Should Add Level</span>
      </mat-checkbox> -->

      <div class="card m-2" style="background-color: #FFFFFF; height: auto; padding: 10px; display: flex; flex-wrap: wrap;">
        <div class="mt-2 ml-3 mb-4">
          <h4 style="color: #00FF00;">General Details</h4>
        </div>
      
        <div class="row mr-5">
          <div class="col-xl-3 col-lg-5 col-md-6 mb-2"> 
            <mat-checkbox [formControl]="shouldAddAddressControl">
              <span class="fw-bold fieldName">Should&nbsp;Add&nbsp;Address</span>
            </mat-checkbox>
          </div>
          <div class="col-xl-3 col-lg-5 col-md-6 mb-2"> 
            <mat-checkbox [formControl]="shouldAddAvailabilityTimingsControl">
              <span class="fw-bold fieldName">Should&nbsp;Add&nbsp;Availability&nbsp;Timings</span>
            </mat-checkbox>
          </div>
          <div class="col-xl-3 col-lg-5 col-md-6 mb-2"> 
            <mat-checkbox [formControl]="shouldAddBranchControl">
              <span class="fw-bold fieldName">Should&nbsp;Add&nbsp;Branch</span>
            </mat-checkbox>
          </div>
          <div class="col-xl-3 col-lg-5 col-md-6 mb-2"> 
            <mat-checkbox [formControl]="canAddMultipleBranchesControl">
              <span class="fw-bold fieldName">Can&nbsp;Add&nbsp;Multiple&nbsp;Branches</span>
            </mat-checkbox>
          </div>
        </div>
      </div>
      
  <div class="m-2">
    <div class="card-header mb-4">
      <h4 class="p-3 ml-2.5 " style="">
        Access Controls
      </h4>
    </div>
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel *ngFor="let head of heading; let i = index ">
        <mat-expansion-panel-header>
          <mat-panel-title [ngClass]="{'h6': step === 0}"
            style="color: #000000 !important; font-weight: bold; font-size: 18px;">
            {{headingArr[i]}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <table class="table">
          <thead>
            <tr>
              <th scope="col" style="font-size: 16px">Features</th>
              <th scope="col"></th>
              <th scope="col" style="font-size: 16px">Web</th>
              <th scope="col" style="font-size: 16px">Mobile</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of dataArr[i]">
              <td width="60%" class="fieldName">{{row.name}}</td>
              <td width="10px"></td>
              <td width="20px"><mat-checkbox [disabled]="!row.is_available_on_web_app"
                  [checked]="row.is_checked_on_web_app" (change)='selectedWed(row.alias,$event)'
                  class="example-margin"></mat-checkbox></td>
              <td width="20px"><mat-checkbox [disabled]="!row.is_available_on_mobile_app"
                  [checked]="row.is_checked_on_mobile_app" (change)='selectedMobile(row.alias,$event)'
                  class="example-margin"></mat-checkbox></td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>

  </div>

  <div class="m-2 btnposition">
    <div class="bottomBtns mb-3 ">
      <button class="ml-4 addBtn" (click)="resetForm()" *ngIf="!isUpdate" mat-flat-button>
        <span class="ml-2 mr-1 txt">Reset</span>
      </button>
      <button class="ml-4 addBtn" (click)="back()" *ngIf="isUpdate" mat-flat-button>
        <span class="ml-2 mr-1 txt">Cancel</span>
      </button>
      <button class="ml-4 mr-6 addBtn" *ngIf="!isUpdate" mat-flat-button [color]="'primary'" (click)="createRole()"
        [disabled]="roleBasicDetailsForm.invalid || !Irole.roleType"
        [ngClass]="{'btn-clr': !(roleBasicDetailsForm.invalid || !Irole.roleType)}">
        <span class="ml-2 mr-1 txt" style="color: white">Create </span>
      </button>
      <button class="ml-4 mr-6 addBtn" *ngIf="isUpdate" mat-flat-button [color]="'primary'" (click)="updateRole()"
        [disabled]="roleBasicDetailsForm.invalid" [ngClass]="{'btn-clr': !roleBasicDetailsForm.invalid}">
        <span class="ml-2 mr-1 txt" style="color: white">Update </span>
      </button>
    </div>
  </div>

</div>