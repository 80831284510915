<div class="container-fluid">
    <div>
        <div class="d-flex m-3">
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{ leavePageTitle }}</h2>
        </div>

        <div class="card m-2"  style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="mt-2 ml-3 mb-4">
                <div class="row  mr-3 ml-3 mt-3">
                    <div class="col-sm-6 col-md-4" *ngIf="!isEditLeave">
                        <div class="fw-bold fieldName">
                            Branch
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;" >
                                <mat-select [placeholder]="'All Branches'"  [(ngModel)]="selected_branch_id" (ngModelChange)="getUsers()">
                                  <mat-option *ngFor="let branch of branchesList" [value]="branch.id">{{branch.name | titlecase  }}</mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4" *ngIf="!isEditLeave">
                        <div class="fw-bold fieldName">
                            Role
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;" >
                            <mat-select [placeholder]="'All Roles'"  [(ngModel)]="selected_role_id" (ngModelChange)="getUsers()">
                              <mat-option *ngFor="let role of roleList" [value]="role.id">{{role.name | titlecase }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="fw-bold fieldName">
                            User
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;" >
                            <mat-select [placeholder]="'Select User'" [(ngModel)]="selectedUserId" id="selectUserElementId" name="selectUserElementId" required #selectUserElementId="ngModel">
                              <mat-option *ngFor="let user of userList" [value]="user.id">{{user.full_name | titlecase }}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="selectUserElementId.invalid && selectUserElementId.touched && selectUserElementId.errors.required">
                                  User is required
                              </mat-error>
                          </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="fw-bold fieldName">
                            Select Start Date
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                                <input matInput readonly [matDatepicker]="picker" id="fromDateId" [(ngModel)]="fromDate"
                                    [placeholder]="'From Date'" name="fromDateId" required #fromDateId="ngModel" [min]="minStartDate">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="fromDateId.invalid && fromDateId.touched && fromDateId.errors.required">From Date is required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <div class="fw-bold fieldName">
                            Select End Date
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                                <input matInput readonly [matDatepicker]="picker1" [(ngModel)]="toDate" [disabled]="!fromDate"
                                    [placeholder]="'End Date'" id="toDateId" name="toDateId" required #toDateId="ngModel" [min]="fromDate">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                                <mat-error *ngIf="toDateId.invalid && toDateId.touched && toDateId.errors.required">To Date is required</mat-error>
                            </mat-form-field>

                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="fw-bold fieldName">
                            Leave Type
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                                    <mat-select [placeholder]="'Select Leave Type'" [(ngModel)]="selectedLeavetypeValue" id="leaveTypeId" name="leaveTypeId" required #leaveTypeId="ngModel">
                                        <mat-option *ngFor="let leave of LeaveType" [value]="leave.value">{{leave.viewValue}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="leaveTypeId.invalid && leaveTypeId.touched && leaveTypeId.errors.required">Leave Type is required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <div class="fw-bold fieldName">
                            Title
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field style="width: 90%;">
                                    <!-- <input id="title" name="title" class="form-control" style="background: #FFFFFF;" matInput
                                        placeholder="Title" required [(ngModel)]="title" #name="ngModel"> -->
                                    <input id="titleId" name="titleId" style="background: #FFFFFF;" matInput
                                        placeholder="Title" [(ngModel)]="title" required #titleId="ngModel"
                                        pattern="^[a-zA-Z][\w\s]*$" (keypress)="handleStartingSpaceTextInput($event, title)"
                                        >
                                    <mat-error *ngIf="titleId.invalid && titleId.touched && titleId.errors.required">Title is required</mat-error>
                                    <mat-error *ngIf="titleId.invalid && titleId.touched && titleId.errors.pattern">Title has invalid characters</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card m-2" style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="row  m-3 justify-content-center">
                <div class="col-10">
                    <div class="fw-bold fieldName" style="background: #000; color: #fff; padding: 10px; border-top-left-radius: 13px; border-top-right-radius: 13px;">
                        Description
                    </div>
                    <div class="">
                        <mat-form-field class="w-full">
                            <textarea rows="5" id="points" style="background: #FFFFFF;" matInput
                                placeholder="Description" [(ngModel)]="description"
                                (keypress)="handleStartingSpaceTextInput($event, description)"
                                ></textarea>
                        </mat-form-field>
                    </div>
                </div>
                
            </div>
        </div>

        <app-file-upload-manager #fileUploadComponent></app-file-upload-manager>
    </div>

    <div class="btnposition" id='rest-button'>
        <div class="bottomBtns mb-3 row" style="justify-content: space-between">
            
            <button class="ml-4 addBtn"  mat-flat-button (click)='reset()' *ngIf="!isEditLeave">
                <span class="ml-2 mr-1 txt">Reset</span>
            </button>

            <button class="ml-4 addBtn" mat-flat-button (click)="back()" *ngIf="isEditLeave">
                <span class="ml-2 mr-1 txt">Cancel</span>
            </button>
           
            <button *ngIf="!isEditLeave" class="ml-4 mr-4 addBtn" mat-flat-button (click)="createLeave()" [color]="'primary'"
                [ngClass]="{'btn-clr': !(fromDateId.invalid|| toDateId.invalid|| leaveTypeId.invalid || titleId.invalid || selectUserElementId.invalid)}"
                [disabled]="(fromDateId.invalid|| toDateId.invalid|| leaveTypeId.invalid || titleId.invalid || selectUserElementId.invalid)" >
                <span class="ml-2 mr-1 txt" style="color: white">Create</span>
            </button>

            <button *ngIf="isEditLeave" class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="updateLeave()"
            [ngClass]="{'btn-clr': !(fromDateId.invalid|| toDateId.invalid|| leaveTypeId.invalid || titleId.invalid || selectUserElementId.invalid)}"
            [disabled]="(fromDateId.invalid|| toDateId.invalid|| leaveTypeId.invalid || titleId.invalid || selectUserElementId.invalid)">
                <span class="ml-2 mr-1 txt" style="color: black">Update </span>
            </button>
        </div>
    </div>
</div>