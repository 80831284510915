import { Component,ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ViewUserComponent } from 'app/pages/Users/view-user/view-user.component';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator ,PageEvent} from '@angular/material/paginator';
import { ApiService } from 'app/api.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { IAttendance, IBranchActions, featureConstants } from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';
import { ExcelReportComponent } from '../excel-report/excel-report.component';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
export interface Element {
  BranchName: string;
  Location: string;
  Number: string;
  viewUser: number;
  IsRemoved:boolean;
  CreatedBy:string;
}

@Component({
  selector: 'app-attendance-list',
  templateUrl: './attendance-list.component.html',
  styleUrls: ['./attendance-list.component.scss']
})
export class AttendanceListComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['UserName', 'LastActiveTime','role','branch','Actions'];
  dataSource = new MatTableDataSource<Element>();
  sortedData=[];
  branches_list:[]
  IAttendance: IAttendance = {
    branchId: "",
    roleId:"",
  }
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  roleList=[]
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1
  isUserAllowedToViewRoles = this.userAllowedFeatures.indexOf(featureConstants.VIEW_ROLES) > -1
  isUserAllowedToViewAttendanceLog = this.userAllowedFeatures.indexOf(featureConstants.VIEW_ATTENDANCE_LOGS) > -1
  isUserAllowedToDownloadAttendanceReport = this.userAllowedFeatures.indexOf(featureConstants.ALLOWED_TO_ATTENDANCE_REPORT) > -1

  constructor( private router: Router,public dialog: MatDialog, public apiService: ApiService,private _liveAnnouncer: LiveAnnouncer, private toastr: ToastrService) {
   
  }
  ngOnInit(){
    this.filterattendance();
    if (this.isUserAllowedToViewBranches) {
      this.getBranches();
    }
    if (this.isUserAllowedToViewRoles) {
      this.filterattendance();
    }
    
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getBranches() {
    let data = {"filter_by_branch_active_status": true}
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        // this.dataSource.data = resp.data.branches;
        this.branches_list = resp.data.branches
        // this.toastr.success("Branches Loaded successfully");
      }
    })
  }
  getRoles(){
    const data = {
      filter_by_role_active_status:true,
     
    }
    this.apiService.getRolesByFilter(data).subscribe((resp)=>{
      this.roleList =resp.data.roles
    })
  }
  filterattendance() {
    let data = {}
      data = {
        filter_by_branch_id: this.IAttendance.branchId,
        filter_by_role_id: this.IAttendance.roleId,
        offset:this.bOffset,
        limit:this.bLimit
      }
   
    this.apiService.filterUserAttendance(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data=resp.data.users_attendance;
        this.dataSource.data.forEach((element: any) => {
          var branchNames = [];
          this.userBranchNames = []
          element.branches.forEach(ele => {
            branchNames.push(ele.name)
            this.userBranchNames = branchNames
          });
          element.branchNames = branchNames;
        });
      this.totalCount=resp.data.total_users_count;
      }
    });
  }
userBranchNames=[]
getUserAttendance(){
  this.apiService.getUserAttendance().subscribe((resp)=>{
    if (resp.status == "success") {
      // this.dataSource = new MatTableDataSource(resp.data.users_attendance);
      this.dataSource.data = resp.data.users_attendance;
      this.dataSource.data.forEach((element: any) => {
        var branchNames = [];
        this.userBranchNames = [];
        element.branches.forEach(ele => {
          branchNames.push(ele.name)
          this.userBranchNames = branchNames
        });
        element.branchNames = branchNames;
      });
      // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    // this.dataSource.data = resp.data.users_attendance;
 
  },
  (err) => {
    this.toastr.error(err.error.message)
  })
}

  viewUserAttendance(row) {
  sessionStorage.setItem('userAttendanceForUserId', row)
  this.router.navigateByUrl('Admin/view-user-attendance');
  // this.router.navigate(['Admin/view-user-attendance', { user_id: row }]);
}
downloadReport() {
  let dialogRef = this.dialog.open(ExcelReportComponent, {
    data: {
      // Data: row,
    },
    // height: '90%',
    width: '50%',
  });
}
announceSortChange(sortState: Sort) {
  if (sortState.direction) {
    this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
  } else {
    this._liveAnnouncer.announce('Sorting cleared');
  }
}

onPaginateChange(event: PageEvent): void {

  this.bLimit = event.pageSize;
  this.bOffset = (event.pageIndex) * event.pageSize;
  this.pageIndex = event.pageIndex;


  this.filterattendance(); 
}

onFilterAttendance(){
  this.pageIndex=0;
  this.bOffset=0;

  this.filterattendance()

}
  
}


