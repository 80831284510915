import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import {   PlanTypeActions, PlansActions, featureConstants } from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';
import { CreatePlanTypesComponent } from '../../plan-types/createPlanTypes/create-plan-types.component';
import { ViewAddOnPlanComponent } from '../../add-on-plan/view-add-on-plan/view-add-on-plan.component';
import { ViewAddOnTypesComponent } from '../view-add-on-types/view-add-on-types.component';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';


export interface Element {
  is_removed: boolean;
  id: String;
}

@Component({
  selector: 'app-get-add-on-types',
  templateUrl: './get-add-on-types.component.html',
  styleUrls: ['./get-add-on-types.component.scss']
})

export class GetAddOnTypesComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['AddOnName', 'CreatedBy', 'CreatedDate' ,'Actions'];
  dataSource = new MatTableDataSource<Element>();
  branches_list = [];
  AddonTypeDetails=[];
  PlanStatusType = [
    // { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }

  ]
  IDPlanType: PlanTypeActions = {
    planTypeActive: "",
    planTypeId: "",
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewAddonTypes = this.userAllowedFeatures.indexOf(featureConstants.VIEW_ADDON_PLANS) > -1
  isUserAllowedToCreateAddonTypes = this.userAllowedFeatures.indexOf(featureConstants.CREATE_ADDON_PLANS) > -1
  isUserAllowedToEditAddonTypes = this.userAllowedFeatures.indexOf(featureConstants.EDIT_ADDON_PLANS) > -1
  isUserAllowedToDeactivateAddonTypes = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_ADDON_PLANS) > -1
  isUserAllowedToReactivateAddonTypes = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_ADDON_PLANS) > -1

  constructor(private router: Router, public dialog: MatDialog, public apiService: ApiService, private toastr: ToastrService) {
    this.getScreenSize();
  }
  ngOnInit() {

    this.getAddOnTypes()
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getAddOnTypes(){
    this.apiService.getAddOnTypesDetails().subscribe((resp)=>{
      if (resp.status == "success") {
        this.dataSource.data = resp.data.add_on_plan_types;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  

  getAddOnTypeById(row) {
    this.AddonTypeDetails = [];
    this.apiService.getAddOnTypeById(row.id).subscribe((resp) => {
      if (resp.status == "success") {
        this.AddonTypeDetails.push(resp.data.add_on_plan_type);
        this.viewUser(this.AddonTypeDetails)
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  viewWidth:any
  viewUser(data) {
    if(this.scrWidth < 1450){
      this.viewWidth='80%'
    }else{
      this.viewWidth='50%'
    }
    let dialogRef = this.dialog.open(ViewAddOnTypesComponent, {
      data: {
        data: data,
      },
      // height: auto,
      width: this.viewWidth,
    });
  }
  
  filterPlanType(value) {
    let data = {}
    // if (this.IBranch.branchId) {
    data = {
      filter_by_plan_type_active_status: this.IDPlanType.planTypeActive,

    }

    this.apiService.getAddOnTypesByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data =  resp.data.add_on_plan_types;
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }
  
  scrHeight: number;
  scrWidth: number;
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // this.getAd(); // This is get ad on every time screen is resized
  }

  createAddOn() {
    sessionStorage.removeItem('editAddonTypeId')
    this.router.navigateByUrl('Admin/create-add-on-type');
  }

  editAddOnType(row) {
    sessionStorage.setItem('editAddonTypeId', row.id)
    this.router.navigateByUrl('Admin/edit-add-on-type');
   
    // this.router.navigate(['Admin/create-add-on-types', { addon_plan_type_id:row.id }]);
  
  }
  deactivatePlanType(addon_plan_type_id: String) {
    this.apiService.deactivateAddOnType(addon_plan_type_id = addon_plan_type_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          AddOntype => {
            if (AddOntype.id == addon_plan_type_id)
            AddOntype.is_removed = true
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  reactivatePlanType(addon_plan_type_id : String) {
    this.apiService.reactivateAddOnType(addon_plan_type_id  = addon_plan_type_id ).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          AddOntype => {
            if (AddOntype.id == addon_plan_type_id ){
              AddOntype.is_removed = false
            }
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

}

