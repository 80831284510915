<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">
        <div class="row mt-8 justify-content-between">
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
              <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">COUPON</h1>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4  mb-2 d-flex justify-content-end" *ngIf="isUserAllowedToCreateCoupons">
                <button class="ml-4 addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
                (click)="createCoupon()">
                <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 txt" style="color: white">Create Coupon</span>
            </button>
            </div>
            
            </div>


            <div class="row">
                
                <div class="col-sm-12 col-md-4 col-lg-2">
                    <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                        <mat-select [placeholder]="'All Coupon Types'" [(ngModel)]="ICoupon.couponTypeId" (selectionChange)="onFilterCoupons()">
                            <mat-option value="">All Coupon Types</mat-option>
                            <mat-option *ngFor="let coupon_type of couponTypesList" [value]="coupon_type.id">{{coupon_type.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-2">
                    <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                        <mat-select [placeholder]="'All'" [(ngModel)]="ICoupon.status" (selectionChange)="onFilterCoupons()">
                            <mat-option value="">All</mat-option>
                            <mat-option *ngFor="let coupon of couponStatus" [value]="coupon.value">{{coupon.viewValue}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div> 
                <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
                    <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;" [subscriptSizing]="'dynamic'">
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                        <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'" [placeholder]="'Search Coupon Name'">
                      </mat-form-field>
                 </div>
                      
            </div>
                   
    </div>
    <div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef >Name</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.name}} </td>
            </ng-container>

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef >Coupon Code</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.coupon_code}} </td>
            </ng-container>

            <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef >Discount</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.discount_percentage || row.discount_price}} </td>
            </ng-container>

            <ng-container matColumnDef="couponUsageCount">
                <th mat-header-cell *matHeaderCellDef >Usage Count</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.coupon_usage_count}}
                </td>
            </ng-container>

            <ng-container matColumnDef="coupontype">
                <th mat-header-cell *matHeaderCellDef >Coupon Type</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.coupon_type.name}} </td>
            </ng-container>

            <ng-container matColumnDef="validfrom">
                <th mat-header-cell *matHeaderCellDef >Valid From</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.valid_from  | dateFormat}} </td>
            </ng-container>

            <ng-container matColumnDef="validto">
                <th mat-header-cell *matHeaderCellDef >Valid To</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.valid_to  | dateFormat}} </td>
            </ng-container>

            <ng-container matColumnDef="CreatedBy">
                <th mat-header-cell *matHeaderCellDef >Created By</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                    {{row.created_by?.full_name}}<span *ngIf="row.created_by?.role">({{row.created_by.role.name}})</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="CreatedDate">
                <th mat-header-cell *matHeaderCellDef >Created Date</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.created_at  | dateFormat}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                    <button mat-button [matMenuTriggerFor]="menu"
                        [disabled]="!isUserAllowedToEditCoupons && !isUserAllowedToDeactivateCoupons && !isUserAllowedToReactivateCoupons"><mat-icon
                            style="color:#0038FF;font-size: 30px;"
                            [disabled]="(isUserAllowedToEditCoupons && isUserAllowedToDeactivateCoupons && isUserAllowedToReactivateCoupons)"
                            svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="viewCoupon(row.id)">View</button>
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToEditCoupons"
                            (click)="updateCoupon(row)">Edit</button>
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToDeactivateCoupons"
                            (click)="verifyDeactivate(row.id)">Deactivate</button>
                        <button mat-menu-item *ngIf="row.is_removed && isUserAllowedToReactivateCoupons"
                            (click)="reactivateCoupon(row.id)">Reactivate</button>
                    </mat-menu>
                  
                  </td>
                  
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <div>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">
                        <h3 style="display: flex;justify-content: center; margin:10%;">
                            No Records Found
                        </h3>
                    </td>
                </tr>
            </div>

        </table>
    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
    <!-- <mat-paginator style="display:flex;justify-content: end;" [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
</div>
