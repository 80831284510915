export function getuserAllowedFeatures(){
     return localStorage.getItem('userAllowedFeatures').split(',')
}

export function setuserAllowedFeatures(userAllowedFeatures) {
    localStorage.setItem('userAllowedFeatures', userAllowedFeatures)
}

export function getOrCreateDeviceId() {
    let deviceId = localStorage.getItem('deviceId')
    if (deviceId) {
        return deviceId
    }
    else {
        deviceId = Math.random().toString(18).substring(5); + Date.now().toString(36).substring(5) + Math.random().toString(18).substring(5);
        localStorage.setItem('deviceId', deviceId)
        return deviceId
    }
}

export function handleStartingSpaceTextInput(event, value) {
    if (event.which === 32 && !value.length)
      event.preventDefault();
}