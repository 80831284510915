import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { MetaService, roles, featureConstants } from 'app/meta.service';
import * as _moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2';

import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/core/auth/auth.service';
import { OtpConformationComponent } from 'app/pages/Users/otp-conformation/otp-conformation.component';
import { MY_FORMATS } from 'app/app.component';
import { forEach } from 'lodash';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';

const moment = _moment;
export interface State {
  id: string;
  name: string;
}
export interface cities {
  id: string;
  name: string;
}

@Component({
  selector: 'app-create-plan-sub-type',
  templateUrl: './create-plan-sub-type.component.html',
  styleUrls: ['./create-plan-sub-type.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class CreatePlanSubTypeComponent {

  handleStartingSpaceTextInput = handleStartingSpaceTextInput

  PlanTypeList = [];
  benefitPoints:string = null;
  planSubTypeBenefitPoints =[];
  plan_id_name_aliases = []
  selectedPlanTypeId = []
  PlanSubTypeDetails=[];
  SubType:string;
  plan_subtype_id: string;
  isUpdate: boolean = false;
  title: string = "CREATE PLAN SUB TYPE";

  constructor(private router: Router, private meta: MetaService, public dialog: MatDialog, private route: ActivatedRoute, public apiService: ApiService, public _authService: AuthService, private datePipe: DatePipe, private _formBuilder: FormBuilder, private toastr: ToastrService,) {
    // this.route.params.subscribe((params) => {
    //   this.plan_subtype_id = params['plan_subtype_id'];
    // });
    this.plan_subtype_id = sessionStorage.getItem('editPlanSubTypeId')
  }
  ngOnInit() {
    this.buildForm();
    if (this.plan_subtype_id != null) {
      this.getPlanSubTypeById();
      this.isUpdate = true;
      this.title = "EDIT PLAN SUB TYPE"
      // this.apiService.getRoleById(this.role_id).subscribe((resp)=>{
      // })
    } else {
      this.getPlanType();
      // this.filterRole();
      // this.getFeatures();
    }
  }
  getPlanType() {
    const data = {
      filter_by_plan_type_active_status: true,
    }
    this.apiService.getPlanTypeByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.PlanTypeList = resp.data.plan_types;
        this.PlanTypeList.forEach(plan_obj => {
          this.plan_id_name_aliases.push({"id": plan_obj.id, "name": plan_obj.name})
        })
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }
  back() {
    this.router.navigateByUrl('Admin/plan-sub-types');
  }

  public buildForm() {
    let name = null
    if(this.plan_subtype_id){
    }
  }

  Addplantype() {
    if(this.benefitPoints){
      this.planSubTypeBenefitPoints.push(this.benefitPoints)
      this.benefitPoints = null
    }else{
      alert("Enter value in benefit points")
    }
    
  }

  createPlanSubTypes() {
    let payload: any = {

    }
    let plan_type_id = this.selectedPlanTypeId
    payload = {
      
      "name": this.SubType,
      all_benefits_points : this.planSubTypeBenefitPoints
  }

    this.apiService.createPlanSubType(payload,plan_type_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.router.navigateByUrl('Admin/plan-sub-types');

      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  getPlanSubTypeById() {
    this.PlanSubTypeDetails = [];
    this.apiService.getPlanSubTypeById(this.plan_subtype_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.PlanSubTypeDetails.push(resp.data.plan_sub_type);
        this.PlanTypeList.push(resp.data.plan_sub_type.parent_plan_type)
        this.SubType = resp.data.plan_sub_type.name
        this.planSubTypeBenefitPoints = resp.data.plan_sub_type.all_benefits_points
        this.selectedPlanTypeId = this.PlanTypeList[0].id
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  UpdatePlanSubType() {
    let payload = {
      name: this.SubType,
      all_benefits_points: this.planSubTypeBenefitPoints

    }
    
    this.apiService.updatePlanSubType(payload,this.plan_subtype_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.router.navigateByUrl('Admin/plan-sub-types');

      }
     
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  deleteBenefitPoint(benefitPoint) {
    let benefitPointIndex = this.planSubTypeBenefitPoints.findIndex(
      existingBenefitPoint => existingBenefitPoint == benefitPoint
    )
    this.planSubTypeBenefitPoints.splice(benefitPointIndex, 1)
  }
  resetSubPlan() {
    this.selectedPlanTypeId = [];
    this.SubType = '';
    this.benefitPoints='';
    this.planSubTypeBenefitPoints=[];
  }
}