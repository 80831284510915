<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
  <div class="row">
    <div class="row mt-8 justify-content-between">
      <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
        <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">
          PAYROLL&nbsp;REPORTS     
      </h1>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4  mb-2 d-flex justify-content-end">
      <button class="addBtn m-1" style="width: 170px" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
      (click)="openPayrollReportPopUp()" *ngIf="isUserAllowedToViewPayrollReport">
      <mat-icon class="white-icon" [svgIcon]="'mat_outline:file_download'"></mat-icon>
      <span class="ml-2 mr-1 txt" style="color: white;">Payroll Report</span>
    </button>


      </div>
      </div>  



      <div class="row">
        
           
              <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2" *ngIf="isUserAllowedToViewBranches">
                    <mat-form-field class="flex-auto gt-xs:pr-3 w-full"  >
                    <mat-select [placeholder]="'All Branches'"  [(ngModel)]="selectedBranchIds" (ngModelChange)="getUsers(); getPayrollReport();" multiple>
                    <mat-checkbox class="" [checked]="allBranchesChecked" [indeterminate]="someBranchesChecked()" (change)="setAllBranchesChecked($event); getPayrollReport();">
                          Select All
                    </mat-checkbox>
                    <mat-option *ngFor="let branch of branchIdNameList" [value]="branch.id">{{branch.name | titlecase  }}</mat-option>
                    </mat-select>
                    </mat-form-field>
              </div>
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2" *ngIf="isUserAllowedToViewRoles">
                   <mat-form-field class="flex-auto gt-xs:pr-3 w-full"  >
                     <mat-select [placeholder]="'All Roles'"  [(ngModel)]="selectedroleIds" (ngModelChange)="getUsers(); getPayrollReport();" multiple>
                        <mat-checkbox class="" [checked]="allRolesChecked" [indeterminate]="someRolesChecked()" (change)="setAllRolesChecked($event); getPayrollReport()">
                             Select All
                            </mat-checkbox>
                                  <mat-option *ngFor="let role of roleIdNameList" [value]="role.id">{{role.name | titlecase }}</mat-option>
                        </mat-select>
                          </mat-form-field>
                </div>
    <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 gk" >
      <mat-form-field class="flex-auto gt-xs:pr-3 w-full" >
        <mat-select [placeholder]="'All Users'" [(ngModel)]="selectedUserIds" (ngModelChange)="getPayrollReport()" multiple >
          <mat-checkbox class="" [checked]="allUsersChecked" [indeterminate]="someUsersChecked()" (change)="setAllUsersChecked($event); getPayrollReport()">
              Select All
          </mat-checkbox>
          <mat-option *ngFor="let user of userIdNameList" [value]="user.id">{{user.full_name | titlecase }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-2" >
      <mat-form-field class="flex-auto gt-xs:pr-3 w-full">
       <input matInput [matDatepicker]="picker" [placeholder]="'Payroll Date'" [(ngModel)]="payrollDate" (ngModelChange)="getPayrollReport()">
       <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
       <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
   </div>
   <div class="col-sm-12 col-md-4 col-lg-2 mb-2">

    <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;" [subscriptSizing]="'dynamic'">
      <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
      <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'" [placeholder]="'Search User Name'">
    </mat-form-field>


    </div>
      
    
  </div>




  <div class="mat-elevation-z8 mt-2 overflow-auto d-flex flex-column">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef >User Name</th>
        <td mat-cell *matCellDef="let row"> {{row.user | titlecase}} </td>
      </ng-container>
      <ng-container matColumnDef="activeStatus">
        <th mat-header-cell *matHeaderCellDef >Active Status</th>
        <td mat-cell *matCellDef="let row"> {{row.active_status}}</td>
      </ng-container>

      <ng-container matColumnDef="dutyTimePay">
        <th mat-header-cell *matHeaderCellDef >DutyTime Pay</th>
        <td mat-cell *matCellDef="let row"> {{row.duty_time_pay}}</td>
      </ng-container>
      <ng-container matColumnDef="overTimePay">
        <th mat-header-cell *matHeaderCellDef >OverTime Pay</th>
        <td mat-cell *matCellDef="let row"> {{row.over_time_pay}}</td>
      </ng-container>
      <ng-container matColumnDef="sessionsPay">
        <th mat-header-cell *matHeaderCellDef >Sessions Pay&nbsp;(Count)</th>
        <td mat-cell *matCellDef="let row"> {{row.sessions_pay}}&nbsp;({{row.sessions_count}})</td>
      </ng-container>
      <ng-container matColumnDef="paidLeavesPay">
        <th mat-header-cell *matHeaderCellDef >Paid Leaves Pay</th>
        <td mat-cell *matCellDef="let row"> {{row.paid_leaves_pay}}</td>
      </ng-container>
      <ng-container matColumnDef="branchHolidaysPay">
        <th mat-header-cell *matHeaderCellDef >Branch Holidays Pay</th>
        <td mat-cell *matCellDef="let row"> {{row.branch_holidays_pay}}</td>
      </ng-container>
      <ng-container matColumnDef="dayOffsPay">
        <th mat-header-cell *matHeaderCellDef >DayOffs Pay</th>
        <td mat-cell *matCellDef="let row"> {{row.paid_day_offs_pay}}</td>
      </ng-container>
      <ng-container matColumnDef="totalPay">
        <th mat-header-cell *matHeaderCellDef >Total Pay</th>
        <td mat-cell *matCellDef="let row"> {{row.total_pay}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <!-- Row shown when there is no matching data. -->
      <div>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="12">
            <h3 style="display: flex;justify-content: center; margin:10%;">
              No Records Found
            </h3>
          </td>
        </tr>
      </div>
    </table>

  </div>
  <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>