import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { Component } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {dateFormat: "longDate"} }
  ],
})
export class AppComponent
{
  public showLoader: boolean = false;

    deviceInfo: string;
    /**
     * Constructor
     */
    constructor()
    {
        
    }
    ngOnInit() {
        const userAgent = navigator.userAgent;
        const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
        const isTablet = /iPad/i.test(userAgent);
        const isDesktop = !isMobile && !isTablet;
    
        if (isMobile) {
          this.deviceInfo = 'Mobile device detected';
       
        } else if (isTablet) {
          this.deviceInfo = 'Tablet device detected';
        } else if (isDesktop) {
          this.deviceInfo = 'Desktop device detected';
        } else {
          this.deviceInfo = 'Unknown device';
        }
      }
}
