<div class="flex flex-col flex-auto min-w-0">

    <!-- Header -->
    <div class="flex flex-col shadow bg-card">

        <!-- Cover image -->
        <div>
            <img
                class="h-40 lg:h-80 object-cover"
                src="assets/profilebg.jpg"
                alt="Cover image">
        </div>

        <!-- User info -->
        <div class="flex flex-col flex-0 lg:flex-row items-center max-w-5xl w-full mx-auto px-8 lg:h-18 bg-card">

            <!-- Avatar -->
            <div class="-mt-26 lg:-mt-22 rounded-full pos-rel">
              <div class="hover-effect rounded-full" onclick="document.getElementById('getFile').click()">
                <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:camera'"></mat-icon>
              </div>
                <img 
                class="w-32 h-32 rounded-full ring-4 ring-bg-card profile-pic"
                [src]="displayProfilePicUrl"
                alt="User Profile Pic"
                >
                <input id="getFile" style="display: None;" type="file" (change)="onSelectFile($event)">
            </div>

            <!-- Details -->
            <div class="flex flex-col items-center lg:items-start mt-4 lg:mt-0 lg:ml-8">
                <div class="text-lg font-bold leading-none">{{fullName}}</div>
                <div class="text-secondary"></div>
            </div>
        </div>

    </div>

    <!-- Main -->
    <div class="flex flex-auto justify-center w-full max-w-5xl mx-auto p-6 sm:p-8" >
        <div class="col-12 row d-flex" [formGroup]="updateUserForm">

          <div class="col-12 d-flex justify-content-end mr-2">
            <button class="addBtn" mat-flat-button [color]="'primary'" (click)="allowEdit()" [disabled]="canEditProfile"
              [ngClass]="{'btn-clr': !canEditProfile}">
              <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:pencil'"></mat-icon>
              <span class="ml-1 txt" style="color: white">Edit</span>
            </button>
          </div>

        <div class="col-4">
          <div class="">
            <div class="fw-bold">
              Name
            </div>
            
            <mat-form-field class="w-full">
                <input  id="fullName" class="bg-white" formControlName="fullname" matInput placeholder="Name"  required pattern="^[a-zA-Z][\w\s]*$">
                <mat-error *ngIf="updateUserForm.get('fullname').hasError('required')">
                  Full Name is required
              </mat-error>
              <mat-error *ngIf="updateUserForm.get('fullname').hasError('pattern')">
                Full Name has invalid characters
            </mat-error>
            </mat-form-field>
        </div>
          </div>
       
          <div class="col-4">
            <div class="">
              <div class="fw-bold">
                Email
              </div>
              <mat-form-field class="w-full">
                  <input  id="email" formControlName="email" class="bg-white" readonly matInput placeholder="Email"
                  >
              </mat-form-field>
          </div>
            </div>
            <div class="col-4">
              <div class="">
                <div class="fw-bold">
                  Number
                </div>
                <mat-form-field class="w-full">
                    <input  id="phoneNumber"  formControlName="phoneNumber" readonly class="bg-white" matInput placeholder="Mobile Number"
                    >
                </mat-form-field>
            </div>
              </div>

              <div class="col-4">
                <div class="fw-bold">
                    Gender
                </div>
                <div class="">
                    <div class="">
                        <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;">
                            <mat-select [placeholder]="'Select Gender'" formControlName="gender">
                                <mat-option value="MALE">MALE</mat-option>
                                <mat-option value="FEMALE">FEMALE</mat-option>
                                <mat-option value="OTHER">OTHER</mat-option>
                            </mat-select>

                        </mat-form-field>
                    </div>
                </div>
            </div>
              <div class="col-4">
                  <div class="">
                    <div class="fw-bold">
                      Height(Cms)
                    </div>
                    <mat-form-field class="w-full">
                        <input  id="height" type="number" formControlName="height" class="bg-white" matInput  placeholder="Enter Height"
                        >
                    </mat-form-field>
                </div>
              </div>
              <div class="col-4">
                <div class="">
                  <div class="fw-bold">
                    Weight(Kgs)
                  </div>
                  <mat-form-field class="w-full">
                      <input  id="weight" type="number" formControlName="weight" class="bg-white" matInput  placeholder="Enter Weight"
                      >
                  </mat-form-field>
              </div>
            </div>

            <div class="col-4">
              <div class="">
                <div class="fw-bold">
                  Designation
                </div>
                <mat-form-field class="w-full">
                    <input  id="designation"  formControlName="designation" class="bg-white" matInput  placeholder="Enter designation"
                    >
                </mat-form-field>
              </div>
            </div>

            <div class="col-4">
              <div class="fw-bold">
                  Date of Birth
              </div>
              <div class="">
                  <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;">
                      <input matInput readonly [matDatepicker]="picker" [max]="maxDate"
                          formControlName="dob" [placeholder]="'Select DOB'">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker style="color:#00FF00"></mat-datepicker>
                  </mat-form-field>

              </div>
          </div>

          <div class="col-4">
            <div class="fw-bold">
                Date of joining
            </div>
            <div class="">
                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;">
                    <input matInput readonly [matDatepicker]="dojPicker"
                        formControlName="doj" [placeholder]="'Select DOJ'">
                    <mat-datepicker-toggle matSuffix [for]="dojPicker"></mat-datepicker-toggle>
                    <mat-datepicker #dojPicker style="color:#00FF00"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex justify-content-end mr-2">

          <button class="ml-4 addBtn" mat-flat-button (click)="cancelEdit()" [disabled]="!canEditProfile">
            <span class="ml-2 mr-1 txt">Cancel</span>
          </button>

          <button class="ml-2 addBtn" mat-flat-button [color]="'primary'" [disabled]="!canEditProfile || updateUserForm.invalid" (click)="saveProfile()"
            [ngClass]="{'btn-clr': canEditProfile && updateUserForm.valid}">
            <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="txt" style="color: white">Save</span>
          </button>
        </div>

    </div>

    </div>

</div>
