import { DatePipe, Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { MetaService, roles, featureConstants } from 'app/meta.service';
import * as _moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/core/auth/auth.service';
import { OtpConformationComponent } from 'app/pages/Users/otp-conformation/otp-conformation.component';





const moment = _moment;
export interface State {
  id: string;
  name: string;
}
export interface cities {
  id: string;
  name: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-create-user-payable',
  templateUrl: './create-user-payable.component.html',
  styleUrls: ['./create-user-payable.component.scss']
})

export class CreateUserPayableComponent {

  newDate: any;
  minDate: any;
  fromDate: any;
  isUpdate: boolean = false;
  toDate = null;
  monthlypay;
  sessionlypay;
  payableForUserId = null;
  payableId = null

  payableDetails;
  Overtime = null;
  OverTime = [
    // { value: '', viewValue: 'All' },
    { value: true, viewValue: 'YES' },
    { value: false, viewValue: 'NO' }

  ]
  title: string = "CREATE PAYABLE";
  

  constructor(private router: Router, private meta: MetaService, public dialog: MatDialog, private route: ActivatedRoute, public apiService: ApiService, public _authService: AuthService, private datePipe: DatePipe, private _formBuilder: FormBuilder, private toastr: ToastrService, private _location: Location) {
  }
  ngOnInit() {
    this.newDate = new Date();
    this.newDate = moment(new Date(this.newDate)).format("YYYY-MM-DD").toString();


    this.payableId = sessionStorage.getItem('payableId');
    if (this.payableId) {
      this.isUpdate = true
      this.title = "EDIT PAYABLE"
      this.payableForUserId = sessionStorage.getItem("EditPayableForUserId")
      this.getUserPayableById()
    }
    else {
      this.isUpdate = false
      this.payableForUserId = sessionStorage.getItem("CreatePayableForUserId")

    }
  }
  back() {
    sessionStorage.removeItem('payableId');
    sessionStorage.removeItem('CreatePayableForUserId');
    sessionStorage.removeItem('EditPayableForUserId');
    this._location.back();
  }

  createPlan() {
    this.fromDate = this.fromDate ? moment(new Date(this.fromDate)).format("YYYY-MM-DD").toString(): null;
    this.toDate = this.toDate ? moment(new Date(this.toDate)).format("YYYY-MM-DD").toString(): null;
    
    let requestData = {  
      user_id : this.payableForUserId,
      start_date: this.fromDate,
      end_date: this.toDate,
      allowed_for_overtime: this.Overtime,
      monthly_pay_in_rupees: this.monthlypay,
      session_pay_in_rupees: this.sessionlypay,
    };
    this.apiService.createPayable(requestData).subscribe(
      (resp) => {
        if (resp.status == 'success') {
          this.back();
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  updatePlan() {
    this.fromDate = this.fromDate ? moment(new Date(this.fromDate)).format("YYYY-MM-DD").toString(): null;
    this.toDate = this.toDate ? moment(new Date(this.toDate)).format("YYYY-MM-DD").toString(): null;
    
    let requestData = {
      user_id : this.payableForUserId,
      start_date: this.fromDate,
      end_date: this.toDate,
      allowed_for_overtime: this.Overtime,
      monthly_pay_in_rupees: this.monthlypay,
      session_pay_in_rupees: this.sessionlypay,
    };
    this.apiService.updatePayable(requestData, this.payableId).subscribe(
      (resp) => {
        if (resp.status == 'success') {
          this.back();
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  getUserPayableById() {
    this.payableDetails = [];
    this.apiService.getUserPayableListById(this.payableId).subscribe((resp) => {
      if (resp.status == "success") {
        this.payableDetails.push(resp.data);
        this.sessionlypay = resp.data.session_pay_in_rupees
        this.monthlypay = resp.data.monthly_pay_in_rupees
        this.fromDate = resp.data.start_date
        this.toDate = resp.data.end_date
        this.Overtime = resp.data.allowed_for_overtime
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  resetUserPayable() {
    this.fromDate = null;
    this.toDate = null;
    this.monthlypay = null;
    this.sessionlypay = null;
    this.OverTime = null;
  }
}
