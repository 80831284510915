import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { CreateLevelComponent } from '../create-level/create-level.component';
export interface Element {
  BranchName: string;
  Location: string;
  Number: string;
  viewUser: number;
  IsRemoved:boolean;
  CreatedBy:string;
}
const ELEMENT_DATA: Element[] = [
  {BranchName: 'Jubilee Hills Branch', Location: 'Hyderabad', IsRemoved: false,Number:'+91-9542999999',viewUser:120, CreatedBy: 'ADMIN'},
  {BranchName: 'Gachibowli Branch', Location: 'Hyderabad', IsRemoved: false,Number:'+91-9542999999',viewUser:90, CreatedBy: 'ADMIN'},
 
];
@Component({
  selector: 'app-level-list-view',
  templateUrl: './level-list-view.component.html',
  styleUrls: ['./level-list-view.component.scss']
})
export class LevelListViewComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['PlanLevel','Actions'];
  dataSource = new MatTableDataSource<Element>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor( private router: Router,public dialog: MatDialog,public apiService: ApiService) {
    
  }
  ngOnInit(){
    this.getLevels()
  }
  getLevels(){
    this.apiService.getLevels().subscribe((resp)=>{
      this.dataSource.data = resp.data.levels
    })
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
getBranches(){
}

createLevel(){
 let dialogRef = this.dialog.open(CreateLevelComponent,{
    height: 'auto',
    width: '30%',
  });
  dialogRef.afterClosed().subscribe(result => {
    this.getLevels()
  });
}
editLevel(row){
  let dialogRef = this.dialog.open(CreateLevelComponent,{
    data: {
      id:row.id,
      data: row,
    },
    height: 'auto',
    width: '30%',
  });
  dialogRef.afterClosed().subscribe(result => {
    this.getLevels()
  });
  
}


viewUser() {
  // let dialogRef = this.dialog.open(,{
  //   height: '90%',
  //   width: '70%',
  // });
}

}
