
import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { MetaService } from 'app/meta.service';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/core/auth/auth.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import moment from 'moment';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';

@Component({
  selector: 'app-create-coupon',
  templateUrl: './create-coupon.component.html',
  styleUrls: ['./create-coupon.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})

export class CreateCouponComponent {
  
  handleStartingSpaceTextInput = handleStartingSpaceTextInput

  couponName = null;
  CouponTypeList = [];
  Discount_Price = null;
  Discount_Percentage = null;
  Minimum_Purchase_Price = null;
  customerType = null;
  couponType;
  Maximum_Usages = null;
  newDate: any;
  minDate: any;
  fromDate = null;
  toDate = null;
  coupon_id: string;

  termPoint = null
  termsAndConditions = []
  

  CustomersType = [
    // { value: '', viewValue: 'All' },
    { value: 'ALL_CUSTOMERS', viewValue: 'ALL_CUSTOMERS' },
    { value: 'NEW_CUSTOMERS', viewValue: 'NEW_CUSTOMERS' },
    { value: 'EXISTING_CUSTOMERS', viewValue: 'EXISTING_CUSTOMERS' }
  ]



  isUpdate: boolean = false;
  title: string = 'CREATE COUPON';

  constructor(
    private router: Router,
    private meta: MetaService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public apiService: ApiService,
    public _authService: AuthService,
    private datePipe: DatePipe,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    // this.route.params.subscribe((params) => {
    //   this.coupon_id = params['coupon_id'];
    // });
    this.coupon_id = sessionStorage.getItem('editCouponId')
  }

  ngOnInit() {
    this.getCouponType();

    this.newDate = new Date();
    this.newDate = moment(new Date(this.newDate)).format("YYYY-MM-DD").toString();
    if (this.coupon_id != null) {
      this.getCouponById();
     this.isUpdate = true;
     this.title = "EDIT COUPON"
    
   } else {
    
   }

  }

  getCouponById() {
    this.apiService.getCouponById(this.coupon_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.couponName = resp.data.coupon.name;
        this.Discount_Percentage = resp.data.coupon.discount_percentage;
        this.Discount_Price = resp.data.coupon.discount_price;
        this.Minimum_Purchase_Price = resp.data.coupon.min_purchase_price;
        this.Maximum_Usages = resp.data.coupon.max_usages;
        this.fromDate = resp.data.coupon.valid_from;
        this.toDate = resp.data.coupon.valid_to;
        this.customerType = resp.data.coupon.applicable_to;
        this.couponType = resp.data.coupon.coupon_type.id;
        this.termsAndConditions = resp.data.coupon.terms_and_conditions
      }},
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  back() {
    this.router.navigateByUrl('Admin/coupons');
  }

  getCouponType() {
    const data = {
      filter_by_active_status: true,
    };
    this.apiService.getCouponTypeByFilter(data).subscribe(
      (resp) => {
        if (resp.status == 'success') {
          this.CouponTypeList = resp.data.coupon_types;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }
  createCoupon() {
    if (this.fromDate) {
    this.fromDate = moment(new Date(this.fromDate)).format("YYYY-MM-DD").toString();
    }
    if (this.toDate) {
    this.toDate = moment(new Date(this.toDate)).format("YYYY-MM-DD").toString();
    }
    let requestData = {
      name: this.couponName,
      terms_and_conditions: this.termsAndConditions,
      coupon_type_id: this.couponType,
      valid_from: this.fromDate,
      valid_to: this.toDate,
      discount_percentage: this.Discount_Percentage || null,
      discount_price: this.Discount_Price || null,
      min_purchase_price: this.Minimum_Purchase_Price,
      applicable_to: this.customerType,
      max_usages: this.Maximum_Usages
    }
    this.apiService.createCoupon(requestData).subscribe(
      (resp) => {
          if (resp.status == 'success') {
              this.router.navigateByUrl('Admin/coupons');
          }
      },
      (err) => {
          this.toastr.error(err.error.message);
      }
  );
  }
  updateCoupon() {
    if (this.fromDate) {
      this.fromDate = moment(new Date(this.fromDate)).format("YYYY-MM-DD").toString();
      }
    if (this.toDate) {
    this.toDate = moment(new Date(this.toDate)).format("YYYY-MM-DD").toString();
    }
    let requestData = {
      name: this.couponName,
      terms_and_conditions: this.termsAndConditions,
      coupon_type_id: this.couponType,
      valid_from: this.fromDate,
      valid_to: this.toDate,
      discount_percentage: this.Discount_Percentage,
      discount_price: this.Discount_Price,
      min_purchase_price: this.Minimum_Purchase_Price,
      applicable_to: this.customerType,
      max_usages: this.Maximum_Usages
    }
    this.apiService.updateCoupon(requestData, this.coupon_id).subscribe(
      (resp) => {
          if (resp.status == 'success') {
              this.router.navigateByUrl('Admin/coupons');
          }
      },
      (err) => {
          this.toastr.error(err.error.message);
      }
  );
  }

  addTermsAndConditions() {
    if(this.termPoint){
      this.termsAndConditions.push(this.termPoint)
      this.termPoint = null
    }else{
      alert("Enter value in benefit points")
    }
    
  }

  deleteTermsAndConditions(termPoint) {
    let termPointIndex = this.termsAndConditions.findIndex(
      existingTermPoint => existingTermPoint == termPoint
    )
    this.termsAndConditions.splice(termPointIndex, 1)
  }

  reset() {
    this.couponName = '';
    this.couponType = '';
    this.Discount_Percentage = null;
    this.Discount_Price = null;
    this.Maximum_Usages = null;
    this.Minimum_Purchase_Price = null;
    this.fromDate = '';
    this.toDate = '';
    this.customerType = null;
  }
}
