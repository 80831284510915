<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">
        <div class="row mt-8 justify-content-between">
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
              <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">
                PLAN&nbsp;TYPES
              </h1>
            </div>
      
            <div class="col-xs-12 col-sm-6 col-md-4  mb-2 d-flex justify-content-end" *ngIf="isUserAllowedToCreatePlanType">
                <button class="ml-4 addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
                (click)="createPlanType()">
                <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 txt" style="color: white">Create Plan Type</span>
            </button>
          </div>
        </div>
        <div class="row">    
            <div class="col-sm-12 col-md-4 col-lg-2">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                    <mat-select [placeholder]="'Active Plan Type'" [(ngModel)]="IDPlanType.planTypeActive" (selectionChange)="onFilterPlanType()">
                        <mat-option value="">All</mat-option>
                        <mat-option *ngFor="let plan of PlanStatusType" [value]="plan.value">{{plan.viewValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
                
            </div>
            <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;" [subscriptSizing]="'dynamic'">
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'"
                        [placeholder]="'Search Plan Type'">
                </mat-form-field>
            </div>
            </div>
            
           
        
       
    </div>



    <div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="PlanType">
                <th mat-header-cell *matHeaderCellDef >Plan Type</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.name}}
                </td>
            </ng-container>
            <ng-container matColumnDef="CreatedBy">
                <th mat-header-cell *matHeaderCellDef >Created By</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.created_by.full_name}}({{row.created_by.role.name}}) </td>

            </ng-container>
            <ng-container matColumnDef="CreatedDate">
                <th mat-header-cell *matHeaderCellDef >Created Date</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.created_at  | dateFormat}}
                </td>mat-sort-header
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                    <button mat-button [matMenuTriggerFor]="menu"
                        [disabled]="!isUserAllowedToEditPlanTypes && !isUserAllowedToDeactivatePlanType && !isUserAllowedToReactivatePlanType"><mat-icon
                            style="color:#0038FF;font-size: 30px;"
                            [disabled]="(isUserAllowedToEditPlanTypes && isUserAllowedToDeactivatePlanType && isUserAllowedToReactivatePlanType)"
                            svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToEditPlanTypes"
                            (click)="editPlanType(row)">Edit</button>
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToDeactivatePlanType"
                            (click)="verifyDeactivate(row.id)">Deactivate</button>
                        <button mat-menu-item *ngIf="row.is_removed && isUserAllowedToReactivatePlanType"
                            (click)="reactivatePlanType(row.id)">Reactivate</button>
                    </mat-menu>

                </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <div>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">
                        <h3 style="display: flex;justify-content: center; margin:10%;">
                            No Records Found
                        </h3>
                    </td>
                </tr>
            </div>
        </table>
    </div>
    <!-- <mat-paginator style="display:flex;justify-content: end;" [pageSizeOptions]="[ 10, 25, 100]"
            aria-label="Select page of plan_types"></mat-paginator> -->
            <mat-paginator style="display:flex;justify-content: end;" 
  (page)="onPaginateChange($event)" [length]="totalCount"
  [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
  aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>