<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall"
    style="margin-top: 0px !important;">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 p-6 pb-0" style="display: flex; justify-content:center;margin-top: 15%;">
            <!-- Light version -->
            <a  style="cursor: pointer;"  href="Admin/home" >
                <!-- Logo icon -->
                <b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <img
                class="dark:hidden w-30"
                src="assets/steelgym_lite.svg"
                alt="Logo image">
                    <!-- Light Logo icon --> <!--Logo when width is < 1024-->
                    <img
                    class="hidden dark:flex w-40"
                    src="assets\steelgym_lite.svg"
                    alt="Logo image">
                    <!--Logo when width is > 1023-->
                    <!-- <img src="assets/images/MMH-logo.svg" alt="Manage My Health" class="light-logo logo hide-1023"> -->
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->

            </a>
            <!-- <img
                class="dark:hidden w-30"
                src="assets/images/logo/logo-text.svg"
                alt="Logo image"> -->
            <!-- Dark version -->
            <!-- <img
                class="hidden dark:flex w-40"
                src="assets/updated_logo.svg"
                alt="Logo image"> -->
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div  [ngClass]="{'navItem': !showNav , 'w-full' : showNav }" class="fixed  flex flex-0 items-center  h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <languages></languages> -->
            <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts></shortcuts> -->
            <!-- <messages></messages> -->
            <!-- <notifications></notifications> -->
            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto" style="margin-top: 65px; margin-bottom: 70px;">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div style="position: fixed; bottom: 0; right: 0;"  class="flex flex-0 items-center justify-content-center w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Kunal Gir Fitness Solutions &copy; {{currentYear}}</span>
    </div> -->
    <div [ngClass]="{'navItem': !showNav , 'w-full' : showNav }" style="bottom: 0; right: 0;" class="fixed  flex flex-0 items-center justify-content-center  h-16 px-4 md:px-6 z-49 dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Kunal Gir Fitness Solutions &copy; {{currentYear}}</span>
    </div>

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
