<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-10 col-md-10 col-sm-12 mt-8 d-flex flex-lg-row flex-sm-column ">
            <h1 class="h1 ml-4 mr-6">
              Assign Devices
            </h1>
            <div class="" style="width: 25%;">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                    <mat-select [placeholder]="'Select Device'">
                        <mat-option value="country-1">Country 1</mat-option>
                        <mat-option value="country-2">Country 2</mat-option>
                        <mat-option value="country-3">Country 3</mat-option>
                    </mat-select>
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:location-marker'"></mat-icon>
                </mat-form-field>
            </div>
            <div class="" style="width: 25%;">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                    <mat-select [placeholder]="'Active Device'">
                        <mat-option value="country-1">Country 1</mat-option>
                        <mat-option value="country-2">Country 2</mat-option>
                        <mat-option value="country-3">Country 3</mat-option>
                    </mat-select>
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:location-marker'"></mat-icon>
                </mat-form-field>
            </div>
            <div class="" style="width: 25%;">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;" [subscriptSizing]="'dynamic'">
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'"
                        [placeholder]="'Search Device'">
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12 mt-8 d-flex justify-content-end ">
            <button class="ml-4 addBtn" mat-flat-button [color]="'primary'" (click)="createRole()">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 txt" style="color: black">Create Device</span>
            </button>
        </div>
    </div>
    <div class="mat-elevation-z8 mt-8 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="deviceId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Device ID</th>
                <td mat-cell *matCellDef="let row"> {{row.BranchName}} </td>

            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="deviceName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Device Name</th>
                <td mat-cell *matCellDef="let row"> {{row.Location}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="deviceMac">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Device MAC Address</th>
                <td mat-cell *matCellDef="let row"> {{row.Number}} </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <mat-cell style="height: 52px;align-items: center;
            justify-content: center;" *matCellDef="let row;">
                    <button mat-button [matMenuTriggerFor]="menu"><mat-icon style="color:#0038FF;font-size: 30px;"
                            svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>View</button>
                        <button mat-menu-item *ngIf="!row.is_removed ">Edit</button>
                        <button mat-menu-item *ngIf="!row.is_removed">Deactivate</button>
                    </mat-menu>

                </mat-cell>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator style="display:flex;justify-content: end;" [pageSizeOptions]="[ 5,10, 25, 100]"
            aria-label="Select page of users"></mat-paginator>
    </div>
</div>