<div class="container-fluid">
    <div class="dilogStl">
        <h1 style="
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;" mat-dialog-title>Enter OTP sent to your Mobile / Email</h1>
          
    </div>
    <div mat-dialog-content>
        <ng-otp-input  id="password"  (onInputChange)="onOtpChange($event)"  [config]="config"></ng-otp-input>

    </div>
    <div mat-dialog-actions style="justify-content: end;">
      <button mat-button mat-dialog-close  (click)="onNoClick()">Cancel</button>
      <button mat-button mat-dialog-close  [disabled]="!disableAdd" (click)="onOkClick()">Ok</button>
    </div>

</div>