<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-lg-8  mt-8 d-flex flex-column flex-sm-row ">
            <h1 class="h3 ml-4 mr-6 heading">
                PLANS LEVEL
            </h1>
            <div class="w-100">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;" [subscriptSizing]="'dynamic'">
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'"
                        [placeholder]="'Search Level'">
                </mat-form-field>
            </div>
            <div class="w-100">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                    <mat-select [placeholder]="'Active Plan Type'">
                        <mat-option value="">All</mat-option>
                        <mat-option *ngFor="let role of roleType" [value]="role.value">{{role.viewValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-12 col-lg-4 mt-8 d-flex justify-content-end" *ngIf="isUserAllowedToDeviceCreate">
            <button class="ml-4 addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
                (click)="addDevice()">
                <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 txt" style="color: black">Plan Level</span>
            </button>
        </div>
    </div>
    <div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="PlanType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Level</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> MemberShip
                </td>
            </ng-container>
            <ng-container matColumnDef="CreatedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> Admin </td>

            </ng-container>
            <ng-container matColumnDef="CreatedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> 20-03-2023
                </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                    <button mat-button [matMenuTriggerFor]="menu"
                        [disabled]="!isUserAllowedToDeviceEdit && !isUserAllowedToDeviceDeactivate && !isUserAllowedToDeviceReactivate"><mat-icon
                            style="color:#0038FF;font-size: 30px;"
                            [disabled]="(isUserAllowedToDeviceEdit && isUserAllowedToDeviceDeactivate && isUserAllowedToDeviceReactivate)"
                            svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToDeviceEdit"
                            (click)="editDevice(row)">Edit</button>
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToDeviceDeactivate"
                            (click)="deactivateDevice(row.id)">Deactivate</button>
                        <button mat-menu-item *ngIf="row.is_removed && isUserAllowedToDeviceReactivate"
                            (click)="reactivateDevice(row.id)">Reactivate</button>
                    </mat-menu>

                </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <div>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">
                        <h3 style="display: flex;justify-content: center; margin:10%;">
                            No Records Found
                        </h3>
                    </td>
                </tr>
            </div>
        </table>

        <mat-paginator style="display:flex;justify-content: end;" [pageSizeOptions]="[ 10, 25, 100]"
            aria-label="Select page of users"></mat-paginator>
    </div>
</div>