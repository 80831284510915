import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ApiService } from 'app/api.service';
import * as _moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { throwError } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { featureConstants } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';

@Component({
  selector: 'app-file-upload',
  templateUrl: './upload-faqs.component.html',
  styleUrls: ['./upload-faqs.component.scss'],
})
export class FileUploadComponent implements OnInit {
	extensions = "CSV"
	maxSize: number = 5;
	status: "initial" | "uploading" | "success" | "fail" = "initial"; // Variable to store file status
	file: any | null = null; // Variable to store file
  
	constructor(private http: HttpClient, public apiService: ApiService, private toastr: ToastrService,) {}
  
	userAllowedFeatures = getuserAllowedFeatures()
	isUserAllowedToUploadFAQs = this.userAllowedFeatures.indexOf(featureConstants.UPLOAD_FAQS) > -1
	
	ngOnInit(): void {
		if (!this.isUserAllowedToUploadFAQs) {
			this.toastr.error("User Doesn't have access to Upload FAQs" )
		}
	}
	private isValidExtension(file) {
		const extensions = (this.extensions.split(','))
                          .map( (x) => { return x.toLocaleUpperCase().trim() });
		var extension = file.name.toUpperCase().split('.').pop() || file.name;
		const exists = extensions.includes(extension)
		if(!exists) {
		 this.toastr.error("Invalid Extension: " + file.name)
		 return true
		}
		return false
	}
		/**
	 * on file drop handler
	 */
	onFileDropped($event) {
		this.file = null
		this.prepareFilesList($event);
	}

	/**
	 * handle file from browsing
	 */
	fileBrowseHandler(file) {
		this.prepareFilesList(file);
	}

	deleteFile() {
		this.file = null;
	  }
	/**
	 * Simulate the upload process
	 */
	uploadFilesSimulator(index: number) {
		setTimeout(() => {
		if (this.file == null) {
			return;
		} else {
			const progressInterval = setInterval(() => {
			if (this.file.progress === 100) {
				clearInterval(progressInterval);
				this.uploadFilesSimulator(index + 1);
			} else {
				this.file.progress += 5;
			}
			}, 200);
		}
		}, 1000);
	}

	/**
	 * Convert Files list to normal array list
	 * @param files (Files List)
	 */
	prepareFilesList(event: Array<any>) {
		const file = event[0];
		var is_invalid_file = false
		if (this.isValidFileSize(file) || this.isValidExtension(file)) {
			is_invalid_file = true
		}
		else {
			file.progress = 0;
			this.file = file
		}
		if (is_invalid_file == false) {
			this.uploadFilesSimulator(0);
		}
	}

	private isValidFileSize(file) {
			var fileSizeinMB = file.size / (1024 * 1000);
			/** convert upto 2 decimal place */
			var size = Math.round(fileSizeinMB * 100) / 100; 
			if (size > this.maxSize) {
				this.toastr.error("Error (File Size): " + file.name + ": exceed file size limit of " + this.maxSize + "MB ( " + size + "MB )");
		return true
		}
		return false
	}
    
	onUpload() {
		if (this.file) {
			const formData = new FormData();
		
			formData.append('file', this.file, this.file.name);
			const upload$ = this.apiService.uploadFaqs(formData);
		
			this.status = 'uploading';
		
			upload$.subscribe({
			  next: () => {
				this.status = 'success';
			  },
			  error: (error: any) => {
				this.status = 'fail';
				return throwError(() => error);
			  },
			});
		  }
	}
}