<!-- <h1  mat-dialog-title>View User</h1> -->
<div class="bgCard" >

    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>{{title}}</h1>
           <button align="end" mat-icon-button mat-dialog-close >
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>       
          </button>
    </div>
    <div class="card " style="border: none">
        <div class="row  mr-3 ml-3 mt-3 justify-center"[formGroup]="roleBasicDetailsForm">
            <div class="col-md-6">
                <div class="fw-bold fieldName">
                    Coupon Type
                    <span class="text-danger">*</span>
                </div>
                <div class="">
                    <mat-form-field class="w-full">
                      <input id="name" style="background: #FFFFFF;" matInput placeholder="Coupon Type"
                        formControlName="name" required 
                        pattern="^[a-zA-Z][\w\s-]*$"
                        (keypress)="handleStartingSpaceTextInput($event, roleBasicDetailsForm.get('name').value)"
                        >
                        <mat-error *ngIf="roleBasicDetailsForm.get('name').hasError('required')">
                            Coupon Type is required
                          </mat-error>
                          <mat-error *ngIf="roleBasicDetailsForm.get('name').hasError('pattern')">
                            Coupon Type has invalid characters
                          </mat-error>
                    </mat-form-field>
                  </div>
            </div>
            
        </div>
        <div class="col-md-12">
            <div class="mt-6">
                <div class=" d-flex justify-content-center">

                    <button *ngIf="!isUpdate" class="ml-4 addBtn" mat-flat-button [color]="'primary'" (click)="createCouponType()"
                        [ngClass]="{'btn-clr': roleBasicDetailsForm.valid}" [disabled]="!roleBasicDetailsForm.valid">
                        <span class="ml-2 mr-1 txt" style="color: white">Create</span>
                    </button>
                    <button *ngIf="isUpdate" class="ml-4 addBtn" mat-flat-button [color]="'primary'" (click)="UpdateCouponType()"
                    [ngClass]="{'btn-clr': isNameChanged()}" [disabled]="!isNameChanged()">
                    <span class="ml-2 mr-1 txt" style="color: white">Update</span>
                </button>
                </div>
            </div>
        </div>
        
    </div>
    
   
</div>

    <!-- <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
    </mat-dialog-actions> -->
