
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MetaService } from 'app/meta.service';
import { MatIconModule } from '@angular/material/icon';
@Component({
  selector: 'app-verify-deactivate',
  templateUrl: './verify-deactivate.component.html',
  styleUrls: ['./verify-deactivate.component.scss']
})
export class VerifyDeactivateComponent {
  receivedData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VerifyDeactivateComponent>,
    public meta: MetaService
  ) {
    
    this.receivedData = {
      message: this.data.message
    };
  }

  onNoClick() {
    
    this.dialogRef.close();
    this.meta.dialogClosed = false;
  }

  onOkClick() {
  
    this.dialogRef.close();
   
    this.meta.dialogClosed = true;
  }
}
