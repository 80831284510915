<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">
        <div class="row mt-8 justify-content-between">
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
              <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">
                TIMESLOT&nbsp;DISCOUNTS
              </h1>
            </div>
      
            <div class="col-xs-12 col-sm-6  mb-2 d-flex justify-content-end" *ngIf="isUserAllowedToCreateTimeslotDiscounts">
                <button class="ml-4 w-auto addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
                (click)="createTimeslotDiscount()">
                <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 w-auto txt" style="color: white">Create Timeslot Discount</span>
            </button>
          </div>
          </div>

          <div class="row ">
            
                
            <div class="col-sm-12 col-md-4 col-lg-2">
                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 100%;">
                    <mat-select [placeholder]="'All'" [(ngModel)]="filter_by_timeslot_active_status" (selectionChange)="onFilterTimeslotDiscounts()">
                        <mat-option value="">All</mat-option>
                        <mat-option *ngFor="let timeslot of timeslotStatus" [value]="timeslot.value">{{timeslot.viewValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> 
            <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;" [subscriptSizing]="'dynamic'">
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'"
                        [placeholder]="'Search Time Slot'">
                </mat-form-field>
            </div>
    </div>

    <div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

            <!-- ID Column -->
            <ng-container matColumnDef="TimeSlot">
                <th mat-header-cell *matHeaderCellDef>Time Slot</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.timeSlot}}
                </td>
            </ng-container>
            <ng-container matColumnDef="StartDate">
                <th mat-header-cell *matHeaderCellDef>Start Date</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.start_date| dateFormat}} </td>

            </ng-container>
            <ng-container matColumnDef="EndDate">
                <th mat-header-cell *matHeaderCellDef>End Date</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.end_date  | dateFormat}}
                </td>
            </ng-container>
            <ng-container matColumnDef="DiscountPercentage">
                <th mat-header-cell *matHeaderCellDef >Discount Percentage</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.discount_percentage}}
                </td>
            </ng-container>

            <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef >Created By</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                    {{row.created_by?.full_name}}<span *ngIf="row.created_by?.role">({{row.created_by.role.name}})</span> 
                </td>
            </ng-container>
            <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef >Created Date</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.created_at | dateFormat}} </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                    <button mat-button [matMenuTriggerFor]="menu"
                        [disabled]="!isUserAllowedToUpdateTimeslotDiscounts && !isUserAllowedToDeactivateTimeslotDiscounts && !isUserAllowedToReactivateTimeslotDiscounts"><mat-icon
                            style="color:#0038FF;font-size: 30px;"
                            [disabled]="(isUserAllowedToUpdateTimeslotDiscounts && isUserAllowedToDeactivateTimeslotDiscounts && isUserAllowedToReactivateTimeslotDiscounts)"
                            svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToUpdateTimeslotDiscounts"
                            (click)="editTimeslotDiscount(row)">Edit</button>
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToDeactivateTimeslotDiscounts"
                            (click)="verifyDeactivate(row.id)">Deactivate</button>
                        <button mat-menu-item *ngIf="row.is_removed && isUserAllowedToReactivateTimeslotDiscounts"
                            (click)="reactivateTimeslotDiscount(row.id)">Reactivate</button>
                    </mat-menu>

                </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <div>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">
                        <h3 style="display: flex;justify-content: center; margin:10%;">
                            No Records Found
                        </h3>
                    </td>
                </tr>
            </div>
        </table>
    </div>
    <!-- <mat-paginator style="display:flex;justify-content: end;" [pageSizeOptions]="[ 10, 25, 100]"
        aria-label="Select page of users"></mat-paginator> -->
        <mat-paginator style="display:flex;justify-content: end;" 
        (page)="onPaginateChange($event)" [length]="totalCount"
        [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
        aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>