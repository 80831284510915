<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-md-10 col-lg-5 mt-8 d-flex flex-row flex-sm-column">
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">VIEW&nbsp;SCHEDULES</h2>

        </div>
    </div>
    <div class="row">
            
            <div class="col-sm-12 col-md-6 col-lg-2 mt-2">
                <mat-form-field class="flex-auto gt-xs:pr-3 w-full">
                    <input matInput readonly [matDatepicker]="fromDatePicker" [(ngModel)]="fromDateFilter"
                        [placeholder]="'From Date'" (dateChange)="fromDateChanged()">
                    <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-2 mt-2">
                <mat-form-field class="flex-auto gt-xs:pr-3  w-full">
                    <input matInput readonly [matDatepicker]="toDatePicker" [placeholder]="'To Date'" 
                    [(ngModel)]="toDateFilter" [disabled]="!fromDateFilter" [min]="fromDateFilter" (dateChange)="toDateChanged()">
                    <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #toDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-2 mt-2">
                <mat-form-field
                    class="flex-auto gt-xs:pr-3 w-full"
                    [subscriptSizing]="'dynamic'"
                >
                    <mat-icon
                        class="icon-size-5"
                        matPrefix
                        [svgIcon]="'heroicons_solid:search'"
                    ></mat-icon>
                    <input
                        matInput
                        (keyup)="applyFilter($event)"
                        [autocomplete]="'off'"
                        [placeholder]="'Search Schedule'"
                        [(ngModel)]="searchTerm"
                    />
                </mat-form-field>
            </div>
    </div>


    <div class="card dataCard m-2">
        <div class="row ml-2" *ngIf="userInfo">
           
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div class="listFont ">
                    {{userTitle}}
                </div>
                <div style="font-size: 20px; font-weight: 400;">
                    {{userInfo.user.full_name}}
                </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div class="listFont ">
                        Branch:
                    </div>
                    <div style="font-size: 20px; font-weight: 400;">
                        {{userInfo.branch}}
                    </div>
                </div>
            
        </div>
        <div class="row ml-2" *ngIf="userPlanInfo">
            
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div class="listFont ">
                    PlanType:
                 </div>
                 <div style="font-size: 20px; font-weight: 400;">
                    {{userPlanInfo.plan_type.name}}
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div class="listFont ">
                    PlanSubType:
                 </div>
                 <div style="font-size: 20px; font-weight: 400;">
                    {{userPlanInfo.plan_type.plan_subtype?.name}}
                </div>
            </div>
        </div>
        <div class="row ml-2" *ngIf="userPlanInfo">
            
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div class="listFont ">
                    Plan:
                 </div>
                 <div style="font-size: 20px; font-weight: 400;">
                    {{userPlanInfo.plan.name}}
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div class="listFont ">
                    PlanPrice:
                 </div>
                 <div style="font-size: 20px; font-weight: 400;">
                    {{userPlanInfo.user_order_plan.price}}
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div class="listFont ">
                    PlanDuration:
                 </div>
                 <div style="font-size: 20px; font-weight: 400;">
                    {{userPlanInfo.plan.validity_in_months}}
                </div>
            </div>
            
            <div class=" col-sm-6 col-md-6 col-lg-3"></div>
        </div>
    </div>

    <div class="mat-elevation-z8 mt-8 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="Customer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
                <td mat-cell *matCellDef="let row"> {{row.customer | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="Trainer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Trainer</th>
                <td mat-cell *matCellDef="let row"> {{row.trainer | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="SlotDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Slot Date</th>
                <td mat-cell *matCellDef="let row"> {{row.slotDate | dateFormat}} </td>
            </ng-container>

            <ng-container matColumnDef="SlotTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Slot Time</th>
                <td mat-cell *matCellDef="let row"> {{row.slotTime | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="BookingStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Booking Status</th>
                <td mat-cell *matCellDef="let row"> {{row.bookingStatus.replace('_', ' ') | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="SessionStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Session Status</th>
                <td mat-cell *matCellDef="let row"> {{row.sessionStatus.replace('_', ' ') | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="SessionStartedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Session Started At</th>
                <td mat-cell *matCellDef="let row"> {{row.sessionStartedAt | titlecase}} </td>
            </ng-container>


            <ng-container matColumnDef="CreatedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                    {{row.createdBy?.full_name}}<span *ngIf="row.createdBy?.role">({{row.createdBy?.role.name}})</span> 
                </td>
            </ng-container>
            <ng-container matColumnDef="CreatedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.createdAt | dateFormat}} </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <mat-cell class="" style="height: 52px;" *matCellDef="let row;">
                    <button mat-button [matMenuTriggerFor]="menu"><mat-icon style="color:#0038FF;font-size: 30px;" svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="isUserAllowedToEditSchedules" (click)="updateSchedule(row)">Update Schedule</button>
                    </mat-menu>
                  </mat-cell>
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- when there is no matching data it will be visible -->
            <div>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">
                        <h3 style="display: flex;justify-content: center; margin:10%;">
                            No Records Found
                        </h3>
                    </td>
                </tr>
            </div>

        </table>
    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>
