<!-- <h1  mat-dialog-title>View User</h1> -->
<div class="bgCard">
    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>Leave Action</h1>
        <button align="end" mat-icon-button mat-dialog-close>
            <mat-icon style="color: #0038ff" svgIcon="feather:x"></mat-icon>
        </button>
    </div>
    <div>
        <mat-dialog-content class="mat-typography">

            <div class="card dataCard mt-3 p-2">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-3 mb-4">
                        <div class="listFont fw-bold">User Name:</div>
                        <div class="fw-bold">
                            {{ leaveData.user.full_name }}
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-2 mb-4">
                        <div class="listFont fw-bold">Role:</div>
                        <div class="fw-bold">
                            {{ leaveData.user.role.name }}
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-3 mb-4">
                        <div class="listFont fw-bold">No Of Leaves:</div>
                        <div class="fw-bold">
                            {{ leaveData.total_leaves_count }}
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-3 mb-4">
                        <div class="listFont fw-bold">No Of Paid Leaves:</div>
                        <div class="fw-bold">
                            {{ leaveData.paid_leaves_count }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-3 mb-4">
                        <div class="listFont fw-bold">From Date:</div>
                        <div class="fw-bold">
                            {{ LeaveDates.start_date | dateFormat }}
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-3 mb-4">
                        <div class="listFont fw-bold">To Date:</div>
                        <div class="fw-bold">
                            {{ LeaveDates.end_date | dateFormat }}
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-3 mb-4">
                        <div class="listFont fw-bold">Current Status:</div>
                        <div class="fw-bold">
                            {{ leaveData.status | titlecase}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="card dataCard mt-3 p-2">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4 mb-4">
                        <div class="listFont fw-bold">Title:</div>
                        <div class="fw-bold">
                            {{ leaveData.title }}
                        </div>
                    </div>
                    <div class="col-sm-8 col-md-8 col-lg-8 mb-4">
                        <div class="listFont fw-bold">Description:</div>
                        <div class="fw-bold">
                            {{ leaveData.description }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4 mb-4">
                        <div class="listFont fw-bold">Last Responded By</div>
                        <div class="fw-bold">
                            {{leaveData.last_responded_by?.full_name}}<span *ngIf="leaveData.last_responded_by?.role">({{leaveData.last_responded_by.role.name}})</span>
                        </div>
                    </div>
                    <div class="col-sm-8 col-md-8 col-lg-8 mb-4">
                        <div class="listFont fw-bold">Last Responded At:</div>
                        <div class="fw-bold">
                            {{leaveData?.last_responded_at | dateTimeFormat}}
                        </div>
                    </div>
                </div>
            </div>

            <app-file-upload-manager #fileUploadComponent 
            [shouldShowFileUploader]="false" 
            [existingFileData]="existingFileDataForFileManager" 
            [actualexistingFilesCount]="actualexistingFilesCountForFileManager">
            </app-file-upload-manager>

            <div class="card dataCard mt-3 p-2" *ngIf="shouldShowApprovalScreen">
                <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-6">
                        <mat-radio-group aria-label="Select an option" class="d-flex flex-column flex-sm-row" style="
                                        justify-content: space-around;
                                        align-items: center;" 
                                        [(ngModel)]="Status">
                            <mat-radio-button value="ACCEPTED" (change)="LeaveStatusChanged($event)">Approve</mat-radio-button>
                            <mat-radio-button value="REJECTED" (change)="LeaveStatusChanged($event)">Reject</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>

            <div class="card " style="border: none" *ngIf="shouldShowApprovalScreen">
                <div class="row  m-3 justify-content-center">
                    <div class="col-10">
                        <div class="fw-bold fieldName" style="background: #000; color: #fff; padding: 10px; border-top-left-radius: 13px; border-top-right-radius: 13px;">
                            Description
                            <span class="text-danger" *ngIf="Status == 'REJECTED'">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <textarea rows="5" style="background: #ffffff" matInput
                                    placeholder="Description" [(ngModel)]="description" id="descriptionId" name="descriptionId" #descriptionId="ngModel">
                                </textarea>
                            </mat-form-field>
                            <mat-error *ngIf="(Status == 'REJECTED' && descriptionId.touched && (descriptionId.invalid || !description))">Description is required</mat-error>
                        </div>
                    </div>
                </div>
            </div>

        </mat-dialog-content>
    </div>
    <div class="btnposition" *ngIf="shouldShowApprovalScreen">
        <div class="bottomBtns mb-3 row" style="display: flex; justify-content: space-between">
            <button class="ml-4 addBtn" mat-flat-button (click)="reset()">
                <span class="ml-2 mr-1 txt">Reset</span>
            </button>

            <!-- Why tghis span is added here -->
            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="createLeaveStatus()"
                [ngClass]="{
                    'btn-clr': !(
                        !Status ||
                        (Status == 'REJECTED' && (descriptionId.invalid || !description))
                    )
                }" 
                [disabled]="(
                    !Status ||
                    (Status == 'REJECTED' && (descriptionId.invalid || !description))
                )">
                <span class="ml-2 mr-1 txt" style="color: white">Update</span>
            </button>
        </div>
    </div>
</div>
