
import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { viewUser } from 'app/meta.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';

@Component({
  selector: 'app-create-tags',
  templateUrl: './create-tag.component.html',
  styleUrls: ['./create-tag.component.scss']
})
export class CreateTagComponent {

  handleStartingSpaceTextInput = handleStartingSpaceTextInput

  name = null;
  selectedAlias = null;
  ALIAS = [
    { value: 'PLAN', viewValue: 'Plan' },
    { value: 'PAYMENT', viewValue: 'Payment' },
    { value: 'SLOTS', viewValue: 'Slots' },
    { value: 'OTHER', viewValue: 'Other' }
  ]
  data;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data_: any,
    public apiService: ApiService, private router: Router, private toastr: ToastrService,private _formBuilder: FormBuilder, private dialogRef: MatDialogRef <CreateTagComponent>
  ) {
    this.data = data_
  }

  plan_type_id : string;
  isUpdate:boolean=false;
  title: string = 'CREATE TAG';
  ngOnInit() {
    if(this.data != null){
      this.isUpdate = true;
      this.title = "EDIT TAG"
      this.name = this.data.data.name
      this.selectedAlias = this.data.data.alias
    }
  }
  
  createTag() {
    let payload = {
      name: this.name,
      alias: this.selectedAlias
    }
    this.apiService.createTag(payload).subscribe((resp) => {
      this.dialogRef.close();
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  updateTag() {
    let payload = {
      name: this.name,
      alias: this.selectedAlias
    }
    this.apiService.updateTags(payload,this.data.data.id).subscribe((resp) => {
      this.dialogRef.close();
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }
  isNameChanged() {
    if (this.name) {
      return this.name != this.data.data.name
    }
  }
}


