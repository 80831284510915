<!-- <h1  mat-dialog-title>View User</h1> -->
<div class="container-fluid" style="background-color: #F1F5F9; height:100%;">
  <div class="dilogStl">
    <h1 class="viewHeading" mat-dialog-title>VIEW ROLES</h1>
    <button align="end" mat-icon-button mat-dialog-close>
        <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>
    </button>
</div>
    <mat-dialog-content class="mat-typography">
    
    <div class="pl-6 row mt-3 justify-content-center">
        <div class="col-sm-6 col-lg-3">
            <div class="listFont fw-bold">
                Role Name:
            </div>
            <div class="fw-bold">
                {{Irole.name | titlecase}}
            </div>
        </div>

        <div class="col-sm-6 col-lg-3">
            <div class="listFont fw-bold">
                Role Type:
            </div>
            <div class="fw-bold">
                {{Irole.type}}
            </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="listFont fw-bold">
              Created By:
          </div>
          <div class="fw-bold">
              {{Irole.createdBy}}<span *ngIf="Irole.createdByRole">({{Irole.createdByRole}})</span>
          </div>
      </div>
        <div class="col-sm-6 col-lg-3">
            <div class="listFont fw-bold">
                Created Date:
            </div>
            <div class="fw-bold">
                {{Irole.createdAt | dateFormat }}
            </div>
        </div>

    </div>

    <div class="pl-6 row mt-3 justify-content-center">
        <div class="col-md-12">
          <mat-checkbox [checked]="Irole.shouldAddAddress" [disabled]="true"> 
            <span class="listFont">Should Add Address</span>
          </mat-checkbox>
          <mat-checkbox [checked]="Irole.shouldAddAvailabilityTimings" [disabled]="true"> 
            <span class="listFont">Should Add Availability Timings</span>
          </mat-checkbox>
          <mat-checkbox [checked]="Irole.shouldAddBranchAtUserCreation" [disabled]="true"> 
            <span class="listFont">Should Add Branch At User Creation</span>
          </mat-checkbox>
          <mat-checkbox [checked]="Irole.canAddMultipleBranches" [disabled]="true"> 
            <span class="listFont">Can Add Multiple Branches</span>
          </mat-checkbox>
          <!-- <mat-checkbox [checked]="Irole.shouldAddLevel" [disabled]="true"> 
            <span class="fw-bold">Should Add Level</span>
          </mat-checkbox> -->
        </div>
    </div>

    <div class="">
        <div class="card-header mb-4" >
          <h4 class="p-3 ml-2.5">
            Access Controls
          </h4>
        </div>
        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel *ngFor="let head of heading; let i = index " >
            <mat-expansion-panel-header>
              <mat-panel-title [ngClass]="{'h6': step === 0}" style="color: #000000 !important; font-weight: bold; height: fit-content;">
                {{headingArr[i]}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow:auto;">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Features</th>
                  <th scope="col"></th>
                  <th scope="col">Web</th>
                  <th scope="col">Mobile</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataArr[i]">
                  <td width="60%" class="fieldName">{{row.name}}</td>
                  <td width="10px"></td>
                  <td width="20px"><mat-checkbox [disabled]="true" [checked]="row.is_checked_on_web_app"
                      class="example-margin"></mat-checkbox></td>
                  <td width="20px"><mat-checkbox [disabled]="true" [checked]="row.is_checked_on_mobile_app"
                      class="example-margin"></mat-checkbox></td>
                </tr>
              </tbody>
            </table>
          </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

    </mat-dialog-content>

</div>
<!-- <mat-dialog-actions class="modal-footer" align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->