/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { featureConstants } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';


export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: '',
        title: '',
        // subtitle: 'Unique dashboard designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            // {
            //     id   : 'example',
            //     title: 'Example',
            //     type : 'basic',
            //     icon : 'heroicons_outline:chart-pie',
            //     link : '/example'
            // },
            {
                id: 'home',
                title: 'HOME',
                type: 'basic',
                icon: 'heroicons_outline:home',
                link: 'Admin/home'
            },
            {
                id: 'roleManagement',
                title: 'Role Management',
                type: 'basic',
                icon: 'feather:users',
                link: 'Admin/roles'
            },
            {
                id: 'userManagement',
                title: 'User Management',
                type: 'basic',
                icon: 'feather:users',
                link: 'Admin/users'
            },
            {
                id: 'branchManagement',
                title: 'Branch Management',
                type: 'basic',
                icon: 'heroicons_outline:office-building',
                link: 'Admin/branches'
            },
            {
                id: 'deviceManagement',
                title: 'Device Management',
                type: 'basic',
                icon: 'mat_solid:fingerprint',
                link: 'Admin/devices'
            },
            // {
            //     id: 'userAttendance',
            //     title: 'User Attendance',
            //     type: 'basic',
            //     icon: 'feather:user-check',
            //     link: 'Admin/attendances'
            // },
            {
                id: 'leaveManagement',
                title: 'Leave Management',
                type: 'basic',
                icon: 'feather:user-check',
                link: 'Admin/leaves'
            },
            // {
            //     id: 'LEVEL Management',
            //     title: 'LEVEL Management',
            //     type: 'basic',
            //     icon: 'mat_outline:query_stats',
            //     link: 'Admin/level-List'
            // },
            {
                id: 'userpurchasehistory',
                title: 'User Purchase History',
                type: 'basic',
                icon: 'feather:user-check',
                link: 'Admin/user-purchase-history-all'
            },

            {
                id      : 'fitnessPlans',
                title   : 'Fitness Plans',
                type    : 'collapsable',
                icon    : 'feather:list',
                children: [
                    {
                        id   : 'planTypes',
                        title: 'Plan Types',
                        type : 'basic',
                        link : 'Admin/plan-types'
                    },
                    {
                        id   : 'planSubTypes',
                        title: 'Plan Sub Types',
                        type : 'basic',
                        link : 'Admin/plan-sub-types'
                    },
                    // {
                    //     id   : 'plan-level',
                    //     title: 'Plan Level',
                    //     type : 'basic',
                    //     link : 'Admin/plan-level'
                    // },
                    {
                        id   : 'plans',
                        title: 'Plans',
                        type : 'basic',
                        link : 'Admin/plans'
                    },
                    // {
                    //     id   : 'addonTypes',
                    //     title: 'AddOn Types',
                    //     type : 'basic',
                    //     link : 'Admin/addon-types'
                    // },
                    // {
                    //     id   : 'addonPlans',
                    //     title: 'AddOn Packs',
                    //     type : 'basic',
                    //     link : 'Admin/addon-plans'
                    // },
                    {
                        id   : 'timeslotDiscounts',
                        title: 'Timeslot Discounts',
                        type : 'basic',
                        link : 'Admin/timeslot-discounts'
                    },
                ]
            },
            {
                id      : 'schedules',
                title   : 'Schedules',
                type    : 'collapsable',
                icon    : 'feather:calendar',
                children: [
                    {
                        id   : 'activeSchedules',
                        title: 'Schedules',
                        type : 'basic',
                        link : 'Admin/active-schedules'
                    },
                    {
                        id   : 'reschedulesRequests',
                        title: 'Reschedule Requests',
                        type : 'basic',
                        link : 'Admin/reschedules'
                    }
                ]
            },
            {
                id      : 'coupons',
                title   : 'Coupons',
                type    : 'collapsable',
                icon    : 'heroicons_outline:ticket',
                children: [
                    {
                        id   : 'couponTypes',
                        title: 'Coupon Types',
                        type : 'basic',
                        link : 'Admin/coupon-types'
                    },
                    {
                        id   : 'coupons',
                        title: 'Coupons',
                        type : 'basic',
                        link : 'Admin/coupons'
                    }

                ]
            },
            {
                id      : 'support',
                title   : 'Support',
                type    : 'collapsable',
                icon    : 'heroicons_outline:ticket',
                children: [
                    {
                        id      : 'tags',
                        title   : 'Tags',
                        type    : 'basic',
                        link    : 'Admin/tags',
                        icon    : 'heroicons_outline:ticket',
                    },
                    {
                        id      : 'tickets',
                        title   : 'Tickets',
                        type    : 'basic',
                        link    : 'Admin/tickets',
                        icon    : 'heroicons_outline:ticket',
                    },
                    {
                        id      : 'faqs',
                        title   : 'FAQs',
                        type    : 'basic',
                        link    : 'Admin/faqs',
                        icon    : 'heroicons_outline:ticket',
                    }
                ]
            },
            {
                id      : 'reports',
                title   : 'Reports',
                type    : 'collapsable',
                icon    : 'feather:user-check',
                children: [
                    {
                        id      : 'usageReports',
                        title   : 'Usage Reports',
                        type    : 'basic',
                        link    : 'Admin/usage-reports',
                        icon    : 'feather:user-check',
                    },
                    {
                        id      : 'payrollReports',
                        title   : 'Payroll Reports',
                        type    : 'basic',
                        link    : 'Admin/payroll-reports',
                        icon    : 'feather:user-check',
                    }
                ]
            },
            // {
            //     id      : 'Branch Management',
            //     title   : 'Branch Management',
            //     type    : 'collapsable',
            //     icon    : 'heroicons_outline:office-building',
            //     children: [
            //         {
            //             id   : 'Branch Management.Branch',
            //             title: 'Branch',
            //             type : 'basic',
            //             link : 'Admin/branch'
            //         },
            //         {
            //             id   : 'Branch Management.createBranch',
            //             title: 'Create Branch',
            //             type : 'basic',
            //             link : 'Admin/create-branch'
            //         }
            //     ]
            // },
            // {
            //     id      : 'User Attendence',
            //     title   : 'User Attendence',
            //     type    : 'collapsable',
            //     icon    : 'feather:user-check',
            //     children: [
            //         {
            //             id   : 'User Attendence.attendence',
            //             title: 'Attendence',
            //             type : 'basic',
            //             link : ''
            //         },
            //         {
            //             id   : 'User Attendence.CreateAttendence',
            //             title: 'Create Attendence',
            //             type : 'basic',
            //             link : ''
            //         }
            //     ]
            // },
            // {
            //     id      : 'Bio-metric',
            //     title   : 'BIOMETRIC DEVICE',
            //     type    : 'collapsable',
            //     icon    : 'mat_solid:fingerprint',
            //     children: [
            //         {
            //             id   : 'Bio-metric.Device',
            //             title: 'Devices',
            //             type : 'basic',
            //             link : ''
            //         },
            //         {
            //             id   : 'Bio-metric.CreateDevice',
            //             title: 'Create Device',
            //             type : 'basic',
            //             link : ''
            //         },
            //         {
            //             id   : 'Bio-metric.AssignDevice',
            //             title: 'Assign Device',
            //             type : 'basic',
            //             link : ''
            //         }
            //     ]
            // },
        ]
    },

];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    },

];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    },

];
export const horizontalNavigation: FuseNavigationItem[] = [

    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];

export function setNavigationItems() {

    let userAllowedFeatures = getuserAllowedFeatures()
    
    let defaultNavigation: FuseNavigationItem[] = [
        {
            id: '',
            title: '',
            // subtitle: 'Unique dashboard designs',
            type: 'group',
            icon: 'heroicons_outline:home',
            children: [
                {
                    id: 'Home',
                    title: 'HOME',
                    type: 'basic',
                    icon: 'heroicons_outline:home',
                    link: 'Admin/home'
                }
            ]
        },
    
    ];

    if (!userAllowedFeatures) {
        userAllowedFeatures = []
    }

    if (userAllowedFeatures.indexOf(featureConstants.VIEW_ROLES) > -1) {
        defaultNavigation[0].children.push({
            id: 'RoleManagement',
            title: 'ROLE MANAGEMENT',
            type: 'basic',
            icon: 'feather:users',
            link: 'Admin/roles'
        })
        
    }

    if (userAllowedFeatures.indexOf(featureConstants.VIEW_USERS) > -1) {
        defaultNavigation[0].children.push({
            id: 'USER MANAGEMENT',
            title: 'USER MANAGEMENT',
            type: 'basic',
            icon: 'feather:users',
            link: 'Admin/users'
        })
        
    }

    if (userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1) {
        defaultNavigation[0].children.push({
            id: 'Branch Management',
            title: 'BRANCH MANAGEMENT',
            type: 'basic',
            icon: 'heroicons_outline:office-building',
            link: 'Admin/branch'
        })
        
    }

    if (userAllowedFeatures.indexOf(featureConstants.VIEW_ATTENDANCE_DEVICES) > -1) {
        defaultNavigation[0].children.push({
            id: 'deviceManagement',
            title: 'DEVICE MANAGEMENT',
            type: 'basic',
            icon: 'mat_solid:fingerprint',
            link: 'Admin/device-List'
        })
        
    }

    if (userAllowedFeatures.indexOf(featureConstants.VIEW_ATTENDANCE_LOGS) > -1) {
        defaultNavigation[0].children.push({
            id: 'USER ATTENDANCE',
            title: 'USER ATTENDANCE',
            type: 'basic',
            icon: 'feather:user-check',
            link: 'Admin/attendances'
        })
    }
    if (userAllowedFeatures.indexOf(featureConstants.VIEW_TICKETS) > -1) {
        defaultNavigation[0].children.push({
            id: 'Tickets',
            title: 'Tickets',
            type: 'basic',
            icon: 'heroicons_outline:ticket',
            link: 'Admin/ticket-list'
        })
    }
}
