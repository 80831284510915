
import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { MetaService, roles, featureConstants } from 'app/meta.service';
import * as _moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2';

import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/core/auth/auth.service';
import { OtpConformationComponent } from 'app/pages/Users/otp-conformation/otp-conformation.component';
import { MY_FORMATS } from 'app/app.component';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';

const moment = _moment;
export interface State {
  id: string;
  name: string;
}
export interface cities {
  id: string;
  name: string;
}

@Component({
  selector: 'app-create-add-on-types',
  templateUrl: './create-add-on-types.component.html',
  styleUrls: ['./create-add-on-types.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class CreateAddOnTypesComponent {

  handleStartingSpaceTextInput = handleStartingSpaceTextInput
  
  PlanTypeList = [];
  benefitPoints:string = null;
  benefit = null;
  planSubTypeBenefitPoints =[];
  plan_id_name_aliases = []
  selected_report_to_user_ids = []
  AddOnTypeDetails=[];
  addOnName:string;
  addon_plan_type_id : string;
  isUpdate: boolean = false;
  title: string = "CREATE ADD ON TYPE";

  constructor(private router: Router, private meta: MetaService, public dialog: MatDialog, private route: ActivatedRoute, public apiService: ApiService, public _authService: AuthService, private datePipe: DatePipe, private _formBuilder: FormBuilder, private toastr: ToastrService,) {
    // this.route.params.subscribe((params) => {
    //   this.addon_plan_type_id  = params['addon_plan_type_id'];
    // });
    this.addon_plan_type_id = sessionStorage.getItem('editAddonTypeId')
  }
  ngOnInit() {

    this.buildForm();
    if (this.addon_plan_type_id  != null) {
      this.getAddOnTypeById();
      this.isUpdate = true;
      this.title = "EDIT ADD ON TYPE"
     
    } else {
     
    }
  }
  back() {
    this.router.navigateByUrl('Admin/addon-types');
  }

  public buildForm() {
    let name = null
    if(this.addon_plan_type_id ){
    }
  }
 
 

  Addplantype() {
    if(this.benefitPoints){
      this.benefit=this.planSubTypeBenefitPoints.push(this.benefitPoints)
      this.benefitPoints= null
    }else{
      alert("Enter value in benefit points")
    }
    
  }

  createAddOnTypes() {
    
    
    let payload = {
      
      "name": this.addOnName,
      all_benefits_points : this.planSubTypeBenefitPoints
  }

    this.apiService.createAddOnType(payload).subscribe((resp) => {
      if (resp.status == "success") {
        this.router.navigateByUrl('Admin/addon-types');

      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  getAddOnTypeById() {
    this.AddOnTypeDetails = [];
    this.apiService.getAddOnTypeById(this.addon_plan_type_id ).subscribe((resp) => {
      if (resp.status == "success") {
        this.AddOnTypeDetails.push(resp.data.add_on_plan_type);
        this.addOnName = resp.data.add_on_plan_type.name
        this.planSubTypeBenefitPoints = resp.data.add_on_plan_type.all_benefits_points
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  UpdatePlanSubType() {
    let payload = {
      name: this.addOnName,
      all_benefits_points: this.planSubTypeBenefitPoints

    }
    
    this.apiService.updateAddOnType(payload,this.addon_plan_type_id ).subscribe((resp) => {
      if (resp.status == "success") {
        this.router.navigateByUrl('Admin/addon-types');

      }
     
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  deleteBenefitPoint(benefitPoint) {
    let benefitPointIndex = this.planSubTypeBenefitPoints.findIndex(
      existingBenefitPoint => existingBenefitPoint == benefitPoint
    )
    this.planSubTypeBenefitPoints.splice(benefitPointIndex, 1)
  }
  resetAddOnType() {
    this.benefit = [];
    this.addOnName = '';
    this.benefitPoints = '';
    this.planSubTypeBenefitPoints = [];
  }
}
