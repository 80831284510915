<div class="container-fluid">
    <div>
        <div class="d-flex m-3">
            <div class=" mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{actionItem}}</h2>
        </div>

        <div class="card" [formGroup]="basicDetailsForm" style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="mt-2 ml-3 mb-4">
                <h4 style="color: #00FF00;">
                    Device Details
                </h4>
                <div class="row mr-3 ml-3">
                    <div class="col-md-6">
                        <div class="fw-bold fieldName">
                            Device Name
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="name" style="background: #FFFFFF;" matInput placeholder="Enter Device Name"
                                    formControlName="name" required pattern="^[a-zA-Z][\w\s]*$" (keypress)="handleStartingSpaceTextInput($event, basicDetailsForm.get('name').value)">
                                    <mat-error *ngIf="basicDetailsForm.get('name').hasError('required')">
                                        Name is required
                                    </mat-error>
                                    <mat-error *ngIf="basicDetailsForm.get('name').hasError('pattern')">
                                        Name has invalid characters
                                    </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="fw-bold fieldName">
                            Description
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="description" style="background: #FFFFFF;"  matInput
                                   placeholder="Enter Description" formControlName="description" 
                                   (keypress)="handleStartingSpaceTextInput($event, basicDetailsForm.get('description').value)">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="fw-bold fieldName">
                            Attendance Device Id
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="attendance_device_id" style="background: #FFFFFF;" [readonly]="isUpdate" (keypress)="keyPress($event)" matInput placeholder="Enter Access Point"
                                    formControlName="attendance_device_id">
                                    <mat-error *ngIf="basicDetailsForm.get('attendance_device_id').hasError('required')">
                                        Attendance Device Id is required
                                    </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="fw-bold fieldName">
                            Attendance Device Type
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <mat-select id="attendance_device_type" name="Attendance Device Type" required [placeholder]="'Attendance Device Type'" formControlName="attendance_device_type">
                                    <mat-option *ngFor="let device of attendanceDeviceTypes" [value]="device.value">{{device.viewValue}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="basicDetailsForm.get('attendance_device_type').hasError('required')">
                                    Attendance Device Type is required
                                </mat-error>
                            </mat-form-field>

                            <!-- <mat-form-field class="w-full">
                                <input id="attendance_device_type" style="background: #FFFFFF;" [readonly]="true" (keypress)="keyPress($event)" matInput placeholder="Select Attendance Device Type"
                                    formControlName="attendance_device_type" value="kghg">
                            </mat-form-field> -->
                        </div>
                    </div>

                </div>
                <div class="row  mr-3 ml-3 mt-3">
                    <div class="col-md-6" >
                        <div class="fw-bold fieldName">
                            Branch 
                            <span class="text-danger">*</span>
                        </div>
                        <div class="" *ngIf="!isUpdate">
                            <div class="">
                                <mat-form-field class="w-full" style="width: 90%;">
                                    <mat-select [placeholder]="'Select Branch'" formControlName="branchId">
                                      <mat-option *ngFor="let branch of branches_list" [value]="branch.id">{{branch.name}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="basicDetailsForm.get('branchId').hasError('required')">
                                        Branch is required
                                    </mat-error>
                                  </mat-form-field>
                            </div>
                        </div>
                        <div class="" *ngIf="isUpdate">
                            <div class="">
                                <mat-form-field class="w-full" style="width: 90%;">
                                    <!-- {{selectedBranch?.name}} -->
                                    <input id="branch_id" style="background: #FFFFFF;" formControlName="branchName"  [readonly]="isUpdate" matInput>
                                  </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-4"  >
                        <div class="fw-bold fieldName">
                            Role
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="w-full">
                                    <input id="role" style="background: #FFFFFF;" matInput readonly
                                        formControlName="deviceType">
                                </mat-form-field>
                            </div>
                        </div>
                    </div> -->

               
                </div>

            </div>


        </div>


    </div>
    <div class="m-2 btnposition">
        <div class="bottomBtns mb-3 row">
          <button class="ml-4 addBtn" *ngIf="!isUpdate" (click)="resetForm()"mat-flat-button>
            <span class="ml-2 mr-1 txt" >Reset</span>
          </button>
          
          <button class="ml-4 addBtn" *ngIf="isUpdate" (click)="back()" mat-flat-button>
            <span class="ml-2 mr-1 txt" >Cancel</span>
          </button>
          <button class="ml-4 mr-6 addBtn"  mat-flat-button [color]="'primary'"
         (click)="createDevice()"  *ngIf="!isUpdate"  [disabled]="!basicDetailsForm.valid" [ngClass]="{'btn-clr': basicDetailsForm.valid}">
            <span class="ml-2 mr-1 txt" style="color: white">Create </span>
          </button>
          <button class="ml-4 mr-6 addBtn"  mat-flat-button [color]="'primary'"
          (click)="updateDevice()" *ngIf="isUpdate" [disabled]="!basicDetailsForm.valid" [ngClass]="{'btn-clr': basicDetailsForm.valid}">
             <span class="ml-2 mr-1 txt" style="color: white">Update </span>
           </button>
        </div>
      </div>
</div>        