<!-- <p>roles-list works!</p> -->
<div class="container-fluid" >
    <div class="row">
    <div class="col-lg-8 col-md-10 col-sm-12 mt-8 d-flex flex-lg-row flex-sm-column " >
        <h1 class="h1 ml-4 mr-6" >
            Branches
        </h1>
        <!-- <div class="" style="width: 25%;">
            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                <mat-select [placeholder]="'Select branch   '">
                    <mat-option value="country-1">Country 1</mat-option>
                    <mat-option value="country-2">Country 2</mat-option>
                    <mat-option value="country-3">Country 3</mat-option>
                </mat-select>
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:location-marker'"></mat-icon>
            </mat-form-field>
        </div> -->
        <!-- <div class=""style="width: 25%;">
            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                <mat-select [placeholder]="'Active branches'">
                    <mat-option value="country-1">Country 1</mat-option>
                    <mat-option value="country-2">Country 2</mat-option>
                    <mat-option value="country-3">Country 3</mat-option>
                </mat-select>
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:location-marker'"></mat-icon>
            </mat-form-field>
        </div> -->
        <div class=""  style="width: 25%;">
            <mat-form-field
            class="flex-auto gt-xs:pr-3"
            style="width: 90%;"
            [subscriptSizing]="'dynamic'">
            <mat-icon
                class="icon-size-5"
                matPrefix
                [svgIcon]="'heroicons_solid:search'"></mat-icon>
            <input
                matInput
                (keyup)="applyFilter($event)"
                [autocomplete]="'off'"
                [placeholder]="'Search '">
        </mat-form-field>
        </div>
    </div>
    <div class="col-lg-4 col-md-2 col-sm-12 mt-8 d-flex justify-content-end ">
            <button class="ml-4 addBtn" mat-flat-button [color]="'primary'" (click)="createLevel()">
              <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
              <span class="ml-2 mr-1 txt" style="color: black">Create Level</span>
            </button>
            <!-- (click)="createRole()" -->
          
    </div>
</div>
<div class="justify-content-center d-flex ">
    <div class="mat-elevation-z8 mt-8 overflow-auto d-flex justify-content-center flex-column align-items-center" style="width: 50%;">
        <table mat-table [dataSource]="dataSource"  matSort>
      
            <!-- ID Column -->
            <ng-container matColumnDef="PlanLevel">
              <th style="width: 70%;" mat-header-cell *matHeaderCellDef mat-sort-header>Plan Level</th>
              <td style="width: 70%;" mat-cell *matCellDef="let row"> {{row.name}} </td>
              
            </ng-container>
        
            <!-- Progress Column -->
            <!-- <ng-container matColumnDef="Location">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Location</th>
              <td mat-cell *matCellDef="let row"> {{row.Location}} </td>
            </ng-container> -->
        
            <!-- Name Column -->
            <!-- <ng-container matColumnDef="Number">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Number</th>
              <td mat-cell *matCellDef="let row"> {{row.Number}} </td>
            </ng-container>
            <ng-container matColumnDef="IsRemoved">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> IsRemoved </th>
                <td mat-cell *matCellDef="let row"> {{row.IsRemoved}} </td>
              </ng-container> -->
            <!-- Fruit Column -->
            <!-- <ng-container matColumnDef="CreatedBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Created-By </th>
              <td mat-cell *matCellDef="let row"> {{row.CreatedBy}} </td>
            </ng-container>
             -->
            <!-- <ng-container matColumnDef="Assigneduser">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Assign User's</th>
              <td mat-cell *matCellDef="let row" class="text-center"> 
                <span class="badge bg-pill " style="background: #402FFF;">
                  <mat-icon style="color:#fff" svgIcon="heroicons_outline:user-group"></mat-icon>
                </span>
              </td>
            </ng-container>
             -->
            <!-- <ng-container matColumnDef="ViewUser">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> View User's </th>
              <td mat-cell *matCellDef="let row" class="text-center"> 
                <span class="badge bg-pill bg-accent">
                  <mat-icon style="color:#fff" svgIcon="heroicons_outline:user"></mat-icon>
                  <span class="text-lg fw-lighter ">
                    {{row.viewUser}}
                  </span> 
                </span> 
              </td>
            </ng-container> -->
          <ng-container matColumnDef="Actions">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> Actions</th>
            <td mat-cell *matCellDef="let row;" class="row m-0 h-14">
                <button mat-icon-button (click)="viewUser()">
                  <mat-icon style="color:#0038FF" svgIcon="heroicons_outline:eye"></mat-icon>       
                </button>
                <button mat-icon-button (click)="editLevel(row)">
                  <mat-icon style="color:#0FAE1F" svgIcon="heroicons_outline:pencil"></mat-icon>
                </button>
              </td>
              
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>
      
        <mat-paginator style="display:flex;justify-content: end; width: 100%;" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
      </div>
</div>

</div>


