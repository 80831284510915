import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
@Injectable({
  providedIn: 'root'
})
export class ExcelDownloadService {

  constructor(private http: HttpClient) {}
  // downloadExcelFile(data): void {
  //   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  //   const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  //   const fileName = 'sample-data.xlsx';
  //   const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //   saveAs(blob, fileName);
  // }

  // downloadExcelFile(sampleData, fileName="SampleFile", format='xlsx'): void {
  //   let heading = [['User', 'Is Deactivated', 'Branch', 'Role' ,'Attendance Date','Day Type','No of Check-ins','No of Check-outs','Total Active Time (In Minutes)', 'Arrival Status']];

  //   //Had to create a new workbook and then add the header
  //   const wb = XLSX.utils.book_new();
  //   const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  //   XLSX.utils.sheet_add_aoa(ws, heading);

  //   //Starting in the second row to avoid overriding and skipping headers
   
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //     XLSX.writeFile(wb, fileName + '.' + format);

  // }
  downloadExcelFile(data, headerNames, filename): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Set bold header style
    const headerRow = worksheet.addRow(headerNames);
    headerRow.font = { bold: true };

    // Add data rows
   data.forEach(item => {
      worksheet.addRow(Object.values(item));
    });

    // Auto-fit columns
    worksheet.columns.forEach(column => {
      column.width = 18; // Increase column size to 20
    }); 

    // Generate and save Excel file
    workbook.xlsx.writeBuffer().then(buffer => {
      this.saveExcelFile(buffer, filename);
    });
  }

  saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url: string = window.URL.createObjectURL(data);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    setTimeout(() => {
      // For Firefox, it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
      link.remove();
    }, 100);
  }
}
 
