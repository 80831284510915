<div class="container-fluid">
    <div class="d-flex m-2">
        <div class=" mr-2">
            <button mat-icon-button (click)="back()">
                <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
            </button>
        </div>
        <h2 class="h3 createHeading">USER ATTENDANCE</h2>
    </div>
    
    <div class="card dataCard m-1">
        <div class="m-2">
            <div class="row" *ngFor="let row of basicDetails">
                <!-- <div class="col-sm-6 col-md-6 col-lg-4 mb-4 border border-warning"> -->
                    
                    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                        <div class="listFont ">
                            User Name:
                        </div>
                        <div class="textValue">
                            {{row.full_name}}
                        </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3">
                            <div class="listFont">
                               Role:
                            </div>
                            <div class="textValue">
                                {{row.role?.name}}
                            </div>
                        </div>
                
                    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3" >
                        <div class="listFont ">
                            Active&nbsp;Branches:
                        </div>
                        <div class="textValue" 
                        
                        matTooltip='{{userActiveBranchNames.join(", ")}}'
                        > 
                            {{userActiveBranchNames.join(", ")}}
                        </div>
                    </div>
                   
                    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3" >
                        <div class="listFont ">
                            Previous&nbsp;Branches:
                        </div>
                        <div class="textValue"
                        matTooltip='{{userPreviousBranchNames.join(", ")}}'
                        > 
                            {{userPreviousBranchNames.join(", ")}}
                        </div>
                    </div>
                
            </div>


            <!-- <div class="d-flex justify-content-between border border-primary" *ngFor="let row of basicDetails"> -->
                <!-- <div class="col-sm-6 col-md-6 col-lg-4 mb-4 border border-warning">
                    <div class="border border-danger">
                        <span class="listFont"> User Name:</span><span style="    font-size: 20px;
                    font-weight: 400;"> {{row.full_name}}</span>
                    </div>
                </div> -->
                <!-- <div class="col-sm-6 col-md-6 col-lg-3 mb-4 border border-warning">
                    <div class="border border-danger">
                        <span class="listFont">Role:</span><span style="font-size: 20px;
                        font-weight: 400;"> {{row.role.name}}</span>
                    </div>
                </div> -->
                <!-- <div class="col-sm-6 col-md-6 col-lg-5 mb-4"> -->
                    <!-- <div class="listFont">
                        Branches:
                    </div>
                    <div class="row">
                        
                            <div class="">
                                {{userBranchNames.join()}}
                            </div>
                        </div> -->
                    <!-- <div class="">
                        <span class="listFont">Branches:</span><span style="font-size: 20px;
                            font-weight: 400;"> {{userBranchNames.join(", ")}}</span>
                    </div> -->
                <!-- </div> -->
                <!-- <div class="col-sm-6 col-md-6 col-lg-3 mb-4">
                    <div class="listFont">
                        Role:
                    </div>
                    <div class="listFont">
                        Krishna
                    </div>
                </div> -->


            <!-- </div> -->
        </div>
    </div>
    
    <div class="card dataCard m-1 mt-4">
        <div class="ml-3">
            <h3 class="headings">
                User Activity
            </h3>
            <div class="row">
                <div class="col-12 row">
                    <div class="col-sm-12 col-md-3 col-lg-4">
                        <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                            <mat-select [placeholder]="'All Branches'" [(ngModel)]="branch" (selectionChange)="onGetUserAttendanceStats()" >
                              <mat-option  value="">All Branches</mat-option>
                              <mat-option *ngFor="let branch of all_branches_list" [value]="branch.id">{{branch.name}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-4" >

                        <!-- <div class="fw-bold">
                            From Date
                        </div> -->
                        <div class="">
                            <!-- <mat-form-field appearance="fill" class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;">
                            <input matInput [matDatepicker]="picker" formControlName="dateOfBirth">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field> -->
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-75">
                                <input matInput readonly [matDatepicker]="picker" [(ngModel)]="fromDate" [max]="newDate"
                                    [placeholder]="'From Date'" (dateChange)="onGetUserAttendanceStats()">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3  col-lg-4 " >
                        <!-- <div class="fw-bold">
                            To Date
                        </div> -->
                        <div class="">
                            <!-- <mat-form-field appearance="fill" class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;">
                            <input matInput [matDatepicker]="picker" formControlName="dateOfBirth">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field> -->
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-75">
                                <input matInput readonly [matDatepicker]="picker1" [placeholder]="'To Date'" [(ngModel)]="toDate" [disabled]="!fromDate"
                                    [min]="fromDate" [max]="newDate" (dateChange)="onGetUserAttendanceStats()">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>

                        </div>
                    </div>
                </div>
                <!-- <hr> -->
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4 mb-4">
                        <div class="listFont">
                            Total Active Time: <span>{{attendance_log_statistics}}</span>
                        </div>
                     
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 mb-4">
                        <div class="listFont">
                            Total Working Days:<span>{{total_working_days}}</span>
                        </div>
                       
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 mb-4">
                        <div class="listFont">
                            Week off: <span>{{total_week_off_days}}</span>
                        </div>
                    
                    </div>
                   
                </div>

            </div>

        </div>
    </div>
    <!-- <div class="col-sm-12 col-md-3" style="float: right; " *ngIf="isCustom">
        <div class="d-flex" >
            <button style="border-radius: 10px !important; width: 140px;" class="ml-4 addBtn"
                mat-flat-button [color]="'primary'" (click)="addEvent($event)">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 txt" style="color: black">Find</span>
            </button>
            <button style="border-radius: 10px !important; width: 140px;" class="ml-4 addBtn"
                mat-flat-button (click)="reset()">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 txt">RESET</span>
            </button>
        </div>

    </div>
</div> -->

    <!-- <div class="card dataCard mt-6">

        <div class="mt-2 ml-3">
            <h1 class="headings">
                User Branches
            </h1>

            <div class="row m-4">
                <div class="col-12 row">
                    <div class="col-sm-12 col-md-3">
                        <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                            <mat-select [placeholder]="'All Branches'" [(ngModel)]="branch" (selectionChange)="getUserAttendanceStats()" >
                              <mat-option  value="">All Branches</mat-option>
                              <mat-option *ngFor="let branch of branches_list" [value]="branch.id">{{branch.name}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                   
                </div>


            </div>
            
        </div>

    </div> -->
   
    <!-- <div class="card dataCard mt-6">
        <div class="mt-2 ml-6 ">
            
       
        </div>
    </div> -->
    <div class="mat-elevation-z8 mt-4 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="UserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                <td mat-cell *matCellDef="let row">{{row.log_date | dateFormat}} </td>

            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="DutyTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Duty Time</th>
                <td mat-cell *matCellDef="let row">{{row.duty_time_in_seconds}}
            </ng-container>
            <ng-container matColumnDef="OverTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Over Time</th>
                <td mat-cell *matCellDef="let row">{{row.over_time_in_seconds}}
            </ng-container>
            <ng-container matColumnDef="ActiveTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Active Time</th>
                <td mat-cell *matCellDef="let row">{{row.active_time_in_seconds}}
            </ng-container>
            <ng-container matColumnDef="arrivalStatuses">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Arrival Statuses</th>
                <td mat-cell *matCellDef="let row">{{row.arrival_statuses}} </td>
            </ng-container>
            <ng-container matColumnDef="aboutDay">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>About Day</th>
                <td mat-cell *matCellDef="let row">{{row.about_day}} </td>
            </ng-container>
            <ng-container matColumnDef="branch">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Branch</th>
                <td mat-cell *matCellDef="let row">{{row.branch?.name}} </td>
            </ng-container>
            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <mat-cell class="" style="height: 52px;" *matCellDef="let row;">
                    <button mat-button [matMenuTriggerFor]="menu"><mat-icon style="color:#0038FF;font-size: 30px;"
                            svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <!-- <button mat-menu-item (click)="getBranchById(row)">View</button> -->
                        <button mat-menu-item (click)="viewUserLog(row)">View</button>
                        <!-- <button mat-menu-item (click)="editUserLog(row)"
                        [disabled]="!isUserAllowedToCreateAttendanceLog">Edit</button> -->
                        <!-- <button mat-menu-item >Reactivate</button> -->
                    </mat-menu>

                </mat-cell>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <div>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">
                        <h3 style="display: flex;justify-content: center; margin:10%;">
                            No Records Found
                        </h3>
                    </td>
                </tr>
            </div>
        </table>
    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    #paginator
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>