import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import {   MetaService, PlanTypeActions, PlansActions, featureConstants } from 'app/meta.service';
import { CreatePlanTypesComponent } from 'app/pages/Plans/plan-types/createPlanTypes/create-plan-types.component';
import { ToastrService } from 'ngx-toastr';
import { CreateCouponTypesComponent } from '../create-coupon-types/create-coupon-types.component';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { VerifyDeactivateComponent } from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';



export interface Element {
  is_removed: boolean;
  id: String;
}

@Component({
  selector: 'app-get-coupon-types',
  templateUrl: './get-coupon-types.component.html',
  styleUrls: ['./get-coupon-types.component.scss']
})

export class GetCouponTypesComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['PlanType', 'CreatedBy', 'CreatedDate' ,'Actions'];
  dataSource = new MatTableDataSource<Element>();
  branches_list = [];
  PlanStatusType = [
    // { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }

  ]
  IDPlanType: PlanTypeActions = {
    planTypeActive: "",
    planTypeId: "",
  }

  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValue=null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewCouponTypes = this.userAllowedFeatures.indexOf(featureConstants.VIEW_COUPON_TYPES) > -1
  isUserAllowedToCreateCouponType = this.userAllowedFeatures.indexOf(featureConstants.CREATE_COUPON_TYPES) > -1
  isUserAllowedToEditCouponTypes = this.userAllowedFeatures.indexOf(featureConstants.EDIT_COUPON_TYPES) > -1
  isUserAllowedToDeactivateCouponType = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_COUPON_TYPES) > -1
  isUserAllowedToReactivateCouponType = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_COUPON_TYPES) > -1

  constructor(private router: Router, public dialog: MatDialog, public meta: MetaService, public apiService: ApiService, private toastr: ToastrService) {
    this.getScreenSize();
  }
  ngOnInit() {
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    this.filterCouponTypes()
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    
    this.onFilterPlanType()
  }
  
  filterCouponTypes() {
    let data = {
      filter_by_active_status: this.IDPlanType.planTypeActive || null,
      offset:this.bOffset,
      limit:this.bLimit,
      search_term: this.filterValue || null
    }

    this.apiService.getCouponTypeByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data =  resp.data.coupon_types;
        this.totalCount=resp.data.total_count
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }
  
  scrHeight: number;
  scrWidth: number;
  viewWidth:any
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // this.getAd(); // This is get ad on every time screen is resized
  }
  createCouponType() {
    if(this.scrWidth < 1450){
      this.viewWidth='90%'
    }else{
      this.viewWidth='40%'
    }
    let dialogRef = this.dialog.open(CreateCouponTypesComponent, {
      // data: {
      //   data: data,
      // },
      // // height: auto,
      width: this.viewWidth,
      
    });
    dialogRef.afterClosed().subscribe(result => {
      this.filterCouponTypes()
    });
  }
  editPlanType(row) {
    if(this.scrWidth < 1450){
      this.viewWidth='90%'
    }else{
      this.viewWidth='40%'
    }
    let dialogRef = this.dialog.open(CreateCouponTypesComponent, {
      data: {
        data: row,
      },
      // // height: auto,
      width: this.viewWidth,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.filterCouponTypes()
    });
  }

  verifyDeactivate(coupon_type_id: String){
    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
        "message": "Coupons under this type won't be visible on Mobile App."
      }, // Pass data to the dialog
      width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {

      if (this.meta.dialogClosed) {
        this.deactivateCouponType(coupon_type_id=coupon_type_id)
      }
    });
  }


deactivateCouponType(coupon_type_id: String) {
    this.apiService.deactivateCouponType(coupon_type_id = coupon_type_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          coupon_type => {
            if (coupon_type.id == coupon_type_id)
            coupon_type.is_removed = true
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  reactivateCouponType(coupon_type_id: String) {
    this.apiService.reactivateCouponType(coupon_type_id = coupon_type_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          coupon_type => {
            if (coupon_type.id == coupon_type_id){
              coupon_type.is_removed = false
            }
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
    this.filterCouponTypes(); 
  }


  onFilterPlanType(){
    this.bOffset=0;
    this.pageIndex=0;
    this.filterCouponTypes()
  }

}

