<div class="bgCard">
    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>TICKET</h1>
        <button align="end" mat-icon-button mat-dialog-close>
            <mat-icon style="color: #0038ff" svgIcon="feather:x"></mat-icon>
        </button>
    </div>
    <div>
        <mat-dialog-content class="mat-typography">
            <div class="card dataCard mt-3 p-2">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4 mb-1">
                        <div class="listFont  fw-bold">
                            Ticket Code:
                        </div>
                        <div class="fw-bold">
                            {{ticketData.ticket_code}}
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4 mb-1">
                        <div class="listFont  fw-bold">
                            Customer:
                        </div>
                        <div class="fw-bold">
                            {{ticketData.user.full_name}}
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4 mb-1">
                        <div class="listFont fw-bold">
                            Tag:
                        </div>
                        <div class="fw-bold" *ngIf="!shouldShowApprovalScreen">
                            {{ticketData.tag.name}}
                        </div>
                        <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="plan" style="width: 90%;" *ngIf="shouldShowApprovalScreen">
                            <mat-select [placeholder] = "'Select Tag'" [(ngModel)]="tagId">
                                <mat-option *ngFor="let tag of tagsList"
                                    [value]="tag.id">{{tag.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            <div class="row">
                <div class="col-sm-4 col-md-4 col-lg-4 mb-1">
                        <div class="listFont  fw-bold">
                        Current Status:
                    </div>
                    <div class=" fw-bold">
                        {{ticketData.status | titlecase}}
                    </div>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4 mb-1">
                    <div class="listFont  fw-bold">
                    Last Responded By:
                </div>
                <div class="fw-bold">
                    {{ticketData.last_responded_by?.full_name}}<span *ngIf="ticketData.last_responded_by?.role">({{ticketData.last_responded_by.role.name}})</span>
                </div>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 mb-1">
                <div class="listFont fw-bold">
                    Last Responded At:
            </div>
            <div class="fw-bold">
                {{ticketData?.last_responded_at | dateTimeFormat}}
            </div>
        </div>
            </div>
        </div>
        <div class="card dataCard mt-3 p-2" >
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                    <div class="listFont fw-bold">Subject:</div>
                    <div class="fw-bold">{{ticketData.subject}}</div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 mb-1">
                    <div class="listFont  fw-bold">Description:</div>
                    <div class="fw-bold">{{ticketData.description}}</div>
                </div>
            </div>
        </div>
                <div class="card dataCard mt-3 p-2" *ngIf="shouldShowApprovalScreen">
                    <div class="row">
                        <div class="col-md-12  d-flex flex-column flex-sm-row justify-content-center">
                            <mat-radio-group aria-label="Select an option" class="d-flex flex-column flex-sm-row"
                                style="display: flex; justify-content: space-evenly; align-items: center;"
                                [(ngModel)]="Status" (ngModelChange)="resolveStatusChnage()">
                                <mat-radio-button value="RESOLVED">Resolved</mat-radio-button>
                                <mat-radio-button value="UNRESOLVED">Not Resolved</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="card dataCard mt-3 p-2" *ngIf="shouldShowApprovalScreen">
                    <div class="row">
                    <div class="col-md-12">
                        <div class="fw-bold fieldName"
                            style="background: #000; color: #fff; padding: 10px; border-top-left-radius: 13px; border-top-right-radius: 13px;">
                            Resolve Description
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <textarea rows="5" id="points" style="background: #FFFFFF;" matInput
                                    placeholder="Description" [(ngModel)]="description"
                                    (keypress)="handleStartingSpaceTextInput($event, description)"
                                    ></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
    <div class="btnposition" *ngIf="shouldShowApprovalScreen">
        <div class="bottomBtns mb-3 row" style="display: flex; justify-content:end; ">
            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="updateTicket()" 
            [ngClass]="{
                'btn-clr': (Status)}"
            [disabled]="(!Status)">
                <span class="ml-2 mr-1 txt" style="color: white">Update</span>
            </button>
        </div>
    </div>
</div>